import React, {useState} from "react";
import { Circles } from "react-loader-spinner";

const PaypalInput = ({isLoading,onPayment,}) => {
   const [paypalDataInputs, setPaypalDataInputs] = useState({
     email: "",
     password: "",

   });
  
   
  const paypalInputChange = (e) => {
    const { name, value } = e.target;
    setPaypalDataInputs({
      ...paypalDataInputs,
      [name]: value,
    });
  };


  

  return (
    <div>
      <form className="flex mt-5 flex-col text-base max-w-[642px] text-teal-950">
        {/* <label className="w-full text-lg max-md:max-w-full">
          Email address
        </label>
        <input
          placeholder="Enter your email"
          className="justify-center items-start px-5 py-2 mt-2.5 w-full border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
          value={paypalDataInputs.email}
          onChange={paypalInputChange}
          name="email"
        />
        <label className="mt-5 w-full text-lg max-md:max-w-full">
          Password
        </label>
        <input
          placeholder="Enter password"
          className="justify-center items-start px-5 py-2 mt-2.5 w-full border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
          value={paypalDataInputs.password}
          type="password"
          onChange={paypalInputChange}
          name="password"
        /> */}

        <buton
          onClick={onPayment}
          className="justify-center hover:bg-800 text-center items-center px-5 py-2 mt-6 text-lg font-medium text-white bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full">
          {isLoading ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20" // Adjust the height
                width="20" // Adjust the width
                color="#fff" // Choose your desired color
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "Proceed with payment"
          )}
        </buton>
      </form>
      <div className="mt-10 w-full text-sky-600 max-md:max-w-full">
        <span className="text-zinc-800">Don’t have a Paypal account?</span>{" "}
        <a
          href="https://www.paypal.com/ng/home"
          target="_blank"
          rel="noreferrer"
          className="font-medium text-sky-600">
          Register
        </a>
      </div>
    </div>
  );
}
export default PaypalInput;