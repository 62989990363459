import React, { useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../services/url";
import { Circles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { sendEmail } from "../services/authServices";

const ChangeEmail = () => {
  const navigate = useNavigate();
  const [existingEmail, setExistingEmail] = React.useState("");
  const [newEmail, setNewEmail] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsLoading(true);

    try {
      const response = await axios.put(
        `${BACKEND_URL}/users/change-email`,
        {
          newEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setSuccess("Email updated successfully");
        await sendEmail();
        navigate("/change-email-success");
      } else {
        setError("Failed to update email");
      }
    } catch (error) {
      setError("Failed to update email");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="overflow-hidden font-roboto bg-white">
      <div className="flex gap-5 max-md:flex-col">
        <div className="hidden lg:flex flex-col w-[35%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-12 pt-5 pb-28 w-full text-4xl font-semibold text-center text-white bg-sky-700 max-md:px-5 max-md:pb-24 max-md:mt-10 max-md:max-w-full">
            <img
              alt=""
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="object-contain max-w-full aspect-[1.79] w-[100px]"
            />
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/047d95613deafbf9f6bf60f7df9b773aa394a141bbbf9df856329f4df59f11d9?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="object-contain self-end mt-28 max-w-full aspect-square w-[450px] max-md:mt-10"
            />
            <div className="self-center mt-5 w-[390px]">
              Your journey to success starts here!
            </div>
          </div>
        </div>
        <div className="flex md:ml-10 px-10 flex-col ml-5 w-full max-md:ml-0 max-md:w-full">
          <div className="flex flex-col w-full max-md:mt-10 max-md:max-w-full">
            <div className="max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col">
                <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
                  <div className="mt-48 text-3xl font-medium text-teal-950 max-md:mt-10">
                    Change Email Address
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full"></div>
              </div>
            </div>
            <form
              onSubmit={handleUpdateEmail}
              className="mt-7 max-w-full w-[506px]">
              <div className="flex gap-5 max-md:flex-col">
                <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col -mr-80 w-full max-md:max-w-full">
                    <div className="flex flex-col w-full whitespace-nowrap max-w-[506px] text-zinc-800 max-md:max-w-full">
                      <label className="text-xl font-medium mt-5">
                        New Email
                      </label>
                      <input
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        className="gap-2.5 self-stretch px-5 py-2 mt-3 w-full text-lg rounded-md border border-solid border-zinc-400"
                        placeholder="newjohndoe@gmail.com"
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="gap-2.5 self-stretch text-center px-5 py-2 mt-12 max-w-full text-lg font-medium text-white bg-sky-600 rounded-md w-[506px] max-md:mt-10">
                      {isLoading ? (
                        <Circles color="#fff" height={20} width={20} />
                      ) : (
                        "Update Email Address"
                      )}
                    </button>
                    {error && <p className="text-red-500">{error}</p>}
                    {success && <p className="text-green-500">{success}</p>}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;
