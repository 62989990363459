import React from "react";
import { useNavigate } from "react-router-dom";

const EmailSuccess = () => {
    const navigate = useNavigate()
  return (
      <div className="overflow-hidden bg-white flex justify-center items-center h-screen">
             <div className="hidden lg:flex flex-col w-[35%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-12 pt-5 pb-28 w-full text-4xl font-semibold text-center text-white bg-sky-700 max-md:px-5 max-md:pb-24 max-md:mt-10 max-md:max-w-full">
            <img
              alt=""
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="object-contain max-w-full aspect-[1.79] w-[100px]"
            />
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/047d95613deafbf9f6bf60f7df9b773aa394a141bbbf9df856329f4df59f11d9?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="object-contain self-end mt-28 max-w-full aspect-square w-[450px] max-md:mt-10"
            />
            <div className="self-center mt-5 w-[390px]">
              Your journey to success starts here!
            </div>
          </div>
        </div>
          <div className="flex flex-col gap-5 w-full max-w-5xl mx-auto p-5 md:p-10">
              
        <div className="flex justify-center">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f025cb678355c8c04a4031673254b5a4ec4edd0f02d4b7ad0439375f921553d3?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
            className="object-contain w-20 h-20 md:w-40 md:h-40"
          />
        </div>
        <div className="text-3xl font-medium text-teal-950 text-center">
          Email Changed Successfully!
        </div>
        <div className="text-xl text-zinc-800 text-center">
          We have sent your login details to your new email
        </div>
        <div className="text-lg text-center text-neutral-500">
          Kindly check and use the information to log in to your dashboard
        </div>
        <button onClick={() => navigate("/login")} className="gap-2.5 self-stretch px-5 py-2 mt-12 text-lg font-medium text-white bg-sky-600 rounded-md w-full">
          Proceed to Login
        </button>
      </div>
    </div>
  );
};

export default EmailSuccess;
