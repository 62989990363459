import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CiGrid42 } from "react-icons/ci";
import { FiBookOpen } from "react-icons/fi";
import { RiLogoutBoxLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { logoutUser } from "../../services/authServices";
import { MdBrowserUpdated } from "react-icons/md";
import { GrSearch } from "react-icons/gr";
import { BsPeopleFill } from "react-icons/bs";

const AdminSidebar = () => {
  const [hoveredButton, setHoveredButton] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Derive selectedButton from the current route
  const [selectedButton, setSelectedButton] = useState(() => {
    const path = location.pathname;
    if (path === "/admindashboard") return "dashboard";
    if (path === "/admindashboard/courses") return "courses";
    if (path === "/admindashboard/updates") return "updates";
    if (path === "/admindashboard/learning") return "learning";
    if (path === "/admindashboard/manage-cohort") return "cohort"
    return "dashboard"; // Default to dashboard
  });

  useEffect(() => {
    // Update selectedButton whenever the route changes
    const path = location.pathname;
    if (path === "/admindashboard") setSelectedButton("dashboard");
    else if (path === "/admindashboard/courses") setSelectedButton("courses");
    else if (path === "/admindashboard/updates") setSelectedButton("updates");
    else if (path === "/admindashboard/learning") setSelectedButton("learning");
    else if (path === "/admindashboard/manage-cohort") setSelectedButton("cohort")
    else setSelectedButton("dashboard"); // Default to dashboard
  }, [location.pathname]);

  const handleLogout = async () => {
    const response = await logoutUser();
    if (response.data.message) {
      localStorage.clear("sawl_user");
      navigate("/login");
    } else {
      navigate("/studentdashboard");
    }
  };

  const handleButtonHover = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleButtonLeave = () => {
    setHoveredButton(null);
  };

  const sidebarItems = [
    {
      icon: CiGrid42,
      text: "Dashboard",
      path: "/admindashboard",
    },
    {
      icon: FiBookOpen,
      text: "Courses",
      path: "/admindashboard/courses",
    },
    {
      icon: BsPeopleFill,
      text: "Manage Cohorts",
      path: "/admindashboard/manage-cohort",
    },
    {
      icon: MdBrowserUpdated,
      text: "Updates",
      path: "/admindashboard/updates",
    },
    {
      icon: GrSearch,
      text: "Learning platform",
      path: "/admindashboard/learn",
    },
  ];

  return (
    <div className="hidden md:flex mt-20 flex-col px-4 w-[200px] h-full pb-6 text-base border-r-2 border-[#A7AEB91A]  bg-white max-w-[240px]">
      {sidebarItems.map((item) => (
        <Link
          key={item.path}
          to={item.path}
          className={`flex gap-2 p-2.5 w-full text-sm mt-2 rounded-md border-none ${
            selectedButton === item.text
              ? "bg-[#127ABB] text-white"
              : "hover:bg-[#127ABB] hover:text-white"
          }`}
          onMouseEnter={() => handleButtonHover(item.text)}
          onMouseLeave={handleButtonLeave}>
          <item.icon
            size={24}
            className={`shrink-0 w-5 aspect-square ${
              hoveredButton === item.text ? "text-white" : "text-sky-600"
            }`}
          />
          {item.text}
        </Link>
      ))}

      {/* Logout Button */}
      <div className="mt-20 text-zinc-800">
        <button
          className={`flex gap-4 p-2.5  rounded-md border-none ${
            hoveredButton === "logout"
              ? "bg-[#127ABB] text-white"
              : "hover:bg-[#127ABB] hover:text-white"
          }`}
          onClick={handleLogout}
          onMouseEnter={() => handleButtonHover("logout")}
          onMouseLeave={handleButtonLeave}>
          <RiLogoutBoxLine
            size={24}
            className={`shrink-0 w-6 aspect-square ${
              hoveredButton === "logout" ? "text-white" : "text-sky-600"
            }`}
          />
          Logout
        </button>
      </div>
    </div>
  );
};

export default AdminSidebar;
