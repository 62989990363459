import React, { useState, useEffect, useMemo } from "react";
import BarChart from "../../BarChart";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url";

const ChartSection = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [hoveredButton, setHoveredButton] = useState(null);
  const [cohorts, setCohorts] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    fetchStudentStatistics(event.target.value);
  };


  useEffect(() => {
    const fetchCohorts = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/cohorts`);
        const data = response.data.cohorts;
        const cohortOptions = data && data.map((cohort) => cohort);
        setCohorts(cohortOptions);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCohorts();
  }, []);

  useEffect(() => {
    const fetchStudentStatistics = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/users/student/statistics`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        const statistics = response.data;
        const updatedCoursesData = [
          { name: "All", totalStudents: statistics.totalStudents },
          {
            name: "Business analysis",
            totalStudents: statistics.businessAnalysisStudents,
          },
          {
            name: "Data analysis",
            totalStudents: statistics.dataAnalysisStudents,
          },
        ];
        setCoursesData(updatedCoursesData);
        setStudentsData(updatedCoursesData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentStatistics();
  }, []);

  const fetchStudentStatistics = async (cohortId) => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/users/student/statistics/${cohortId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const statistics = response.data;
      const updatedCoursesData = [
        { name: "All", totalStudents: statistics.totalStudents },
        {
          name: "Business analysis",
          totalStudents: statistics.businessAnalysisStudents,
        },
        {
          name: "Data analysis",
          totalStudents: statistics.dataAnalysisStudents,
        },
      ];
      setCoursesData(updatedCoursesData);
      setStudentsData(updatedCoursesData);
    } catch (error) {
      console.error(error);
    }
  };
  const transformedStudentsData = useMemo(
    () => [
      {
        name: "All",
        businessStudents: studentsData[1]?.totalStudents,
        dataStudents: studentsData[2]?.totalStudents,
      },
      {
        name: "Business analysis",
        businessStudents: studentsData[1]?.totalStudents,
        dataStudents: 0,
      },
      {
        name: "Data analysis",
        businessStudents: 0,
        dataStudents: studentsData[2]?.totalStudents,
      },
    ],
    [studentsData]
  );
  return (
    <div className="flex flex-col px-6 py-8 mt-10 rounded-md bg-white shadow-lg max-md:px-5 max-md:max-w-full">
      <div className="flex gap-4 self-start font-medium whitespace-nowrap">
        <div className="my-auto text-xl text-teal-950">Cohort</div>
        <div className="flex gap-5 justify-between py-1.5 pr-2.5 pl-5 text-base text-white bg-sky-600 rounded-md">
          <select
            name="options"
            id="options"
            value={selectedOption}
            onChange={handleSelectChange}
            className="h-full w-full outline-none border-none bg-inherit">
            {cohorts.map((cohort, index) => (
              <option value={cohort.id} key={index}>
                {new Date(cohort.start_date).toLocaleString("default", {
                  month: "long",
                })}{" "}
                {new Date(cohort.start_date).getFullYear()}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex gap-5 pb-4 mt-7 max-md:flex-wrap max-md:max-w-full">
        {coursesData.map((student, index) => (
          <div
            key={index}
            className="flex gap-2.5 px-5 py-4 bg-white rounded-lg shadow-md">
            <div className="flex flex-col">
              {" "}
              <div
                className={`self-start w-4 h-4 rounded-full ${
                  student.name === "All" ? "bg-white" : ""
                }
              ${student.name === "Business analysis" ? "bg-sky-600" : ""}
              ${student.name === "Data analysis" ? "bg-orange-700" : ""}`}
              />
              <p className="text-base text-neutral-500">
                {" "}
                {student.name === "All" ? "Total students" : student.name}
              </p>
              <p className="mt-2.5 text-2xl font-medium text-teal-950">
                {student.totalStudents}
              </p>
            </div>
          </div>
        ))}
      </div>
      <BarChart
        students={transformedStudentsData}
        selectedOption={selectedOption}
      />
    </div>
  );
};

export default ChartSection;
