import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import axios from "axios"
import { BACKEND_URL } from "../../../services/url";
import { formatCreatedAt } from "../../../utils/convertDate";
import {confirmAlert} from "react-confirm-alert";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";

const CourseList = () => {
  useRedirectLoggedOutAdmin("/login");
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCourseId, setselectedCourseId] = useState(null);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

   useEffect(() => {
     const fetchCourses = async () => {
       try {
         const response = await axios.get(
           `${BACKEND_URL}/course/courses/details`,
           {
             headers: {
               "Content-Type": "application/json",
             },
             withCredentials: true,
           }
         );
         const formattedCourses = response.data?.map((course) => ({
           id: course.id,
           name: course.courseName,
           courseCode: course.courseCode,
           coursePrice: course.generalPrice,
           dateUploaded: course.updated_at,
           instructorName: `${course.firstName} ${course.lastName}`,
           students: 12,
           link: `/admindashboard/courses/${course.courseCode}/overview`,
         }));
         setCourses(formattedCourses);
       } catch (error) {
         console.error(error);
       }
     };
     fetchCourses();
   }, []);

  const handleOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setselectedCourseId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setselectedCourseId(null);
  };
  // const handleDeleteTestimonial = async (testimonialId) => {
  //   try {
  //     // Replace with actual delete function
  //     const updatedcourses =courses.filter(
  //       (course) => course.id !== courseId
  //     );
  //     setcourses(updatedcourses);
  //     handleCloseMenu();
  //   } catch (error) {
  //     // Handle errors here
  //     console.error("Error deleting course:", error);
  //   }
  // };

  const handleViewCourse = async (courseId) => {
    navigate(`/admindashboard/courses/${courseId}/details`);
  };

 const handleEditCourse = async (courseName) => {
   navigate(`/admindashboard/courses/course-form/${courseName}`);
   handleCloseMenu()
 };
		
const handleDeleteCourse = async (courseId) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className="modal-dialog modal-dialog-centered"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirm Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              />
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this course?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={onClose}>
                No
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={async () => {
                  try {
                    const response = await axios.delete(
                      `${BACKEND_URL}/course/${courseId}`
                    );
                    if (response.status === 200) {
                      const updatedCourses = courses.filter(
                        (course) => course.id !== courseId
                      );
                      setCourses(updatedCourses);
                    } else {
                      console.error("Deletion failed:", response.status);
                    }
                  } catch (error) {
                    console.error("Error deleting course:", error);
                  }
                  onClose();
                }}>
                Yes
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};
 
  return (
    <div className="flex mt-20 flex-col py-8 font-roboto bg-neutral-50 md:px-5 min-h-[400px]">
      {/* Main Container with Max Width */}
      <div className="max-w-screen-xl">
        {/* Title and Add Button */}
        <div className="flex flex-row md:flex-row bg-white shadow-md p-4 rounded-md pb-4 justify-between items-center mb-4">
          <h3 className="text-lg md:text-xl font-medium text-teal-950">
            Courses
          </h3>
          <button
            onClick={() => navigate("/admindashboard/courses/add_course")}
            className="px-4 py-2 text-base text-white bg-sky-600 rounded-md mt-2 md:mt-0">
            Add new course
          </button>
        </div>

        {/* Course Data Table */}
        <table className="w-full bg-white rounded-lg shadow-lg">
          {/* Table Head (Headers) */}
          <thead>
            <tr className="bg-neutral-200 text-teal-950 text-left">
              <th className="px-4 py-3 text-sm font-medium">Course name</th>
              <th className="px-4 py-3 text-sm font-medium hidden md:table-cell">
                Course Code
              </th>
              <th className="px-4 py-3 text-sm font-medium hidden md:table-cell">
                Course Price
              </th>
              <th className="px-4 py-3 text-sm font-medium">Date uploaded</th>
              <th className="px-4 py-3 text-sm font-medium hidden lg:table-cell">
                Instructor name
              </th>
              {/* <th className="px-4 py-3 text-sm font-medium hidden xl:table-cell">
                Number of cohorts
              </th>
              <th className="px-4 py-3 text-sm font-medium hidden xl:table-cell">
                Number of students
              </th> */}
              <th className="px-4 py-3 text-sm font-medium">Action</th>
            </tr>
          </thead>
          {/* Table Body (Data Rows) */}
          <tbody className="text-zinc-800">
            {courses.map((course, index) => (
              <tr key={index} className="border-b border-zinc-400">
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="flex items-center">
                    <div>{course.name}</div>
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap hidden md:table-cell">
                  {course.name.slice(0, 2).toUpperCase()}
                </td>
                <td className="px-4 py-3 whitespace-nowrap hidden md:table-cell">
                  £
                  {parseFloat(course.coursePrice?.replace(/[£,]/g, "")).toFixed(
                    2
                  )}
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  {formatCreatedAt(course.dateUploaded)}
                </td>
                <td className="px-4 py-3 whitespace-nowrap hidden lg:table-cell">
                  {`${course.instructorName || "-"}`}
                </td>
                {/* <td className="px-4 py-3 whitespace-nowrap hidden xl:table-cell">
                  12
                </td>
                <td className="px-4 py-3 whitespace-nowrap hidden xl:table-cell">
                  209
                </td> */}
                <td className="px-4 py-3 text-center">
                  <HiOutlineDotsHorizontal
                    onClick={(e) => handleOpenMenu(e, course.name)}
                    className="cursor-pointer"
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}>
                    {/* <MenuItem
                      onClick={() => handleEditcourse(selectedCourseId)}>
                      View
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => handleEditCourse(selectedCourseId)}>
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleDeleteCourse(selectedCourseId)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/*  */}
    </div>
  );
};

export default CourseList;
