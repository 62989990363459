import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import CreateAssignment from "../Assignment/CreateAssignment";
import { BACKEND_URL } from "../../../services/url";
import { selectCourse } from "../../../redux/auth/authSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ReviewAssignment1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionCount, setSubmissionCount] = useState(null);
  const navigate = useNavigate();

  // fetch assignments submissions
  useEffect(() => {
    const fetchSubmissions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/assignment/submissions`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          const submissions = response.data;
          // Count submissions with "submitted" status
          const count = submissions.filter(
            (submission) =>
              submission.status === "submitted" &&
              submission.assignment_id !== null
          ).length;
          setSubmissionCount(count);
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch assignment. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching classes";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSubmissions();
  }, []);
  return (
    <div className="flex flex-col ml-2 w-[32%] max-md:ml-0 max-md:w-full font-roboto">
      <div className="flex flex-col grow px-6 pt-5 pb-8 mx-auto w-full font-medium bg-white shadow-lg max-md:pl-5 max-md:mt-5">
        <div className="flex gap-5 text-xl items-center text-teal-950">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/88b995834b9468a4c63a9ce1dc071f44507da24139925303d60ed9863b0a954e?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 aspect-square w-[30px]"
          />
          <p className="w-full text-sm">Review Assignments</p>
        </div>
        <div className="shrink-0 mt-3.5 h-px bg-gray-200 border border-gray-200 border-solid" />
        <p className="mt-5 text-sm leading-7 text-neutral-500">
          {submissionCount > 0
            ? ` You have ${submissionCount} assignment submissions to review`
            : `You have no pending assignment to review`}
        </p>
        {submissionCount > 0 ? (
          <>
            <p
              onClick={() => navigate("/teachersdashboard/assignment")}
              className="mt-7 t text-base cursor-pointer text-sky-600 max-md:mr-0.5">
              Review Now
            </p>
          </>
        ) : (
          <>
            <p
              onClick={() => setIsModalOpen(true)}
              className="mt-7 t text-base cursor-pointer text-sky-600 max-md:mr-0.5">
              Create new assignment
            </p>
          </>
        )}
      </div>
      <CustomModal isOpen={isModalOpen}>
        <CreateAssignment closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default ReviewAssignment1;
