export function convertToPngUrl(fileUrl) {
  const fileExtension = fileUrl?.split(".").pop().toLowerCase();

  if (fileExtension === "pdf") {
    // Handle PDF conversion logic (if needed)

    return fileUrl.replace(/\.pdf$/i, ".png");
  } else if (
    fileExtension === "jpg" ||
    fileExtension === "jpeg" ||
    fileExtension === "png"
  ) {
    // Image file, no conversion needed
    return fileUrl;
  } else if (
    fileExtension === "xlsx" ||
    fileExtension === "xls" ||
    fileExtension === "ods"
  ) {
    // Excel file, convert to image
    return fileUrl
      .replace(".xlsx", ".png")
      .replace(".xls", ".png")
      .replace(".ods", ".png");
  } else if (fileExtension === "gsheet" || fileExtension === "gdoc") {
    // Google Spreadsheet or Google Doc file, convert to image
    return fileUrl.replace(".gsheet", ".png").replace(".gdoc", ".png");
  } else {
    // Handle other file types (e.g., text, audio, video)
    console.warn("Unsupported file type:", fileExtension);
    return null; // Or return a default image URL
  }
}
