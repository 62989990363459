import React from "react";

const EmptyAssignment = () => {
  return (
    <div className="flex justify-center items-center px-16 py-20 bg-neutral-50 max-md:px-5">
      <div className="flex flex-col items-center mt-20 max-w-full w-[466px] max-md:mt-10">
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/781a5b1805d171ff89a8645ceb4239bef5f0b0ca6767aa6dcc92b770af2d19de?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="w-56 max-w-full aspect-[1.2]"
        />
        <p className="self-stretch mt-12 text-xl text-zinc-800 max-md:mt-10 max-md:max-w-full">
          No assignment set yet. Kindly check back later.
        </p>
        
      </div>
    </div>
  );
};

export default EmptyAssignment;
