import React, { useEffect } from "react";
import { useTutorFilterData, setSearchQuery } from "../../redux/filter/teachersFilterSlice";
import { useDispatch } from "react-redux";
import Scrollbar from "react-scrollbar-component";
import { Link, useLocation } from "react-router-dom";

const TutorSearchComponent = () => {
  const { searchQuery, searchResults } = useTutorFilterData();
  const dispatch = useDispatch();
    const location = useLocation();
    


  useEffect(() => {
    return () => {
      dispatch(setSearchQuery("")); // Clear the search query when the component unmounts
    };
  }, [dispatch, location.pathname]);

  return (
    <div>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => dispatch(setSearchQuery(e.target.value))}
        placeholder="Search for anything..."
        className="w-full h-full outline-none border-none "
      />
      {searchResults.length > 0 && (
        <div className="absolute overflow-y-auto scroll-mb-6 ">
          <Scrollbar
            className="absolute  m-w-[240px] h-[40px] rounded-md bg-white shadow-md p-4 scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-400 scrollbar-track-gray-200"
            style={{ width: 240, height: 240 }}>
            <ul>
              {searchResults.map((result) => (
                <li key={result.id} className="py-2 border-b text-zinc-800">
                  <Link to={result.link}>{result.title}</Link>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      )}
    </div>
  );
};

export default TutorSearchComponent;
