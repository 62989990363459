import React, { useState } from 'react'
import RegisterTutor from '../RegisterTutor';
import CustomModal from '../../StudentsDashboard/Modal';


const CreateTeachersLogin = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div
      onClick={() => setIsModalOpen(true)}
      className="flex gap-5 justify-between px-8 py-4 mt-10 text-lg leading-6 bg-white rounded-lg shadow-lg text-zinc-800 max-md:px-5">
      <img
        alt=""
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c85c965169cf66af773641f34ecf1230888e0c7ff7a2aad228bbfdc2d8b44ac0?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
        className="shrink-0 my-auto w-10 aspect-square"
      />
      <div>Create teacher’s login credential</div>

      <CustomModal isOpen={isModalOpen}>
        <RegisterTutor closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
}

export default CreateTeachersLogin;