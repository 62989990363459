
import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/convertDate";

const PCards = ({
  handleClick,
  countId,
  title,
  publishedOn,
  dueDate,
  submittedCount,
  totalCount,
  width,
}) => (
  <Link
    onClick={handleClick}
    to={`/teachersdashboard/project/details/${countId}`}
    className="flex flex-col px-5 pt-5 pb-6 mt-6 w-full bg-white rounded-lg border border-solid border-zinc-400 max-w-[1040px] max-md:max-w-full">
    <p className="text-lg font-medium text-zinc-800 max-md:max-w-full">
      {title}
    </p>
    <div className="flex gap-5 justify-between mt-2.5 w-full max-md:flex-wrap max-md:max-w-full">
      <div className="flex gap-2">
        <p className="text-sm text-neutral-500">Published on:</p>
        <p className="text-base text-zinc-800">{formatDate(publishedOn)}</p>
      </div>
      <div className="flex gap-2">
        <p className="text-sm text-neutral-500">Due date:</p>
        <p className="text-base text-zinc-800">{formatDate(dueDate)}</p>
      </div>
    </div>
    <p className="self-end mt-5 text-sm text-neutral-500">
      {submittedCount}/{totalCount} submitted
    </p>
    <div className="flex flex-col justify-center mt-1.5 bg-gray-200 max-md:max-w-full">
      <div
        className="shrink-0 bg-sky-600 rounded-md h-[7px]"
        style={{ width }}
      />
    </div>
  </Link>
);

export default PCards;
