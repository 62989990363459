import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  SET_CREATE_ASSIGNMENT,
  selectCreatedAssignment,
} from "../../../redux/assignment/assignmentSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const initialData = {
  title: "",
  instructions: "",
  overallGrade: "",
  link: "",
  startDate: "",
  dueDate: "",
};
const CreateAssignment = ({ closeModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const { title, instructions, overallGrade, link, startDate, dueDate } =
    formData;
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const editableAssignmet = useSelector(selectCreatedAssignment);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  //

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handlePreview = (e) => {
    e.preventDefault();
    const parsedStartDate = new Date(startDate);
    const parsedDueDate = new Date(dueDate);

    const today = new Date();
    if (!title || !instructions || !startDate || !dueDate || !overallGrade) {
      return toast.error("All fields are required.");
    }
    if (isNaN(parsedStartDate) || isNaN(parsedDueDate)) {
      return toast.error("Invalid date format. Please use YYYY-MM-DD.");
    }

    // if (parsedStartDate < today) {
    //   return toast.error("Start date cannot be in the past.");
    // }

    if (parsedDueDate <= parsedStartDate) {
      return toast.error("Due date must be after the start date.");
    }
    const assignmentData = {
      ...formData,
      attachments: selectedFile,
    };
    dispatch(SET_CREATE_ASSIGNMENT(assignmentData));
    navigate("/teachersdashboard/assignment/preview");
    closeModal();
  };

  return (
    <>
      <form className="flex flex-col pb-6 pt-4 pr-5 h-[70%] pl-5 text-lg font-roboto bg-white rounded-lg max-w-[730px] text-teal-950 max-md:pl-5">
        <div className="flex gap-5 items-start text-xl font-medium max-md:flex-wrap max-md:max-w-full">
          <h3 className="flex-auto mt-2">Create New Assignment</h3>
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 w-6 aspect-square"
            onClick={closeModal}
          />
        </div>
        <label className="mt-4 max-md:max-w-full">Assignment name</label>
        <input
          name="title"
          value={title}
          onChange={handleInputChange}
          className="justify-center items-start px-5 py-2 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
          placeholder="Name the assignment"
        />
        <label className="mt-4 max-md:max-w-full">
          Assignment instructions
        </label>
        <textarea
          name="instructions"
          value={instructions}
          onChange={handleInputChange}
          className="justify-center items-start px-2 py-2 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
          placeholder="Add instructions for your new assignment"
        />
        <div className="flex gap-5 self-start mt-3 text-base text-sky-600">
          <div className="flex gap-2 px-2.5 py-1 rounded-md border border-sky-600 border-solid">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7af7bbe1487d55d4d1c8824af37d614580b469778d418c3ed3e7223e58fe0641?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="shrink-0 w-5 aspect-square"
            />
            <input
              placeholder="Add file"
              onChange={handleFileChange}
              className="w-full h-full outline-none border-none"
              type="file"
            />
          </div>
          <div className="flex gap-2 px-2.5 py-1 bg-white rounded-md border border-sky-600 border-solid">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/45e5e20e52b5c12abb49088bd6aab1809f48837c95e896a946a3b413acdf9e60?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="shrink-0 w-5 aspect-square"
            />
            <input
              name="link"
              value={link}
              onChange={handleInputChange}
              placeholder="Add ink"
              className="outline-none border-none w-full h-full"
              type="link"
            />
          </div>
        </div>
        <label className="mt-4 max-md:max-w-full">Overall grade</label>
        <input
          name="overallGrade"
          value={overallGrade}
          onChange={handleInputChange}
          className="justify-center items-start px-5 py-2 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
          placeholder="Set the overall score"
        />
        <label className="mt-4 max-md:max-w-full">Assignment duration</label>
        <div className="flex gap-5 mt-2.5 text-base whitespace-nowrap text-neutral-500 max-md:flex-wrap">
          <div className="flex gap-2.5 justify-between px-5 py-2 rounded-md border border-solid border-zinc-400">
            <p>From:</p>
            <input
              name="startDate"
              value={startDate}
              onChange={handleInputChange}
              placeholder="From"
              type="date"
              className="w-full h-full outline-none border-none"
            />
          </div>
          <div className="flex gap-2.5 justify-between px-5 py-2 rounded-md border border-solid border-zinc-400">
            <p>To:</p>
            <input
              name="dueDate"
              value={dueDate}
              onChange={handleInputChange}
              placeholder="To"
              type="date"
              className="w-full h-full outline-none border-none"
            />
          </div>
        </div>
        <button
          //disabled={isPreviewDisabled}
          onClick={handlePreview}
          className={`justify-center items-center text-center hover:bg-sky-800 px-5 py-2 mt-4 font-medium text-white bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full `}>
          Preview assignment
        </button>
      </form>
    </>
  );
};

export default CreateAssignment;
