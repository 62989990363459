import React from "react";

const Hero = () => {
  return (
    <section className="flex relative flex-col justify-center items-center self-stretch px-16 py-20 w-full text-white min-h-[411px] max-md:px-5 max-md:max-w-full">
      {/* Background Image */}
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
        alt="Background"
        className="object-cover absolute inset-0 size-full"
      />

      {/* Content Container */}
      <div className="flex relative flex-col mt-14 mb-7 max-w-full w-[751px] max-md:mt-10">
        <h1 className="self-center text-center text-6xl font-semibold md:text-5xl sm:text-4xl">
          About Us
        </h1>
        <p className="mt-4 text-base text-slate-200 font-medium leading-8 text-center mx-auto ">
          {/* Added max-w-md and md:max-w-sm for better text wrapping */}
          We're a reputable tech company dedicated to transforming potential
          into excellence and crafting the most efficient tech products.
        </p>
      </div>
    </section>
  );
};

export default Hero;
