import React, { useState } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import AddClassRecording from "./AddClassRecording";

const AddRecording = ({ onRecordLink, classData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const data = classData


  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="justify-center mt-10 items py-1.5 text-base hover:bg-sky-200 text-sky-600 rounded-md border border-sky-600 border-solid max-w-[330px]">
        Add class recording
      </button>
      <CustomModal isOpen={isModalOpen}>
        <AddClassRecording
          closeModal={() => setIsModalOpen(false)}
          classData={data}
        />
      </CustomModal>
    </>
  );
};

export default AddRecording;
