import React, {useState, useEffect} from "react";
import axios from "axios"
import { BACKEND_URL } from "../../../services/url";
import { CircularProgress } from "@mui/material";

const Cohort = () => {
   const [studentCount, setStudentCount] = useState(null); 
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/students/count`, 
          {
            withCredentials: true, 
          }
        );

        if (response.status === 200) {
          setStudentCount(response.data.studentCount);
        } else {
          throw new Error(response.data.error || "Failed to fetch student count");
        }
      } catch (error) {
        console.error("Error fetching student count:", error);
        setError(error.message || "An error occurred while fetching student count");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStudentCount(); 
  }, []); // Empty dependency array to fetch only once on mount

  return (
    <div className="flex flex-col pb-0 grow w-[30%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col p-2 mx-auto w-full bg-white rounded-lg shadow-lg max-md:mt-5">
        <div className="flex flex-row gap-1.5 py-4 justify-center text-sm text-white rounded bg-teal-950">
          <p className="text-white">Current Cohort:N/A </p>
        </div>
        <div className="flex gap-4 justify-between p-6 mt-11 bg-white rounded-lg border border-solid border-zinc-400 max-md:mt-10">
          <div className="flex flex-col">
            <div className="text-base text-neutral-500 ">No of Students</div>
            {isLoading ? (
              <CircularProgress size={24} /> 
            ) : error ? ( 
              <p className="text-red-500">{error}</p>
            ) : (
              <p className="mt-2.5 text-2xl font-medium text-teal-950">
                {studentCount}
              </p>
            )}
          </div>
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5bbeaced2bd79bdf72ab7c71ac57c5d4b56b9d20be01540497054b42687053ba?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 my-auto w-10 aspect-square"
          />
        </div>
      </div>
    </div>
  );
};

export default Cohort;
