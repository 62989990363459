import React from "react";
import { Link } from "react-router-dom";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";


const Updates = () => {
  useRedirectLoggedOutAdmin("/login")
  
  const updateItems = [
    {
      title: "FAQs",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/91b2f11a6aec14b83248ee060ec054b980703fbe0f5a064b7355a02688053445?apiKey=c4f2754934754c86a2ca35b496fd31f4&",
      path: "FAQs",
    },
    {
      title: "Testimonials",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/91b2f11a6aec14b83248ee060ec054b980703fbe0f5a064b7355a02688053445?apiKey=c4f2754934754c86a2ca35b496fd31f4&",
      path: "Testimonials",
    },
    {
      title: "About us",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/91b2f11a6aec14b83248ee060ec054b980703fbe0f5a064b7355a02688053445?apiKey=c4f2754934754c86a2ca35b496fd31f4&",
      path: "about us",
    },
  ];

  return (
    <div className="flex flex-col px-4 py-8 text-xl font-medium bg-neutral-50 text-zinc-800 md:px-10">
      <section className="px-8 py-10 bg-white rounded-lg shadow-lg max-md:px-5">
        <h2 className="text-2xl text-teal-950 mb-8">Website Updates</h2>

        <ul className="space-y-4 gap-4">
          {" "}
          {updateItems.map((item) => (
            <li
              key={item.title}
              className="flex gap-5 justify-between items-center p-5 whitespace-nowrap rounded-lg border border-solid border-zinc-400 max-md:flex-wrap max-md:mt-10">
              <span>{item.title}</span>
              <Link to={`/admindashboard/updates/${item.title}`}>
                <img
                  alt={item.title}
                  loading="lazy"
                  src={item.icon}
                  className="shrink-0 w-6 aspect-square"
                />
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Updates;

<img
  alt=""
  loading="lazy"
  src="https://cdn.builder.io/api/v1/image/assets/TEMP/91b2f11a6aec14b83248ee060ec054b980703fbe0f5a064b7355a02688053445?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
  className="shrink-0 w-6 aspect-square"
/>;
