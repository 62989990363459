import React, {useState} from 'react'
import CustomModal from '../../StudentsDashboard/Modal';
import CreateProject from './CreateProject';

const NoProject = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  

    return (
      <div className="flex flex-col justify-center items-center font-roboto w-full py-20 bg-neutral-50 px-5 max-md:px-0">
        <div className="flex flex-col justify-center items-center max-w-full">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b15556446a157cec9a86614401c4c80e756247a519484fd10a60b735c2f44848?"
            className="w-56 max-w-full aspect-[1.2]"
          />
          <p className="mt-10 w-full text-center text-xl text-zinc-800">
            No project yet. All projects will appear here.
          </p>
          <button
            onClick={() => setIsModalOpen(true)}
            className="px-10 py-2.5 justify-center mt-12 text-base text-white bg-sky-600 rounded-md">
            Create a project
          </button>
        </div>
        <CustomModal isOpen={isModalOpen}>
          <CreateProject closeModal={() => setIsModalOpen(false)} />
        </CustomModal>
      </div>
    );
}

export default NoProject;