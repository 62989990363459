import React from "react";

const AboutSection = () => {
  return (
    <section className="mt-10 w-full max-w-[1174px] p-5 md:p-10 font-roboto mx-auto max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col md:flex-row gap-5">
        <div className="md:w-1/2 max-md:w-full">
          <div className="flex flex-col self-stretch px-5 my-auto text-lg text-zinc-800 max-md:mt-10 max-md:max-w-full">
            <h2 className="text-4xl font-semibold text-teal-950">Who We Are</h2>
            <p className="mt-4 leading-7 text-base">
              Smart Approaches is a reputable tech company committed to
              empowering individuals with the skills and knowledge necessary to
              thrive in the 21st century. We believe in nurturing talent through
              comprehensive training and mentorship, enabling the next
              generation of tech experts to emerge, regardless of their academic
              background or prior knowledge.
            </p>
            <p className="mt-6 leading-7 text-base">
        
              At Smart Approaches, we also specialize in creating dynamic,
              user-friendly, and visually appealing websites. Our team of
              experienced developers, designers, business analysts, and project
              managers is dedicated to delivering high-quality web solutions
              that meet the unique needs of our clients. We aim to foster
              innovation and provide top-notch training that empowers
              individuals to excel in the tech industry.
            </p>
          </div>
        </div>
        <div className="md:w-1/2 max-md:w-full flex-shrink-0">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
            alt="About Us"
            className="w-full rounded-none aspect-[1.23] object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
