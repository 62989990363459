import React, {useState} from "react";
import axios from "axios"
import { BACKEND_URL } from "../../services/url"


const MakePayment = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")

   const makeSecondPayment = async () => {
     setIsLoading(true);
     try {
       const response = await axios.post(
         `${BACKEND_URL}/payments/make-second-payment`,
         {
           withCredentials: true,
         }
       );
       const approvalUrl = response.data.approvalUrl;
       if (approvalUrl) {
         window.location.href = approvalUrl;
       }
     } catch (error) {
       // Handle errors here
       console.error("Error creating PayPal order:", error);
       setError("An error occurred while creating the order");
     } finally {
       setIsLoading(false);
     }
   };
  return (
    <div
      
      className="bg-white border px-4 text-center cursor-pointer border-sky-600 border-solid shadow-sm max-w-[150px] min-h-[78px] rounded-[40px_0px_0px_40px] flex items-center justify-center">
      <p className="text-base font-roboto font-semibold">Make second payment now!</p>
    </div>
  );
};

export default MakePayment;
