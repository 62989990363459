import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import { BACKEND_URL } from "../../../services/url";

const AddTestimonials = ({ closeModal }) => {
 

  const [testimonialData, setTestimonialData] = useState({
    name: "",
    title: "",
    quote: "",
    image:null,
  });
  const [selectedFile, setSelectedFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTestimonialData({
      ...testimonialData,
      [name]: value,
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFiles(file);
    setTestimonialData({
      ...testimonialData,
      image:file
    })
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();

      // Append form fields
      formData.append("name", testimonialData.name);
      formData.append("title", testimonialData.title);
      formData.append("quote", testimonialData.quote);
      formData.append("image", testimonialData.image);
  
      const response = await axios.post(
        `${BACKEND_URL}/testimonials/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        // Successful creation
        toast.success(
          response.data.message || "Testimonial added successfully!"
        );
        closeModal(); // Close the modal after successful submission
        window.location.reload(); // reload the page to reflect the new testimonial
      } else {
        toast.error("Failed to add testimonial.");
      }
    } catch (error) {
      // Handle errors
      const errorMessage =
        error.response?.data?.error ||
        "An error occurred while adding the testimonial";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col font-roboto pt-5 pr-5 pb-10 pl-10 text-lg bg-white rounded-lg max-w-[730px] text-teal-950 max-md:pl-5">
      {/* Heading and Close Button */}
      <div className="flex justify-between items-center mb-5 md:mb-8">
        <h2 className="text-xl font-medium text-teal-950">Add Testimonial</h2>
        <img
          onClick={closeModal}
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 w-6 aspect-square"
        />
      </div>

      {/* Testimonial Form */}
      <form>
        {/* Name Input */}
        <div className="flex flex-col">
          <label htmlFor="fullName" className="max-md:max-w-full">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={testimonialData.name}
            onChange={handleInputChange}
            placeholder="Full name"
            className="mt-2.5 px-5 py-2 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
          />
        </div>

        {/* Title Input */}
        <div className="flex flex-col mt-4">
          <label htmlFor="title" className="max-md:max-w-full">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={testimonialData.title}
            onChange={handleInputChange}
            placeholder="Student"
            className="mt-2 px-5 py-2 text-base whitespace-nowrap rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
          />
        </div>

        {/* Image Upload */}
        <div className="flex flex-col mt-2">
          <label htmlFor="image" className="max-md:max-w-full">
            Add Image
          </label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            accept="image/*"
            className="mt-2.5 file:mr-5 file:px-5 file:py-2.5 file:rounded-md file:border-0 file:text-sm file:font-medium file:text-sky-600 file:bg-sky-50 hover:file:cursor-pointer hover:file:bg-sky-100"
          />
        </div>

        {/* Testimonial Text Area */}
        <div className="flex flex-col mt-4">
          <label htmlFor="testimonial" className="max-md:max-w-full">
            Testimonial
          </label>
          <textarea
            id="quote"
            name="quote"
            value={testimonialData.quote}
            onChange={handleInputChange}
            placeholder="Input testimonial"
            className="mt-2 px-5 py-5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
          />
        </div>

        {/* Add Testimonial Button */}
        <button
          onClick={handleSubmit}
          type="submit"
          className="mt-8 px-5 w-full py-2 font-medium text-white bg-sky-600 rounded-md max-md:mt-10">
          {isLoading ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20" // Adjust the height
                width="20" // Adjust the width
                color="#fff" // Choose your desired color
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "  Add testimonial"
          )}
        </button>
      </form>
    </div>
  );
};

export default AddTestimonials;
