import * as React from "react";
import { useNavigate } from "react-router-dom";

const SubmitSuccess = ({ closeModal,text,desc,buttonText,handleButtonClick}) => {
  const navigate = useNavigate()

  // const handleButtonClick = (link) => {
  //   navigate(link)
  //   window.location.reload()
  //   closeModal()
  // }

  return (
    <div className="flex flex-col font-roboto rounded-t-3xl items-center pb-5 px-8 pt-0 text-lg font-medium bg-white rounded-lg max-w-[540px] max-md:pl-5">
          <img
          onClick={closeModal}
              alt=""
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
        className="self-end w-6 aspect-square mt-5"
      />
          <img
              alt=""
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f025cb678355c8c04a4031673254b5a4ec4edd0f02d4b7ad0439375f921553d3?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
        className="mt-5 max-w-full aspect-square w-[120px]"
      />
      <div className="mt-11 text-xl font-semibold text-teal-950 max-md:mt-8">
        {text}
      </div>
      <div className="mt-7 text-center leading-[150%] text-zinc-800">
        {desc}
      </div>
      <button onClick={handleButtonClick} className="justify-center text-center items-center self-stretch px-5 py-2 mt-10 text-white bg-sky-600 hover:bg-sky-800 rounded-md max-md:mt-10 max-md:max-w-full">
        {buttonText}
      </button>
    </div>
  );
}


export default SubmitSuccess;