import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { convertDate } from "../../../utils/convertDate";
import pdflogo from "../../../images/pdf.png";
import { createAssignment } from "../../../services/assignmentService";
import { toast } from "react-toastify";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess";
import CustomModal from "../../StudentsDashboard/Modal";
import CreateAssignment from "./CreateAssignment";
import { Circles } from "react-loader-spinner"; // Example: Import Audio Loader
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCreatedAssignment,
  CLEAR_CREATED_ASSIGNMENT,
} from "../../../redux/assignment/assignmentSlice";
import EditableAssignment from "./EditableAssignment";

const AssignmentPreview = () => {
  const text = "Assignment created successfully";
  const desc = "You have successfully created an assignment";
  const buttonText = "View Assignments";
  const buttonLink = "/teachersdashboard/assignment";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateAssOpen, setIsCreateAssOpen] = useState(false);
  const assignmentData = useSelector(selectCreatedAssignment);
  const {
    title,
    instructions,
    overallGrade,
    attachments,
    link,
    startDate,
    dueDate,
  } = assignmentData;

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const createImageUrl = async () => {
      if (!attachments) return "";
      const newImageUrl = await URL.createObjectURL(attachments);
      setImageUrl(newImageUrl);
    };
    createImageUrl();
  }, [attachments]);

  const handleCreateAssignment = async (e) => {
    const convertedGrade = parseInt(overallGrade, 10);

    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("instructions", instructions);
    formData.append("overallGrade", convertedGrade);
    formData.append("link", link);
    formData.append("attachments", attachments);
    formData.append(
      "startDate",
      new Date(startDate).toISOString().split("T")[0]
    );
    formData.append("dueDate", new Date(dueDate).toISOString().split("T")[0]);
    setIsLoading(true);
    try {
      const response = await createAssignment(formData);
      if (response && response.message) {
        dispatch(CLEAR_CREATED_ASSIGNMENT());
        toast.success(response.message);
        setIsModalOpen(true);
      } else {
        //navigate("/login");
      }
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        if (error.response.status === 401) {
          errorMessage = "Session expired. Please log in again.";
          //navigate("/login");
        } else if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        }
      }
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessButtonClick = (link) => {
    navigate(link);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-col justify-center p-10 bg-neutral-50 max-md:px-5">
        <div className="flex gap-5 justify-between px-8 py-4 w-full bg-white rounded-lg shadow-lg max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <h3 className="my-auto text-xl font-medium text-teal-950">
            Assignment
          </h3>
          <div className="flex gap-5">
            <div className="my-auto text-xl font-medium text-zinc-800">
              <span className="text-base  text-neutral-500">Due:</span>{" "}
              <span className="text-lg text-zinc-800">
                {convertDate(dueDate)}
              </span>
            </div>
            <Link
              to="/teachersdashboard/assignment"
              className="justify-center px-2 py-0 md:px-5 md:py-2.5 text-sm md:text-base text-sky-600 rounded-md border border-sky-600 border-solid">
              View submissions
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start px-12 py-10 mt-10 bg-white rounded-lg shadow-lg text-neutral-500 max-md:px-5 max-md:max-w-full">
          <p className="text-sm">Assignment title:</p>
          <h4 className="mt-2.5 text-base font-medium text-zinc-800">
            {title}
          </h4>
          <p className="mt-5 text-sm max-md:max-w-full">Assignment requirement:</p>
          <p className="mt-2.5 text-base leading-6 text-zinc-800 max-md:max-w-full">
            {instructions}
          </p>

          <p className="mt-5 text-sm">Attachments</p>
          <div className="flex gap-2.5 mt-2.5 text-zinc-800">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                <div className="flex flex-row px-1.5 py-2 text-xs bg-white rounded-md">
                  {imageUrl && (
                    <embed
                      src={imageUrl}
                      type="application/pdf"
                      className="w-[300px] h-full border-none"
                    />
                  )}
                </div>
                <p className="self-center mt-1.5 text-xs">
                  {attachments?.name}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center text-xs whitespace-nowrap">
              <div className="flex flex-col items-center px-2 py-2 rounded-md bg-slate-50">
                <embed
                  alt="preview"
                  loading="lazy"
                  src={imageUrl}
                  className="aspect-[1.23] w-[124px]"
                />
                {attachments && (
                  <p className="mt-1.5 text-center">{attachments?.name}</p>
                )}
              </div>
            </div>
          </div>
          <p className="mt-5 text-sm">Links</p>
          <p className="mt-2.5 text-base leading-6 text-sky-600">{link}</p>
          <div className="mt-5 text-sm">Duration</div>
          <p className="mt-2.5 text-base leading-6 text-zinc-800">
            {convertDate(startDate)} - {convertDate(dueDate)}
          </p>
        </div>
        <div className="flex flex-row mt-2 gap-2 items-center justify-center w-full">
          <button
            onClick={() => setIsCreateAssOpen(true)}
            className="justify-center hover:bg-sky-800 items-center w-[50%] font-medium text-white bg-sky-600 rounded-md max-md:mt-14 m">
            Edit
          </button>
          <button
            onClick={handleCreateAssignment}
            className="justify-center hover:bg-sky-800 items-center w-[50%] font-medium text-white bg-sky-600 rounded-md max-md:mt-14 m">
            {isLoading ? (
              <div className="inset-0 flex items-center justify-center">
                <Circles
                  height="20" // Adjust the height
                  width="20" // Adjust the width
                  color="#fff" // Choose your desired color
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              "Create assignment"
            )}
          </button>
        </div>
        <CustomModal isOpen={isCreateAssOpen}>
          <EditableAssignment closeModal={() => setIsCreateAssOpen(false)} />
        </CustomModal>
      </div>
      <CustomModal isOpen={isModalOpen}>
        <SubmitSuccess
          closeModal={() => setIsModalOpen(false)}
          text={text}
          desc={desc}
          buttonText={buttonText}
          link={buttonLink}
          handleButtonClick={() =>
            handleSuccessButtonClick("/teachersdashboard/assignment")
          }
        />
      </CustomModal>
    </>
  );
};

export default AssignmentPreview;
