import axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_URL } from "./url";

export const createProjectService = async (projectData) => {
  try {
    // const formData = new FormData();
    // for (const key in projectData) {
    //   formData.append(key, projectData[key]);
    // }
    const response = await axios.post(
      `${BACKEND_URL}/project/create`,
      projectData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    if (response.status === 201) {
      // Check for 201 Created status
      toast.success("Project created successfully");
    }

    return response.data;
  } catch (error) {
    // Enhanced Error Handling
    const errorMessage =
      error.response?.data?.error || "An error occurred while creating Project";

    switch (error.response?.status) {
      case 400:
        toast.error("Bad Request: Missing or invalid data.");
        break;
      case 401:
        toast.error("Unauthorized: Please log in again.");
        break;
      default:
        toast.error(errorMessage);
    }
  }
};

export const submitProject = async (projectData) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/project/submit`,
      projectData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    if (response.statusText === "OK") {
      toast.success("Project submitted successfully");
    }

    return response;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

export const fetchSubmittedProjects = async (projectId) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/project/submission/${projectId}`,
      { withCredentials: true }
    );
    if (response.statusText === "OK") {
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};

export const fetchProjectSubmissionCounts = async (assignmentId) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/project/count`, {
      withCredentials: true,
    });
    if (response.statusText === "OK") {
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};

export const fetchProjectProgress = async (projectId) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/project/progress/${projectId}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.statusText === "OK") {
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};

export const postReview = async (submissionId, studentId, reviewedData) => {
  try {
    const response = await axios.put(
      `${BACKEND_URL}/project/${submissionId}/students/${studentId}/review/`,
      reviewedData,
      {
        withCredentials: true,
      }
    );
    if (response.statusText === "OK") {
      toast.success("submission reviewed successfully");
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};
