import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "../redux/auth/authSlice";
import assignmentSlice from "./assignment/assignmentSlice";
import classSlice from "./class/classSlice"
import projectSlice from "./project/projectSlice";
import filterSlice from "./filter/filterSlice";
import storage from "redux-persist/lib/storage";

import {persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
  REGISTER,
     createMigrate
} from "redux-persist"

// const migrations = {
//   0: (state) => ({
//     ...state,
//     user: {
//       studentId: "",
//       firstName: "",
//       lastName: "",
//       country: "",
//       email: "",
//       phoneNumber: null,
//       username: "",
//       course: "",
//       token: "",
//     },
//   }),
//   1: (state) => {
//     return {
//       ...state,
//       user: state.user || {},
//     };
//   },
// };
const persistConfig = {
  key: "root",
  version: 2,
  storage,
 // migrate: createMigrate(migrations, { debug: false }),
};


const rootReducer = combineReducers({ auth: authReducer,assignment:assignmentSlice,project:projectSlice, class:classSlice, filter:filterSlice});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);