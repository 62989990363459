import React, { useState } from "react";
import EmptyClass from "./EmptyClass";
import ClassesCard from "./ClassesCards.js";
import Upcoming from "./CardDetails.js";
import Bacd from "./Bacd.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectClasses } from "../../../redux/class/classSlice.js";
import { formatDate } from "../../../utils/convertDate.js";


const Classes = () => {
  const cardData = useSelector(selectClasses)
  return (
    <>
      {cardData.length === 0 ? (
        <EmptyClass/>
      ) : (
          <>
            <div className="flex flex-col mt-4 justify-center max-md:px-5">
        <div className="flex gap-5 justify-between px-8 py-4 bg-white rounded-lg shadow-md max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <p className="my-auto text-xl font-medium text-teal-950">Classes</p>
          <Link
            to="/studentdashboard/classes/course_materials"
            className="justify-center px-5 py-2.5 text-base text-sky-600 rounded-md border border-sky-600 border-solid">
            View course materials
          </Link>
        </div>
      </div>
            {cardData && cardData.length > 0 && <ClassesCard cardData={cardData} />}
            </>
      )}
    
    </>
  );
};

export default Classes;
