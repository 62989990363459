import React, { useState, useEffect } from "react";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { formatDate, convertDate } from "../../../utils/convertDate";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";

function ProjectDetails() {
  const [project, setProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState();
  const projectAttachment =
    project?.attachments && JSON.parse(project?.attachments);
  const params = useParams();
  const navigate = useNavigate();

  const projectData = {
    title: "Smart Approaches We Learn 2",
    dueDate: "24 May 2024",
    requirement: [
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.",
      "Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis condimentum ac, vestibulum eu nisl.",
    ],
    attachments: [
      {
        type: "pdf",
        content: [
          "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
          "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
          "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
        ],
        fileName: "Project.pdf",
      },
      {
        type: "image",
        content:
          "https://cdn.builder.io/api/v1/image/assets/TEMP/053889ff65bca287596c6c3d3eec72a4759fa35eda797cdaca6e9b52c4a06beb?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4",
        fileName: "Project.png",
      },
    ],
    links: ["abcjira.com"],
    duration: "03 May 2024 - 24 May 2024",
  };

  // fetch assignment
  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/project/${params.id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setProject(response.data);

          setProjectId(response.data.id);
        } else {
          console.error.error(
            response.data?.error || "Failed to fetch Project. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProject();
  }, []);
  const absoluteUrl = project.link?.startsWith("https://")
    ? project.link
    : `https://${project.link}`;
  return (
    <main className="flex overflow-hidden flex-col p-10 bg-neutral-50 max-md:px-5">
      <header className="flex flex-wrap gap-10 justify-between items-center px-8 py-4 w-full bg-white rounded-lg shadow-lg max-md:px-5 max-md:max-w-full">
        <h1 className="self-stretch my-auto text-xl font-medium text-teal-950">
          {project?.title}
        </h1>
        <div className="flex gap-5 items-center self-stretch my-auto min-w-[240px]">
          <div className="self-stretch my-auto text-xl font-medium text-teal-950">
            <span className="text-base text-neutral-500">Due:</span>{" "}
            <span className="text-lg text-zinc-800">
              {formatDate(project?.due_date)}
            </span>
          </div>
          <button
            onClick={() =>
              navigate(`/teachersdashboard/project/list/${project?.id}`)
            }
            className="gap-2.5 self-stretch px-5 py-2.5 my-auto text-base text-sky-600 rounded-md border border-sky-600 border-solid">
            View submissions
          </button>
        </div>
      </header>
      <section className="flex overflow-hidden flex-col justify-center items-start px-12 py-10 mt-10 bg-white rounded-lg shadow-lg max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col">
          <h2 className="text-sm text-neutral-500">Project title:</h2>
          <p className="mt-2.5 text-base font-medium text-zinc-800">
            {project?.title}
          </p>
        </div>
        <div className="flex flex-col mt-5 w-full max-w-[954px] max-md:max-w-full">
          <h3 className="text-sm text-neutral-500">Project requirement:</h3>
          <div className="flex flex-col mt-2.5 w-full text-base leading-6 text-zinc-800">
            <p className="mt-1.5 max-md:max-w-full">{project?.instructions}</p>
          </div>
        </div>
        <div className="flex flex-col mt-5">
          <h3 className="text-sm text-neutral-500">Attachments</h3>
          <div className="flex gap-2.5 items-start mt-2.5 text-zinc-800">
            {projectAttachment &&
              projectAttachment.map((attachment, index) => (
                <div className="flex gap-2.5 mt-2.5">
                  <div className="flex flex-col justify-center">
                    <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                      <div className="flex flex-col px-1.5 md:w-[500px] py-2 text-xs bg-white rounded-md">
                        {projectAttachment && (
                          <Thumbnail fileUrl={attachment?.filePath} />
                        )}
                      </div>
                      <p className="self-center mt-1.5 text-xs">
                        <a
                          href={attachment?.filePath}
                          target="_blank"
                          rel="noreferrer">
                          {attachment?.fileName}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center text-xs whitespace-nowrap">
                    <div className="flex flex-col items-center px-2 py-2 rounded-md bg-slate-50">
                      <img
                        alt=""
                        loading="lazy"
                        srcSet={convertToPngUrl(attachment?.filePath)}
                        className="aspect-[1.23] w-[124px]"
                      />
                      <p className="mt-1.5">{attachment?.fileName}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col mt-5 whitespace-nowrap">
          <h3 className="text-sm text-neutral-500">Links</h3>
          <a href={absoluteUrl} className="mt-2.5 text-base text-sky-600">
            {project?.link}
          </a>
        </div>
        <div className="flex flex-col mt-5">
          <h3 className="text-sm text-neutral-500">Duration</h3>
          <p className="mt-2.5 text-base text-zinc-800">
            {`  ${formatDate(project?.start_date)} - ${formatDate(
              project?.due_date
            )}`}
          </p>
        </div>
      </section>
    </main>
  );
}

export default ProjectDetails;
