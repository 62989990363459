import React from "react";

const CardInput = ({onPayment}) => {
  return (
    <div>
      <form className="flex flex-col">
        {/* <label className="mt-11 text-lg text-teal-950 max-md:mt-10 max-md:max-w-full">
          Card holder name
        </label>
        <input
          placeholder="John doe"
          className="justify-center items-start px-5 py-2 mt-2.5 text-base border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
        />

        <label className="mt-5 text-lg text-teal-950 max-md:max-w-full">
          Card number
        </label>
        <input
          placeholder="1234 5678 9012 3456"
          className="justify-center items-start px-5 py-2 mt-2.5 text-base border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
        />

        <div className="flex gap-5 mt-5 max-md:flex-wrap">
          <div className="flex flex-col">
            <label className="text-lg text-teal-950">Expiry date</label>
            <input
              placeholder="MM/YY"
              className="justify-center items-start px-5 py-2 mt-2.5 text-base whitespace-nowrap border border-solid border-zinc-400 text-neutral-500"
            />
          </div>
          <div className="flex flex-col whitespace-nowrap">
            <label className="text-lg text-teal-950">CVV</label>
            <input
              placeholder="123"
              className="justify-center items-start px-5 py-2 mt-2.5 text-base border border-solid border-zinc-400 text-neutral-500"
            />
          </div>
        </div> */}
        <buton onClick={onPayment} className="justify-center hover:bg-sky-800 text-center items-center px-5 py-2 mt-16 text-lg font-medium text-white bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full">
          Proceed with Payment
        </buton>
      </form>
    </div>
  );
};

export default CardInput;
