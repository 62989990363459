import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import RegisterStudent from "./RegisterStudent";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url";

const Cohort = () => {
  const [selectedCourse, setSelectedCourse] = useState("All"); // Use useState for controlled input
  const [cohortData, setCohortData] = useState({
    name: "All",
    noOfStudents: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cohortOptions = ["All", "BA", "DA"];
  const [cohort, setCohorts] = useState([]);

  useEffect(() => {
    const fetchCohorts = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/cohorts`);
        console.log("response in cohort: ",response)
        const data = response.data.cohorts;
        const cohortData = data && data.map((cohort) => cohort);
        setCohorts(cohortData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCohorts();
  }, []);

  console.log("cohort in cohort: ",cohort)

  useEffect(() => {
    const fetchStudentStatistics = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/users/student/statistics`
        );
        const statistics = response.data;
        const updatedCohortData = {
          name: "All",
          noOfStudents: statistics.totalStudents,
        };
        setCohortData(updatedCohortData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentStatistics();
  }, []);

  const handleSelectRadioButton = (event) => {
    setSelectedCourse(event.target.value);
    const fetchStudentStatistics = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/users/student/statistics`
        );
        const statistics = response.data;
        let updatedCohortData;
        if (event.target.value === "BA") {
          updatedCohortData = {
            name: "BA",
            noOfStudents: statistics.businessAnalysisStudents,
          };
        } else if (event.target.value === "DA") {
          updatedCohortData = {
            name: "DA",
            noOfStudents: statistics.dataAnalysisStudents,
          };
        } else {
          updatedCohortData = {
            name: "All",
            noOfStudents: statistics.totalStudents,
          };
        }
        setCohortData(updatedCohortData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentStatistics();
  };

  return (
    <div className="flex flex-col px-4 py-5 bg-white rounded-lg shadow-lg max-md:px-5 w-full">
      <p className="flex gap-2.5 text-xs justify-center p-2.5 text-white rounded bg-teal-950">
        {"Current Cohort: "}
        {cohort[0]?.start_date ? (
          <>
            {new Date(cohort[0]?.start_date).toLocaleString("default", {
              month: "long", 
            })}
            {" "}
            {new Date(cohort[0]?.start_date).getFullYear()}
          </>
        ) : (
          "N/A"
        )}
      </p>

      {/* Radio Button Group (using fieldset & legend for semantics) */}
      <fieldset className="mt-8 w-full text-sm text-neutral-500">
        <legend className="sr-only">Select Course</legend>
        <div className="flex gap-5 justify-between max-md:flex-col">
          {cohortOptions.map((option) => (
            <label
              key={option}
              className="flex items-center gap-1.5 cursor-pointer">
              <input
                type="radio"
                name="course"
                value={option}
                checked={selectedCourse === option}
                onChange={handleSelectRadioButton}
                className="shrink-0 w-3.5 h-3.5 rounded-full border-2 border-solid border-zinc-400 stroke-[2px]"
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
      </fieldset>

      <div className="flex flex-col md:flex-row justify-between p-5 mt-4 bg-white rounded-lg border border-solid border-zinc-400 items-center">
        {/* Number of Students Section */}
        <div className="flex flex-col">
          <span className="text-lg text-neutral-500">No of Students</span>
          <span className="mt-2.5 text-2xl font-medium text-teal-950">
            {cohortData?.noOfStudents || 0}{" "}
            {/* Display 0 if noOfStudents is undefined */}
          </span>
        </div>

        {/* Image (Add alt text for accessibility) */}
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/88463c0eac0d2aebf5f9c97b5a990e08297df268b8472bb5a45224d7795bd257?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          alt="Register Student"
          className="shrink-0 my-auto w-10 aspect-square"
        />
      </div>

      {/* Register Student Button (Make it a Link or Button) */}
      <p
        onClick={() => setIsModalOpen(true)}
        className="self-center cursor-pointer mt-5 text-sm text-sky-600">
        Register new student
      </p>
      <CustomModal isOpen={isModalOpen}>
        <RegisterStudent closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default Cohort;
