import React from "react";

const UserDetails = ({ user, course, paymentDetails, handlePayment }) => {
  return (
    <div className="mt-14 font-roboto max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[58%] max-md:ml-0 max-md:w-full">
          {/* Personal Details Table */}
          <table className="min-w-full h-auto divide-y divide-gray-200 bg-white rounded-lg shadow-lg max-md:px-5 max-md:mt-10">
            <tbody>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">Name:</th>
                <td className="text-zinc-800 py-3 px-6">
                  {user.firstName} {user.lastName}
                </td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">Email:</th>
                <td className="text-zinc-800 py-3 px-6">{user.email}</td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">
                  Phone Number:
                </th>
                <td className="text-zinc-800 py-3 px-6">{user.phoneNumber}</td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">Course:</th>
                <td className="text-zinc-800 py-3 px-6">{course}</td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">
                  Student ID:
                </th>
                <td className="text-zinc-800 py-3 px-6">{`0123${user.studentId}`}</td>{" "}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex flex-col ml-5 w-[42%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-12 py-16 w-full text-base font-medium bg-white rounded-lg shadow-lg max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="text-xl text-teal-950">Account Type</div>

            {/* Account Status */}
            <div
              className={`p-2.5 mt-8 rounded-md max-md:px-5 ${
                paymentDetails.paymentComplete
                  ? "text-green-600 bg-green-100"
                  : "text-red-600 bg-red-100"
              }`}>
              {paymentDetails.paymentComplete
                ? "Permanent Access"
                : "Temporary Access"}
            </div>

            {/* Installment Payment Button */}
            {!paymentDetails.paymentComplete && (
              <div className="mt-8">
                <p className="mt-11 text-zinc-800 max-md:mt-10">
                  Pay your second installment to get full access
                </p>
                <button
                  onClick={handlePayment}
                  className="self-start px-5 py-3 hover:bg-sky-800 text-center mt-4 text-lg text-white bg-sky-600 rounded-md">
                  Pay now
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;