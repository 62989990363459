import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import CreateProject from "./CreateProject";
import { fetchProjectSubmissionCounts } from "../../../services/projectService";
import NoProject from "./NoProject";
import PCards from "./PCards";
import { useDispatch } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import useRedirectLoggedOutTutor from "../../../customHook/useRedirectLoggedOutTutor";
import {
  SET_PROJECT,
  SET_PROJECT_COUNT,
  SET_PROJECTS,
} from "../../../redux/project/projectSlice";

const TeachersProject = () => {
  useRedirectLoggedOutTutor("/login")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const dispatch = useDispatch();

  // const assignment = [
  //   {
  //     title: "User flow diagram",
  //     publishedOn: "15 May 2024",
  //     dueDate: "20 May 2024",
  //     submittedCount: 5,
  //     totalCount: 20,
  //     width: "250px",
  //   },
  //   {
  //     title: "SRS documentation",
  //     publishedOn: "09 May 2024",
  //     dueDate: "11 May 2024",
  //     submittedCount: 20,
  //     totalCount: 20,
  //     width: "100%",
  //   },
  //   {
  //     title: "User story acceptance criteria",
  //     publishedOn: "09 May 2024",
  //     dueDate: "29 May 2024",
  //     submittedCount: 0,
  //     totalCount: 20,
  //     width: "100%",
  //   },
  // ];

  const handleProjectClick = (projectId) => {
    dispatch(SET_PROJECT_COUNT(projectId));
  };

  // fetch Assignment Counts
  useEffect(() => {
    const fetchProjectCount = async () => {
      setIsLoading(true);
      try {
        const response = await fetchProjectSubmissionCounts();

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setProjectData(response.data);
          dispatch(SET_PROJECTS(response.data));
        } else {
          console.error(
            response.data?.error ||
              "Failed to fetch assignments. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching project counts";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProjectCount();
  }, []);

  return (
    <>
      {isLoading && (
        <Box sx={{ display: "flex w-full", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && projectData.length === 0 ? (
        <NoProject />
      ) : (
        <div className="flex flex-col font-roboto items-center px-12 pt-11 pb-20 bg-neutral-50 max-md:px-5">
          <div className="flex gap-5 w-full max-w-[1040px] max-md:flex-wrap max-md:max-w-full">
            <h3 className="flex-auto my-auto text-xl font-medium text-teal-950">
              Projects
            </h3>
            <button
              onClick={() => setIsModalOpen(true)}
              className="justify-center hover:bg-gray-200 px-5 py-2.5 text-base text-sky-600 rounded-md border border-sky-600 border-solid">
              Create Project
            </button>
          </div>
          {projectData &&
            projectData.map((project, index) => (
              <PCards
                key={index}
                {...project}
                handleClick={() => handleProjectClick(project.countId)}
              />
            ))}
          <CustomModal isOpen={isModalOpen}>
            <CreateProject closeModal={() => setIsModalOpen(false)} />
          </CustomModal>
        </div>
      )}
    </>
  );
};

export default TeachersProject;
