import React from "react";
import { calculateCourseProgress } from "../../../utils/courseProgressCalculator";

function CourseProgressCircle({ courseName, startDate, courseDuration }) {
  const progress = calculateCourseProgress(startDate, courseDuration);
  const progressPercentage =
    (progress.split("/")[0] / progress.split("/")[1]) * 100;

  const borderColor = courseName.includes("Business Analysis")
    ? "border-blue-500"
    : "border-orange-500";

  if (!startDate || !courseDuration) {
    return <div>Error: Invalid start date or course duration</div>;
  }

  return (
    <div className="relative rounded-full border-4 shrink-0 w-20 aspect-square md:w-16">
      <div
        className={`absolute top-0 left-0 w-full h-full rounded-full bg-transparent ${borderColor} w-[${progressPercentage}] `}
        style={{
          background: `conic-gradient(${borderColor} calc(${progressPercentage}% - 4px), transparent calc(${progressPercentage}% - 4px))`, // Adjusted gradient type
          borderWidth: "4px", // Border is applied to the outer div, so no need here
        }}
      />
      <div className="absolute flex flex-col justify-center items-center w-full h-full text-sm font-medium">
        {progress}
        <p className="text-[8px] ">weeks done</p>
      </div>
    </div>
  );
}

export default CourseProgressCircle;
