import React, { useState } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import CancelClassPrompt from "./CancelClassPromp";
import AddRecording from "./AddRecordingButton";
import { formatDate, formatTimeTo12Hour } from "../../../utils/convertDate";

const ClasstDetails = ({ classData, closeCard }) => {
  const upcoming = classData.status === "Upcoming";
  const done = classData.status === "Done";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordLink, setRecordLink] = useState("");

  const handleRecordLink = (link) => {
    setRecordLink(link);
  };

  return (
    <div className="flex flex-col text-center w-[420px] pt-1 pb-5 text-base bg-white rounded-lg shadow-lg ">
      <img
        alt=""
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?"
        className="self-end w-6 aspect-square"
        onClick={closeCard}
      />
      <h3
        className={`justify-center text-center items-center px-5 py-2 mt-2 w-full text-sky-600 whitespace-nowrap bg-sky-100 ${
          classData.status === "Upcoming" || classData.status === "Pending"
            ? "bg-sky-100 text-sky-800"
            : classData.status === "Done"
            ? "bg-green-200 text-green-800"
            : classData.status === "Cancelled"
            ? "bg-red-200 text-red-800"
            : ""
        }`}>
        {classData.status}
      </h3>
      <div className="flex flex-col px-8 mt-9 w-full text-neutral-500">
        <p className="text-sm font-medium">Class title:</p>
        <p className="mt-1.5 font-medium text-zinc-800">
          {classData.class_title}
        </p>
        <p className="mt-8 text-sm font-medium">Class link:</p>
        <a
          href="https://meet.goto.com/123456789"
          className="mt-1.5 text-sky-600">
          {classData.class_link}
        </a>
        <p className="self-center mt-8 text-sm font-medium">Duration:</p>
        <p className="self-center mt-1.5 text-zinc-800">
          {formatDate(classData.class_date)}
        </p>
        <p className="self-center mt-1.5 text-zinc-800">
          {formatTimeTo12Hour(classData.start_time)}
        </p>
        <p className="mt-8 text-sm font-medium">Description:</p>
        <p className="mt-1.5 leading-6 text-zinc-800">
          {classData.description}
        </p>
        {classData.class_recordings ? (
          <>
            <p className="mt-4 text-sm font-medium">class record:</p>
            <p className="mt-1 leading-6 text-zinc-800 break-words">
              {classData.class_recordings}
            </p>
          </>
        ) : null}

        {upcoming ? (
          <div className="flex gap-0.5 self-end mt-20 text-sky-600">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/06b4336a0efa754ac4371abc46ab43496185841525d4b4c84ca0687b6d88651e?"
              className="shrink-0 w-5 aspect-square"
            />
            <p className="text-sky-600" onClick={() => setIsModalOpen(true)}>
              Cancel class
            </p>
          </div>
        ) : done && classData.class_recordings === null ? (
          <AddRecording onRecordLink={handleRecordLink} classData={classData} />
        ) : null}
      </div>
      <CustomModal isOpen={isModalOpen}>
        <CancelClassPrompt
          closeModal={() => setIsModalOpen(false)}
          classData={classData}
        />
      </CustomModal>
    </div>
  );
};

export default ClasstDetails;
