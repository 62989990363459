import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomModal from "../../StudentsDashboard/Modal";
import CancelReason from "./CancelReason";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTimeTo12Hour } from "../../../utils/convertDate";

const CancelClassPrompt = ({ classData, closeModal }) => {
  const data = classData;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="flex flex-col pt-5 font-roboto pb-20 bg-white rounded-lg max-w-[540px]">
      <div className="flex flex-col self-end mr-5 max-w-full text-2xl font-medium text-teal-950 w-[322px] max-md:mr-2.5">
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?"
          className="self-end w-6 aspect-square"
          onClick={closeModal}
        />
      </div>
      <h3 className="mt-5 flex justify-center text-center font-normal text-lg">
        Cancel Class?
      </h3>

      <div className="flex flex-col px-8 mt-16 w-full text-lg max-md:pl-5 max-md:mt-10 max-md:max-w-full">
        <p className="leading-7 text-center text-zinc-800 max-md:max-w-full">
          Are you sure you want to cancel the class scheduled for{" "}
          <span className="font-medium">
            {`${formatDate(classData.class_date)} from ${formatTimeTo12Hour(
              classData.start_time
            )} to ${formatTimeTo12Hour(classData.end_time)} `}
          </span>
        </p>
        <div className="flex gap-5 mt-28 justify-center font-medium whitespace-nowrap max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
          <Link
            onClick={closeModal}
            className="justify-center items-center px-10 py-4 text-sky-600 rounded-md border border-sky-600 border-solid">
            No
          </Link>
          <button
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="justify-center items-center px-10 py-4 text-white bg-sky-600 rounded-md">
            Yes
          </button>
          <CustomModal isOpen={isModalOpen}>
            <CancelReason
              closeModal={() => {
                setIsModalOpen(false);
                navigate("/teachersdashboard/classes");
              }}
              classData={data}
            />
          </CustomModal>
        </div>
      </div>
    </div>
  );
};

export default CancelClassPrompt;
