import React from "react";

const CustomModal = ({ isOpen, closeModal, children, maxWidth = "lg" }) => {
  if (!isOpen) return null; // Early return if not open

  return (
    <div
      className="fixed overflow-auto z-50 w-full mx-auto inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
      onClick={closeModal}>
      {/* Modal Content */}
      <div
        className="rounded-lg  transform transition-all m-auto"
        onClick={(e) => e.stopPropagation()} // Prevent closing on content click
        style={{ maxWidth: `40rem` }} // Control max-width based on Tailwind breakpoint
      >
      

        <div className="p-4 md:p- w-full md:w-[600px] lg:p-8">
          {" "}
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
