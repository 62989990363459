import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CiGrid42 } from "react-icons/ci";
import { FiBookOpen } from "react-icons/fi";
import { CgNotes } from "react-icons/cg";
import { MdOutlineTableChart } from "react-icons/md";
import { GrSearch } from "react-icons/gr";
import { RiLogoutBoxLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { logoutUser } from "../../services/authServices";
import { useDispatch } from "react-redux";
import { SET_LOGIN } from "../../redux/auth/authSlice";

const StudentSidebar = () => {
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("dashboard");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // Derive selectedButton from the current route
  const [selectedButton, setSelectedButton] = useState(() => {
    const path = location.pathname;
    if (path === "/studentdashboard") return "dashboard";
    if (path === "/studentdashboard/classes") return "classes";
    if (path === "/studentdashboard/assignment") return "assignment";
    if (path === "/studentdashboard/project") return "project";
    if (path === "/studentdashboard/explore") return "explore";
    return "dashboard"; // Default to dashboard
  });

  useEffect(() => {
    // Update selectedButton whenever the route changes
    const path = location.pathname;
    if (path === "/studentdashboard") setSelectedButton("dashboard");
    else if (path === "/studentdashboard/classes") setSelectedButton("classes");
    else if (path === "/studentdashboard/assignment")
      setSelectedButton("assignment");
    else if (path === "/studentdashboard/project") setSelectedButton("project");
    else if (path === "/studentdashboard/explore") setSelectedButton("explore");
    else setSelectedButton("dashboard"); // Default to dashboard
  }, [location]);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const handleLogout = async () => {
    await logoutUser();
    dispatch(SET_LOGIN(false));
    navigate("/login");
  };

  const handleButtonHover = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleButtonLeave = () => {
    setHoveredButton(null);
  };

  const sidebarItems = [
    {
      icon: CiGrid42,
      text: "Dashboard",
      path: "/studentdashboard",
    },
    {
      icon: FiBookOpen,
      text: "My Classes",
      path: "/studentdashboard/classes",
    },
    {
      icon: CgNotes,
      text: "Assignments",
      path: "/studentdashboard/assignment",
    },
    {
      icon: MdOutlineTableChart,
      text: "Project",
      path: "/studentdashboard/project",
    },
    {
      icon: GrSearch,
      text: "Explore",
      path: "/studentdashboard/explore",
    },
  ];

  return (
    <div className="hidden md:flex flex-col px-6 pb-6 text-base border-r-2 border-[#A7AEB91A]  bg-white max-w-[240px]">
      {sidebarItems.map((item) => (
        <Link
          key={item.path}
          to={item.path}
          className={`flex gap-4 p-2.5 mt-2 text-sm rounded-md border-none ${
            selectedButton === item.text
              ? "bg-[#127ABB] text-white"
              : "hover:bg-[#127ABB] hover:text-white"
          } ${activeButton === item.text ? "bg-sky-600 text-white" : ""}`}
          onClick={() => handleButtonClick(item.text)}
          onMouseEnter={() => handleButtonHover(item.text)}
          onMouseLeave={handleButtonLeave}>
          <item.icon
            size={24}
            className={`shrink-0 w-6 aspect-square ${
              hoveredButton === item.text ? "text-white" : "text-sky-600"
            }${activeButton === item.text ? "bg-sky-600 text-white" : ""}`}
          />
          {item.text}
        </Link>
      ))}
      <div className="mt-20">
        <button
          className={`flex gap-4 p-2.5  rounded-md border-none ${
            hoveredButton === "logout"
              ? "bg-[#127ABB] text-white"
              : "hover:bg-[#127ABB] hover:text-white"
          }`}
          onClick={handleLogout}
          onMouseEnter={() => handleButtonHover("logout")}
          onMouseLeave={handleButtonLeave}>
          <RiLogoutBoxLine
            size={24}
            className={`shrink-0 w-6 aspect-square ${
              hoveredButton === "logout" ? "text-white" : "text-sky-600"
            }`}
          />
          Logout
        </button>
      </div>
    </div>
  );
};

export default StudentSidebar;
