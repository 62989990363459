import React, { useState } from "react";
import { formatCreatedAt } from "../../../utils/convertDate";

const DeleteTestimonials = ({ testimonial, onDelete, closeModal }) => {
  return (
    <div className="flex flex-col px-10 pt-5 pb-14 bg-white rounded-lg max-w-[730px] max-md:px-5">
      <img
        onClick={closeModal}
        alt=""
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
        className="shrink-0 w-6 aspect-square"
      />

      {/* Testimonial Content */}
      <div className="flex flex-col md:flex-row pr-5 pl-10 md:items-center gap-4">
        {/* Image */}
        <img
          alt={`Testimonial by ${testimonial.name}`}
          loading="lazy"
          srcSet={`${JSON.parse(testimonial.image_url).filePath}`}
          className="shrink-0 aspect-square w-[70px]"
        />

        {/* Testimonial Text */}
        <div className="flex flex-col my-auto w-full md:w-auto">
          <h3 className="text-2xl font-medium text-teal-950">
            {testimonial.name}
          </h3>
          <p className="mt-2.5 text-lg text-neutral-500">{testimonial.title}</p>
        </div>
      </div>

      <blockquote className="mt-11 text-lg leading-7 text-zinc-800 max-md:mt-10">
        {testimonial.quote}
      </blockquote>

      {/* Date Uploaded */}
      <div className="flex gap-2.5 mt-9">
        <p className="text-base text-neutral-500">Date uploaded:</p>
        <p className="text-base justify-center text-zinc-900">{formatCreatedAt(testimonial.created_at)}</p>
      </div>

      {/* Delete Button */}
      <button
        type="button"
        className="flex items-center w-[50%] gap-2.5 justify-center px-5 py-2 mt-20 text-lg text-red-600 whitespace-nowrap rounded-md border border-red-600 border-solid max-md:mt-10">
        {/* Delete icon */}
        <img
          alt="Delete"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/cf10343be22ff1f68ae0e232c0c0ea27ed30b9df2a6924d5df75d21e27638380?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 self-start w-5 aspect-square"
        />
        <span onClick={onDelete}>Delete</span>
      </button>
    </div>
  );
};

export default DeleteTestimonials;
