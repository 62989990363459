import React from "react";
import { formatDate, formatTimeTo12Hour } from "../../../utils/convertDate";


const CardDetails = ({ cardData, closeCard }) => {
  const absoluteUrl = cardData.class_link.startsWith("https://")
    ? cardData.class_link
    : `https://${cardData.class_link}`;

  return (
    <div className="flex flex-col ml-5 text-center w-[420px] pt-1 pb-5 text-base bg-white rounded-lg shadow-lg max-w-[390px]">
      <img
        onClick={closeCard}
        alt="cancel"
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba6c22f8c08e7c8f5418cd883d10134298792f61ed2d00680fe1933af1989b54?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
        className="self-end w-6 aspect-square"
      />
      <div className="justify-center items-center self-stretch px-5 py-2 mt-6 w-full text-green-800 whitespace-nowrap bg-green-100">
        <p className="text-center">{cardData.status}</p>
      </div>
      <p className="mt-9 text-sm">title:</p>
      <div className="mt-1.5 font-medium text-zinc-800">
        {cardData.class_title}
      </div>
      <p className="mt-8 text-sm">Class link:</p>
      <a
        href={absoluteUrl}
        target="_blank"
        rel="noreferrer"
        className="mt-1.5 text-sky-600">
        {cardData.class_link}
      </a>
      <p className=" mt-8 text-sm">Duration:</p>
      <div className=" mt-1.5 text-zinc-800">
        {`${formatTimeTo12Hour(cardData.start_time)} -
        ${formatTimeTo12Hour(cardData.end_time)}`}
      </div>
      <p className="self-start mt-1.5 ml-8 text-zinc-800"></p>
      <p className="mt-8 text-sm">Description</p>
      <div className="mt-1.5 leading-6 text-zinc-800 p-4 text-center">
        {cardData.description}
        <p className="mt-8 text-sm">Class recording</p>
        {cardData.class_recordings && (
          <a
            href={cardData.class_recordings}
            target="_blank"
            rel="noreferrer"
            className="mt-1.5 text-sky-600 leading-[150%] w-[50%] break-words">
            {cardData.class_recordings}
           
          </a>
        )}
      </div>
    </div>
  );
};

export default CardDetails;
