import React from "react";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col items-center px-5 py-8 w-full bg-white rounded-md border border-dashed border-zinc-400 max-md:mt-6 min-h-[280px] flex-grow">
      <img
        loading="lazy"
        src={icon}
        alt={title}
        className="w-14 aspect-square"
      />
      <h3 className="mt-4 text-xl font-semibold text-teal-950">{title}</h3>
      <p className="text-center font-normal px-6 sm:px-2 md:px-6 text-sm sm:text-xs md:text-sm">
        {description}
      </p>
    </div>
  );
};

export default FeatureCard;
