import React, { useState } from "react";
import Calendar from "react-calendar";
import { MdCancel } from "react-icons/md";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";

const SetCohort = ({ closeModal }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date()); // 
  const [isLoading, setIsLoading] = useState(false);
  const [showStartDateCalendar, setShowStartDateCalendar] = useState(true);
  const [showEndDateCalendar, setShowEndDateCalendar] = useState(true)

  const handleStartDateChange = (date) => {
    setStartDate(date);
  
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
      
  };

  const handleSetCohort = async () => {
    setIsLoading(true);
    try {
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];

      const response = await axios.post(
        `${BACKEND_URL}/cohorts`,
        {
          start_date: startDateString,
          end_date: endDateString, 
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Cohort set successfully");
        closeModal();
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="w-full p-2  bg-white rounded-lg shadow-md">
      <div className="flex flex-row justify-between gap-10">
        <div className="w-full">
          <p className="text-lg font-bold">Start Date:</p>
          {showStartDateCalendar && ( // Conditionally render the calendar
            <Calendar onChange={handleStartDateChange} value={startDate} />
          )}
        </div>
        <div className="w-full">
          <p className="text-lg font-bold">End Date:</p>
          <Calendar onChange={handleEndDateChange} value={endDate} />
        </div>
      </div>

      <div className="flex flex-row  justify-between items-center mt-4">
        <p className="text-lg font-bold">
          Selected Dates: {startDate.toLocaleDateString()} -{" "}
          {endDate.toLocaleDateString()}
        </p>
        <MdCancel size={24} onClick={closeModal} />
      </div>
      <button
        className="bg-sky-600 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded mt-4"
        onClick={handleSetCohort}>
        {isLoading ? (
          <div className="inset-0 flex items-center justify-center">
            <Circles
              height="20"
              width="20"
              color="#fff"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          "Set cohort"
        )}
      </button>
    </div>
  );
};

export default SetCohort;
