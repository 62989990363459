// This example assumes that the backend returns success and failed urls after confirming payment
import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import PaymentService from "../../services/paymentServices";
import PaymentSuccess from "./PaymentSuccess";
import { Circles } from "react-loader-spinner";

const StripeCardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const transactionId = query.get("transactionId");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
   const [isError, setIsError] = useState(false);
   

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
        toast.info("Payment gateway is loading....");
        return;
    }

    setIsLoading(true);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `http://www.smartapproaches.online/stripe-payment-success`,
        },
      });

     
      if (error) {
        console.error("Stripe Error:", error);
        setErrorMessage(getStripeErrorMessage(error));
        setIsError(true); // Set error state to true
      } else {
        setErrorMessage(null);
        setIsError(false); // Reset error state
      }
   
    } catch (err) {
      console.error("Error:", err);
      setErrorMessage(
        "An error occurred during payment. Please try again later."
      );
      setIsError(true);
    } finally {
       setIsLoading(false);
    }
  };

  // Helper function to get a user-friendly message from Stripe errors
  const getStripeErrorMessage = (error) => {
    switch (error.type) {
      case "card_error":
        return error.message;
      case "validation_error":
        return "Please check your payment details and try again.";
      default:
        return "An unexpected error occurred. Please try again later."; // Generic message for other errors
    }
  };
 
  return (
    <div className="mt-16 max-md:mt-10">
        <div className="container mx-auto max-w-screen-md">
          <h2 className="text-2xl font-medium mb-4">Complete your payment</h2>

          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button
              className="px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
              type="submit"
              disabled={!stripe || isLoading}>
              {isLoading ? (
                <Circles
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Pay now"
              )}
            </button>
            {errorMessage && (
              <div className="text-red-500 mt-2">{errorMessage}</div>
            )}
          </form>
        </div>
    </div>
  );
};

export default StripeCardForm;
