import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import DocumentPreview from "../../DocumentPreview";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url";
import { toast } from "react-toastify";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";

const AddCourse = () => {
  useRedirectLoggedOutAdmin("/login")
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openSuccess, setOPenSuccess] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editableCourse, setEditableCourse] = useState([]);
  const [courseData, setCourseData] = useState({
    id: "",
    courseName: "",
    courseCode: "",
    courseDetails: "",
    duration: "",
    language: "",
    countrySymbol: "£",
    courseDocuments: null,
    generalPrice: "",
    startDate: "",
    firstInstallment: "",
    secondInstallment: "",
  });
  const [previewUrl, setPreviewUrl] = useState([]);
  const [trackFirstSelect, setTrackFirstSelect] = useState(false);
  const param = useParams();
  const courseName = param.courseName;

  const navigate = useNavigate();



  //fetch courses if there is a courseName in the route params
  useEffect(() => {
    const fetchCourses = async () => {
      // Set loading state to true
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/${courseName}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          const fetchedCourseData = response.data;
          setCourseData({
            ...courseData,
            ...fetchedCourseData,
            
          });
        } else {
          console.error(
            response.data?.error || "Failed to fetch courses. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching courses";
        console.error(errorMessage);
      } finally {
      }
    };
    if (courseName) {
      fetchCourses();
    }
  }, [courseName,]);

  

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);

    if (files.length > 0) {
      const newPreviews = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      const fileNames = Array.from(files).map((file) => file.name);
      setFileNames((prevNames) => [...prevNames, ...fileNames]);
      setPreviewUrl((prevPreviews) => [...prevPreviews, ...newPreviews]);
    }
    setTrackFirstSelect(true);
  };

  const paymentOptions = [
    { code: "AED", symbol: "د.إ" }, // United Arab Emirates Dirham
    { code: "AUD", symbol: "$" }, // Australian Dollar
    { code: "BRL", symbol: "R$" }, // Brazilian Real
    { code: "CAD", symbol: "$" }, // Canadian Dollar
    { code: "CHF", symbol: "CHF" }, // Swiss Franc
    { code: "CNY", symbol: "¥" }, // Chinese Yuan
    { code: "EUR", symbol: "€" }, // Euro
    { code: "GBP", symbol: "£" }, // British Pound Sterling
    { code: "HKD", symbol: "$" }, // Hong Kong Dollar
    { code: "INR", symbol: "₹" }, // Indian Rupee
    { code: "JPY", symbol: "¥" }, // Japanese Yen
    { code: "KRW", symbol: "₩" }, // South Korean Won
    { code: "MXN", symbol: "$" }, // Mexican Peso
    { code: "NZD", symbol: "$" }, // New Zealand Dollar
    { code: "RUB", symbol: "₽" }, // Russian Ruble
    { code: "SGD", symbol: "$" }, // Singapore Dollar
    { code: "USD", symbol: "$" }, // United States Dollar
    { code: "ZAR", symbol: "R" }, // South African Rand
  ];

  const languageOptions = ["English"];

 const handlePreview = (e) => {
  e.preventDefault()
   // Basic validation for required fields
   if (
     !courseData.courseName ||
     !courseData.courseDetails ||
     !courseData.language ||
     !courseData.generalPrice ||
     !courseData.firstInstallment || 
     !courseData.secondInstallment ||
     !courseData.duration ||
     !courseData.startDate || 
     !courseData.courseCode
   ) {
     return toast.error("All required fields are mandatory.");
   }
   navigate("/admindashboard/courses/add_course/overview", {
     state: {
       ...courseData,
       courseDocuments: selectedFiles,
       previewUrl: previewUrl,
       courseprop: param.courseName ||  null
     },
   });
  };

  const [errors, setErrors] = useState({
    courseName: "",
    courseDetails: "",
    duration: "",
    startDate: "",
    generalPrice: "",
    firstInstallment: "",
    secondInstallment: "",
  });

  const validateInput = (name, value) => {
    let error = "";

    switch (name) {
      case "courseName":
        if (!value) {
          error = "Course name is required";
        } else if (typeof value !== "string" || !/^[a-zA-Z\s]+$/.test(value)) {
          error =
            "Course name must be a string containing only letters and spaces";
        }
        break;
      case "courseDetails":
        if (!value) {
          error = "Course details are required";
        } else if (typeof value !== "string") {
          error = "Course details must be a string";
        }
        break;
      case "duration":
        if (!value) {
          error = "Duration is required";
        // } else if (!/^\d+$/.test(value)) {
        //   error = "Duration must be a number";
         }
        break;
      case "startDate":
        if (!value) {
          error = "Start date is required";
        } else if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
          error = "Invalid date format (YYYY-MM-DD)";
        } else if (new Date(value) <= new Date()) {
          error = "Start date must be a future date";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    validateInput(name, value);
    if (name === "countrySymbol") {
      return;
    }
    setCourseData({
      ...courseData,
      [name]: value,
    });
  };
 
  return (
    <form className="px-8 py-0 mt-10 md:py-12 font-roboto  bg-white rounded-lg shadow-lg max-md:px-5">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
            <h2 className="text-2xl font-medium text-teal-950 max-md:max-w-full">
              {courseName ? "Edit course" : " Add new course"}
            </h2>

            <label className="mt-9 text-lg text-teal-950 max-md:mr-2.5 max-md:max-w-full">
              Course name
            </label>
            <input
              placeholder="Name of course"
              name="courseName"
              value={courseData.courseName}
              onChange={handleInputChange}
              className="justify-center items-start px-5 py-3 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:mr-2.5 max-md:max-w-full"
            />
            {errors.courseName && (
              <p className="text-red-500 text-sm">{errors.courseName}</p>
            )}
            <label className="mt-6 text-lg text-teal-950 max-md:mr-2.5 max-md:max-w-full">
              Start date
            </label>
            <input
              placeholder="provide details about the course"
              name="startDate"
              type="date"
              value={courseData.startDate}
              onChange={handleInputChange}
              className="justify-center items-start px-5 pt-4 py-3 pb-7 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:mr-2.5 max-md:max-w-full"
            />
            {errors.startDate && (
              <p className="text-red-500 text-sm">{errors.startDate}</p>
            )}

            <label className="mt-6 text-lg text-teal-950 max-md:mr-2.5 max-md:max-w-full">
              Course details
            </label>
            <textarea
              placeholder="provide details about the course"
              name="courseDetails"
              value={courseData.courseDetails}
              onChange={handleInputChange}
              className="justify-center items-start px-5 pt-4 py-3 pb-7 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:mr-2.5 max-md:max-w-full"
            />
            {errors.courseDetails && (
              <p className="text-red-500 text-sm">{errors.courseDetails}</p>
            )}
            <div className="flex gap-5 mt-6 max-md:flex-wrap max-md:mr-2.5">
              <div className="flex flex-col">
                <label className="text-lg text-teal-950">Duration</label>
                <input
                  placeholder="How long the course is for"
                  name="duration"
                  value={courseData.duration}
                  onChange={handleInputChange}
                  className="justify-center items-start px-5 py-3 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500"
                />
                {errors.duration && (
                  <p className="text-red-500 text-sm">{errors.duration}</p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-lg text-teal-950">Language</label>
                <div className="flex gap-2.5 justify-between px-5 py-3 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500">
                  <select
                    name="language"
                    value={courseData.language}
                    onChange={handleInputChange}
                    className="my-auto w-full h-full outline-none border-none">
                    <option>{"Select language"}</option>
                    {languageOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <p className="mt-5">Course documents</p>
            <label
              htmlFor="courseDocuments"
              className="flex justify-center items-center px-8 py-7 mt-2.5 text-base bg-white rounded-lg border border-dashed border-zinc-400 text-zinc-800 max-md:px-5 max-md:mr-2.5 max-md:max-w-full cursor-pointer">
              {" "}
              {previewUrl?.length > 0 &&
                previewUrl?.map((url, index) => (
                  <div className="document-preview" key={index}>
                    {/* Conditional rendering (PDF vs. image) */}
                    {selectedFiles[index]?.type === "application/pdf" ? (
                      <div className="flex flex-col">
                        <DocumentPreview
                          fileUrl={url}
                          fileType={selectedFiles[index]?.type}
                        />
                        <div>{selectedFiles[index]?.name}</div>
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <img
                          src={url}
                          className="w-[80%] h-[100px]"
                          alt="Course document preview"
                        />
                        <div>{selectedFiles[index]?.name}</div>
                      </div>
                    )}
                  </div>
                ))}
              {/* Label for accessibility */}
              <input
                type="file"
                id="courseDocuments"
                name="courseDocuments"
                onChange={handleFileChange}
                multiple
                className="hidden" // Hide the default file input
              />
              <div className="flex flex-col items-center max-w-full w-[187px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/29a8e6a9b607cfac96aeb0891172301ef0be35778147e2d8ea5bd1b5183d5ac3?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  alt="Upload Icon"
                  className="self-center w-8 aspect-square"
                />
                <span className="mt-2 text-center">
                  {trackFirstSelect ? "Add more" : "Upload course documents"}
                </span>
              </div>
            </label>
          </div>

          <button
            onClick={handlePreview}
            className="hidden md:flex hover:bg-sky-800 mt-2 justify-center text-center items-center px-5 py-3  text-lg font-medium text-white bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full">
            {isLoading ? (
              <div className="inset-0 flex items-center justify-center">
                <Circles
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              "Preview course"
            )}
          </button>
        </div>
        <div className="flex flex-col ml-0 w-[34%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col mt-14 text-base text-teal-950 max-md:mt-10">
            <label className="text-lg">Course code</label>
            <input
              id="courseCode"
              name="courseCode"
              value={courseData.courseCode}
              onChange={handleInputChange}
              placeholder="Type the course code"
              className="justify-center items-start px-5 py-3 mt-6 rounded-md border border-solid border-zinc-400 text-neutral-500"
            />

            <h2 className="mt-14 text-lg max-md:mt-10">Set course price</h2>
            <div className="flex flex-col px-5 py-7 mt-2.5 w-full bg-white rounded-md border border-solid border-zinc-400 text-neutral-500">
              <p className="text-teal-950">General price</p>
              <label className="mt-5 text-zinc-800">One-time payment</label>
              <div className="flex gap-2.5 mt-3 whitespace-nowrap">
                <select
                  name="countrySymbol"
                  id="countrySymbol"
                  value={courseData.countrySymbol}
                  onChange={handleInputChange}
                  className="my-auto px-2 h-full bg-sky-100 border-none rounded-md ">
                  <option className="bg-white">{"GBP"}</option>
                  {paymentOptions.map((option, index) => (
                    <option
                      className="bg-white"
                      key={index}
                      value={option.symbol}>
                      {option.code}
                    </option>
                  ))}
                </select>

                <input
                  name="generalPrice"
                  value={courseData.generalPrice && parseFloat(
                    courseData.generalPrice?.replace(/[£,]/g, "")
                  )}
                  onChange={handleInputChange}
                  placeholder="Amount"
                  className=" flex flex-col justify-center items-start px-2.5 py-1.5 w-full rounded-md border border-solid border-zinc-400 max-md:pr-5"
                />
              
              </div>
              <p className="mt-6 text-zinc-800">Installment payment</p>
              <label className="mt-3.5 text-sm">First installment</label>
              <div className="flex gap-2.5 mt-2 whitespace-nowrap">
                <div className="flex gap-2.5 bg-sky-100 rounded-md">
                  <select
                    name="countrySymbol"
                    value={courseData.countrySymbol}
                    onChange={handleInputChange}
                    className=" px-2 h-full bg-sky-100 border-none rounded-md ">
                    <option className="bg-white">{"GBP"}</option>
                    {paymentOptions.map((option, index) => (
                      <option
                        className="bg-white"
                        key={index}
                        value={option.symbol}>
                        {option.code}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  name="firstInstallment"
                  value={courseData.firstInstallment && parseFloat(
                    courseData.firstInstallment?.replace(/[£,]/g, "")
                  )}
                  onChange={handleInputChange}
                  placeholder="Amount"
                  className="justify-center w-full items-start px-2.5 py-1.5 rounded-md border border-solid border-zinc-400 max-md:pr-5"
                />
               
              </div>
              <label className="mt-5 text-sm">Second installment</label>
              <div className="flex gap-2.5 mt-2 whitespace-nowrap">
                <div className="flex gap-2.5 bg-sky-100 rounded-md">
                  <select
                    name="countrySymbol"
                    value={courseData.countrySymbol}
                    onChange={handleInputChange}
                    className="px-2 h-full bg-sky-100 border-none rounded-md ">
                    <option className="bg-white">{"GBP"}</option>
                    {paymentOptions.map((option, index) => (
                      <option
                        className="bg-white"
                        key={index}
                        value={option.symbol}>
                        {option.code}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  name="secondInstallment"
                  value={courseData.secondInstallment && parseFloat(
                    courseData.secondInstallment?.replace(/[£,]/g, "")
                  )}
                  onChange={handleInputChange}
                  placeholder="Amount"
                  className="justify-center w-full items-start px-2.5 py-1.5 rounded-md border border-solid border-zinc-400 max-md:pr-5"
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={handlePreview}
        className="md:hidden justify-center text-center md:w-[60%] w-full items-center px-5 py-3 mt-[-100px] text-lg font-medium text-white bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full">
        {isLoading ? (
          <div className="inset-0 flex items-center justify-center">
            <Circles
              height="20"
              width="20"
              color="#fff"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          "  Preview course"
        )}
      </button>
    </form>
  );
};

export default AddCourse;
