import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomModal from "../../StudentsDashboard/Modal";
import AddVideo from "./Addvideo";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";


const LearningPlatform = () => {
  useRedirectLoggedOutAdmin("/login");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const courses = [
    {
      name: "Business analysis",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/4506d05312c7603186c8ff5d22506934ed5b28ad1f092f5925732d1c6998012b?apiKey=c4f2754934754c86a2ca35b496fd31f4&",
    },
    {
      name: "Data analysis",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/f70b6ce8dca7c633df3539c33989d0518be8280d73b4b94075e784c2d1cdf366?apiKey=c4f2754934754c86a2ca35b496fd31f4&",
    },
  ]; // You can add more courses here

  return (
    <>
      <div className="flex flex-col mt-10 w-full py-4 bg-neutral-50 min-h-[400px]">
        {/* Main Content Container */}
        <div className="max-w-screen-xl">
          {/* Title and Add Button */}
          <div className="flex flex-row bg-white w-full p-2 rounded-md md:flex-row justify-between items-center mb-8">
            <h3 className="text-lg md:text-xl font-medium text-teal-950">
              Learning Platform
            </h3>
            <button
              onClick={() => setIsModalOpen(true)}
              className="px-4 py-2 text-base text-white bg-sky-600 rounded-md mt-2 md:mt-0">
              Add video
            </button>
          </div>

          {/* Courses Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {courses.map((course) => (
              <Link
                to={`/admindashboard/learn/videos/${course.name}`}
                key={course.name}
                className="bg-white rounded-lg shadow-lg p-5 md:p-6 flex flex-col justify-center items-center w-full text-lg font-medium text-zinc-800">
                <img
                  src={course.imageUrl}
                  alt={`${course.name} course`}
                  className="self-center w-8 aspect-square mb-4"
                />
                <div className="mt-4 text-center">{course.name}</div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <CustomModal isOpen={isModalOpen}>
        <AddVideo closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </>
  );
};

export default LearningPlatform;
