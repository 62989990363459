import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PaymentService from "../../services/paymentServices";
import { toast } from "react-toastify";
import PaymentFailed from "./PaymentFailed";
import { sendEmail } from "../../services/authServices";
import LoadingModal from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  selectOrderId,
  selectTransactionId,
  CLEAR_PAYMENT_DATA,
} from "../../redux/auth/authSlice";

const StripePaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [paymentStatus, setPaymentStatus] = useState(null); // Initial status
  const orderId = useSelector(selectOrderId);
  const transactionId = useSelector(selectTransactionId);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [paymentMode, setPaymentMode] = useState(null);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await PaymentService.getStripePaymentIntentStatus(
          transactionId
        );
        if (response.status >= 200 && response.status < 300) {
          setPaymentStatus(response.data.status);
          setPaymentMode(response.data.paymentMode);
        }
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };
    if (transactionId) {
      fetchPaymentStatus();
    }
  }, []);

  useEffect(() => {
    if (paymentStatus === "succeeded") {
      if (paymentMode === "second_installment") {
        // Redirect to the student dashboard
        navigate("/second-payment-success");
      } else {
        // Send email
        const sendMail = async () => {
          setRegistrationComplete(false);
          try {
            const emailResponse = await sendEmail();

            if (emailResponse.status >= 200 && emailResponse.status < 300) {
              setRegistrationComplete(true);
              dispatch(CLEAR_PAYMENT_DATA());
            }
          } catch (error) {
            console.error("Error sending email: ", error);
          } finally {
            setRegistrationComplete(false);
          }
        };
        sendMail();
      }
    }
  }, [paymentStatus, paymentMode, dispatch, navigate]);

  if (paymentStatus !== null && paymentStatus !== "succeeded") {
    return <PaymentFailed />;
  }

  if (registrationComplete) {
    return <LoadingModal open={registrationComplete} />;
  }

  return (
    <>
      {paymentStatus != null && paymentStatus === "succeeded" && (
        <div className="bg-white py-4 px-4 md:px-0 font-roboto mx-auto">
          <div className="flex gap-2 max-md:flex-col max-md:gap-0">
            <div className="hidden md:flex flex-col w-[25%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow px-10 pt-2 pb-20 w-full text-4xl font-semibold text-center text-white bg-sky-700 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <img
                  alt=""
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="max-w-full aspect-[1.79] w-[100px]"
                />
                <img
                  alt=""
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ec0609164cc54657bef913987d327ea48ee71565813dbf11e60d8dd1bd4763bb?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="self-end mt-14 max-w-full aspect-square w-[450px] max-md:mt-10"
                />
                <div className="self-center text-base px-2 mt-4 w-[390px]">
                  Your journey to success starts here!
                </div>
              </div>
            </div>

            <div className="flex flex-col py-4 px-4 w-[60%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col max-md:mt-10 max-md:max-w-full">
                <div className="flex flex-col items-center max-md:pl-5 max-md:max-w-full">
                  <div className="max-w-full h-[100px] self-end">
                    {/* icon */}
                    <div className="flex w-[100px] h-[100px] max-md:ml-0 max-md:w-full">
                      <img
                        alt=""
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/af14ce3cbe59690ddc6b15e19c6a354ca71fba49f9120cecba5aaf7e4998d598?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                        className="w-full h-full self-end aspect-[1.22] max-md:mt-10"
                      />
                    </div>
                  </div>
                  {/* success check */}
                  <div className="flex self-center items-center flex-col w-[29%] max-md:ml-0 max-md:w-full">
                    <img
                      alt=""
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/f025cb678355c8c04a4031673254b5a4ec4edd0f02d4b7ad0439375f921553d3?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                      className="shrink-0  max-w-full aspect-square w-[120px] max-md:mt-10"
                    />
                  </div>
                  <p className="mt-11 text-3xl font-medium text-teal-950 max-md:mt-10 max-md:ml-2.5">
                    Payment Successful!
                  </p>
                  <p className="mt-5 text-xl text-center text-zinc-800 max-md:ml-2.5">
                    We have sent your login details to your email
                  </p>
                  <p className="self-stretch mt-3.5 text-lg text-center text-neutral-500 max-md:max-w-full">
                    Kindly check and use the information to log in to your
                    dashboard
                  </p>
                  <p className="mt-16 text-base hover:underline text-center text-sky-600 max-md:mt-10 max-md:ml-2.5">
                    Change email address
                  </p>
                </div>
                <button
                  onClick={() => navigate("/login")}
                  className="justify-center hover:bg-sky-800 text-center items-center px-5 py-2 mt-4 text-lg font-medium text-white bg-sky-600 rounded-md max-md:max-w-full">
                  Proceed to Login
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StripePaymentSuccess;
