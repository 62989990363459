import React, { useState, useEffect } from "react";
import ProjectCards from "./ProjectCards";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import {
  SET_PROJECT_PROGRESS,
  selectProject,
  SET_SUBMISSION,
} from "../../../redux/project/projectSlice";
import { fetchProjectProgress } from "../../../services/projectService";
import { formatCreatedAt } from "../../../utils/convertDate";

const ProjectSubmissions = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const project = useSelector(selectProject)
  const params = useParams();
  const [projectProgress, setProjectProgress] = useState([]);
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState(null);
  const width = project?.width

  const handleSetProgress = (progressId) => {
    const progress = projectProgress.find((a) => a.id === progressId);
    dispatch(SET_PROJECT_PROGRESS(progress));
  };

  // fetch project
  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/project/${params.id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) { 
          setProjectId(response.data.id);
        } else {
          console.error.error(
            response.data?.error || "Failed to fetch project. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching project";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProject();
  }, []);

  // fetch project progress list
  const setSubmission = () => {};
  useEffect(() => {
    const fetchprojectProgress = async () => {
      setIsLoading(true);
      try {
        const response = await fetchProjectProgress(projectId);

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setProjectProgress(response.data);
          //dispatch(SET_PROJECT_PROGRESS(response.data))
        } else {
          console.error(
            response.data?.error ||
              "Failed to fetch project progress. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching project progress";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    if (projectId) {
      fetchprojectProgress();
    }
  }, [projectId]);

  return (
    <div className="flex flex-col px-12 py-16 bg-neutral-50 max-md:px-5">
      <div className="text-2xl font-medium text-teal-950 max-md:max-w-full">
        Project submissions
      </div>
      <div className="self-end mt-7 text-sm text-neutral-500">
    { `${project?.submittedCount}/${project?.totalCount} submitted`}
      </div>
      <div className="flex flex-col justify-center mt-1.5 bg-gray-200 max-md:max-w-full">
        <div className="flex flex-col justify-center items-start bg-gray-200 rounded-md max-md:pr-5 max-md:max-w-full">
          <div className="shrink-0 max-w-full bg-sky-600 rounded-md h-[7px] " style={{width}} />
        </div>
      </div>
      <div className="flex flex-col justify-center mt-11 text-lg bg-white rounded-lg shadow-lg text-zinc-800 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 justify-between px-8 py-5 text-xl font-medium bg-white rounded-lg text-teal-950 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="my-auto">Project progress</div>
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6da1b3c3183204f050c3129999ff2e5a2767e162fcafa04c988794d50de59c27?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 w-7 aspect-square"
          />
        </div>

        <table className="w-full table-auto">
          <thead>
            <tr>
              {/* Table headers */}
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Student
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Assignment status
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date of submission
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Grade /100
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Comment
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {/* First Row */}
            {projectProgress &&
              projectProgress.map((progress) => (
                <tr key={progress.id}>
                  <td className="px-6 py-4 text-base whitespace-nowrap font-medium">
                    {progress.studentName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div
                      className={`justify-center self-stretch px-5 py-2 text-base whitespace-nowrap rounded-md ${
                        progress.status === "submitted"
                          ? "bg-sky-100 text-sky-800"
                          : " bg-green-100 text-green-800"
                      }`}>
                      {progress.status}
                    </div>
                  </td>
                  <td className="px-4 py-4 text-base whitespace-nowrap">
                    {formatCreatedAt(progress.created_at)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    {progress.grade ? progress.grade : "-"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap flex gap-1.5 justify-center self-stretch my-auto text-base text-center whitespace-nowrap text-neutral-500">
                    <img
                      alt=""
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bd402668c3e692b3f2e5f4a860866ac52b0ba8c681ba91f1ed9561c1206877d8?"
                      className="shrink-0 w-5 aspect-square"
                    />
                    <div>{progress.studentComment ? 1 : ""}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link
                      onClick={() => handleSetProgress(progress.id)}
                      to={`/teachersdashboard/project/submissions/${
                        progress.status === "submitted" ? "review" : "view"
                      }`}
                      className="self-stretch my-auto text-sky-600">
                      {progress.status === "submitted" ? "Review" : "View"}
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectSubmissions;
