import React, { useState } from 'react'
import CustomModal from '../../StudentsDashboard/Modal';
import Addvideo from "../learning platform/Addvideo"

const Upload = () => {
  const [isModalOpen,setIsModalOpen] = useState(false)
  return (
    <div onClick={() =>setIsModalOpen(true)} className="flex gap-5 justify-between px-8 py-4 mt-5 text-lg leading-6 bg-white rounded-lg shadow-lg text-zinc-800 max-md:px-5">
      <img
        alt=""
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c6187778c99cdbfa280def1ee8d31016ce460e567b45e41e1ed42313baaf57f?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
        className="shrink-0 my-auto w-10 aspect-square"
      />
      <div>Upload video on learning platform</div>
      <CustomModal isOpen={isModalOpen}>
        <Addvideo closeModal={() => setIsModalOpen(false)}/>
      </CustomModal>
    </div>
  );
}

export default Upload;