import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-white py-5 md:py-2 px-4 md:px-0 font-roboto mx-auto">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="hidden md:flex flex-col w-[25%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-10 pt-2 pb-20 w-full text-4xl font-semibold text-center text-white bg-sky-700 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <img
              alt=""
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="max-w-full aspect-[1.79] w-[100px]"
            />
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ec0609164cc54657bef913987d327ea48ee71565813dbf11e60d8dd1bd4763bb?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="self-end mt-14 max-w-full aspect-square w-[450px] max-md:mt-10"
            />
            <div className="self-center text-base px-2 mt-4 w-[390px]">
              Your journey to success starts here!
            </div>
          </div>
        </div>

        <div className="flex flex-col ml-5 w-[60%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col px-5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col items-center max-md:pl-5 max-md:max-w-full">
              <div className="max-w-full h-[100px] self-end">
                {/* icon */}
                <div className="flex w-[100px] h-[100px] max-md:ml-0 max-md:w-full">
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/af14ce3cbe59690ddc6b15e19c6a354ca71fba49f9120cecba5aaf7e4998d598?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                    className="w-full h-full self-end aspect-[1.22] max-md:mt-10"
                  />
                </div>
              </div>
              {/* success check */}
              <div className="flex self-center items-center flex-col w-[29%] max-md:ml-0 max-md:w-full">
                <img
                  alt="failed"
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/93c288e4b05dcc77faf5a38bb7df9aaf3d532ab795edf8dce7e4f40fa99527fa?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="self-center max-w-full aspect-square w-[120px]"
                />
              </div>
              <p className="mt-11 text-3xl font-medium text-teal-950 max-md:mt-10 max-md:ml-2.5">
                Payment Failed!
              </p>
              <p className="mt-4 text-lg text-center text-neutral-500 w-[454px] max-md:max-w-full">
                Oops, something went wrong and your payment didn’t go through.
                Kindly try again
              </p>
            </div>

            <div onClick={()=>navigate("/register/make-payment")} className="justify-center hover:bg-sky-800 text-center items-center px-5 py-2 mt-10 text-lg font-medium text-white bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full">
              Try again
            </div>
            <div onClick={() =>navigate("/")} className="justify-center  hover:bg-neutral-300 text-center items-center px-5 py-2 mt-5 text-lg text-sky-600 rounded-md border border-sky-600 border-solid max-md:max-w-full">
              Back to Home
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed;