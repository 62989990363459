import React from "react";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import Footer from "../../components/Footer"

const ContactPage = () => {
  return (
    <div className="flex flex-col bg-white">
      <section className="flex relative flex-col px-16 pt-44 pb-36 w-full text-6xl font-semibold text-white min-h-[411px] max-md:px-5 max-md:py-10 max-md:max-w-full max-md:text-4xl">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e5f275a41a96d6b63a2d662911ad796f16696179e3c4793ded6ee08ade001482?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
          alt="Contact Us background"
          className="object-cover absolute inset-0 size-full"
        />
        <h1>Contact Us</h1>
      </section>
      <main className="flex px-1.5 md:px-8 flex-col self-center  mt-20 w-full max-w-[1200px] max-md:mt-10 max-md:max-w-full">
        <h2 className="text-2xl font-semibold text-teal-950 max-md:max-w-full">
          Send Us A Message
        </h2>
        <div className="mt-4 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <ContactForm />
            <div className="flex flex-col ml-5 w-[45%] max-md:ml-0 max-md:w-full">
              <ContactInfo />
            </div>
          </div>
        </div>
      </main>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca2d98bfd017710f82ce10fcf874c19d3e2ae0672023f6cf4937fc3ed610dfa6?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
        alt="Decorative"
        className="mt-20 w-full aspect-[2.56] max-md:mt-10 max-md:max-w-full"
      />
      <Footer />
    </div>
  );
};

export default ContactPage;
