import React,{useState, createContext, useContext} from 'react';
import StudentNav from './StudentNav.js';
import StudentSideBar from './StudentSideBar.js';
import StudentFooter from './StudentFooter.js';
import SmallNav from "./SmallNav.js"

const ActiveComponentContext = createContext(null);
  const StudentLayout = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState("Dashboard");

  const handleSetActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <ActiveComponentContext.Provider value={{ activeComponent, setActiveComponent }}>
    <div className="min-h-screen flex flex-col">
        {" "}
        {/* Full-height layout */}
        <StudentNav />
        <SmallNav />
        <main className="flex-grow flex bg-[#FAFAFA]">
          {" "}
          {/* Main content area takes up remaining space */}
          <div className="hidden md:block border-r border-[#A7AEB91A]">
            {" "}
            {/* Hide sidebar on small screens */}
            <StudentSideBar/>
          </div>
          <div className="w-full px-0 mb-20 py-6 lg:px-10">
            {" "}
            {/* Content padding */}
            {children}
          </div>
        </main>
        <StudentFooter/>
      </div>
  </ActiveComponentContext.Provider>
  );
}

// 2. Custom Hook for Easy Access to Context
function useActiveComponent() {
  const context = useContext(ActiveComponentContext);
  if (!context) {
    throw new Error("useActiveComponent must be used within an AdminLayout");
  }
  return context;
}

// 3. Example of how you'd use the hook in a child component
function ChildComponent() {
  const { activeComponent } = useActiveComponent();
  // ... use activeComponent for conditional rendering or logic ...
}


export default StudentLayout;



