export function convertDate(dateStr) {
    try {
        // Parse the input date string
        const [year, month, day] = dateStr.split("-").map(Number);
        const dateObj = new Date(year, month - 1, day); // Adjust for 2000-based year
        // Format the date as "day month year"
        const formattedDate = dateObj.toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
        return formattedDate;
    } catch (error) {
        return "Invalid date format. Please provide a valid date in the format 'yy-mm-dd'.";
    }
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getUTCDate(); // Get day of the month (UTC)
  const month = date.toLocaleString("default", { month: "long" }); // Get full month name
  const year = date.getUTCFullYear(); // Get the full year (UTC)

  return `${day} ${month} ${year}`;
}



export function formatCreatedAt(createdAt) {
  const date = new Date(createdAt); // Creates a Date object from the input
  const options = {
    day: "numeric",
    month: "long", // Full month name
    year: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
}

export function formatTimeTo12Hour(timeString) {
  const [hours, minutes] = timeString?.split(":");
  let period = "AM";

  let hoursNum = parseInt(hours, 10);

  // Automatically determine AM/PM
  if (hoursNum >= 12) {
    period = "PM";
    if (hoursNum > 12) {
      hoursNum -= 12; // Adjust for 12-hour format
    }
  }

  return `${hoursNum}:${minutes} ${period}`;
}


