import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GridToolbar } from "@mui/x-data-grid";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const StudentsDetails = () => {
  const [studentDetails, setStudentDetailsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalStudents, setTotalStudents] = useState(0);

  useEffect(() => {
    const fetchStudentsData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/users/get-students-details`,
          {
            params: {
              page: currentPage,
              perPage: pageSize,
            },
            withCredentials: true,
          }
        );

        // Log the entire response

        if (response.status >= 200 && response.status < 300) {
          const dataWithIds =
            response.data && // Conditional Check
            response.data.map((item) => ({
              ...item,
              id: item.id || uuidv4(),
            }));

          // Log the processed data with UUIDs

          setStudentDetailsData(dataWithIds);
          setTotalStudents(response.data.total); // Assuming backend sends total number of students
        } else {
          throw new Error(
            response.data.error || "Failed to fetch student details"
          );
        }
      } catch (error) {
        console.error("Error fetching student details:", error);
      }
    };

    fetchStudentsData();
  }, [currentPage, pageSize]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage + 1); // DataGrid uses zero-based index, so increment by 1
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to the first page
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 30,
    },
    {
      field: "cohort",
      headerName: "Cohort",
      width: 100,
      flex: 1,
    },
    {
      field: "first_name",
      headerName: "Name",
      width: 150,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
      flex: 1,
    },
    {
      field: "course",
      headerName: "Course",
      width: 150,
      flex: 1,
    },
    {
      field: "paymentOption",
      headerName: "Payment Option",
      width: 150,
      flex: 1,
    },
    {
      field: "enrollmentDate",
      headerName: "Enrollment Date",
      width: 150,
      flex: 1,
    },
    {
      field: "secondPaymentDue",
      headerName: "2nd Payment Due",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex ml-4 my-auto">
            {params.row.secondPaymentDue || "N/A"}
          </div>
        );
      },
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className={`px-2 py-1 rounded-md ${
              params.row.paymentStatus === "succeeded" || "completed"
                ? "bg-green-200 text-green-800"
                : "bg-red-200 text-red-800"
            }`}>
            {params.row.paymentStatus || "N/A"}
          </span>
        );
      },
    },
    {
      field: "secondPaymentDate",
      headerName: "2nd Payment Date",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex ml-2 my-auto">
            {params.row.secondPaymentDate || "N/A"}
          </div>
        );
      },
    },
  ];

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    // Define columns to always show on mobile
    const mobileVisibleColumns = ["name", "course", "paymentStatus"];

    const newColumns = columns.map((col) => ({
      ...col,
      hide: isMobile && !mobileVisibleColumns.includes(col.field), // Hide columns not in mobileVisibleColumns on mobile
    }));
    setVisibleColumns(newColumns);
  }, [isMobile]);

  useEffect(() => {
    // Adjust column widths based on screen size
    if (isSmallScreen) {
      columns.forEach((column) => {
        if (column.field !== "name") {
          column.width = 100; // Narrower columns on small screens
        }
      });
    } else {
      columns.forEach((column) => {
        if (column.field !== "name") {
          column.width = 150; // Reset to default width on larger screens
        }
      });
    }
  }, [isSmallScreen]);

  return (
    <div className="flex flex-col pt-4 pb-6 mt-10 bg-white rounded-lg shadow-lg max-md:max-w-full">
      {/* Header Section */}
      <div className="flex gap-5 mb-2 justify-between self-center w-full font-medium max-w-[1080px] px-3 max-md:flex-wrap max-md:max-w-full">
        <div className="flex gap-4">
          <p className="my-auto text-xl text-teal-950">Students</p>
          <div className="flex gap-2.5 justify-between py-1.5 pr-2.5 pl-5 text-base text-white bg-sky-600 rounded-md">
            <select className="w-full h-full bg-inherit outline-none border-none">
              <option>All students</option>
            </select>
          </div>
        </div>
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ce90e0cf073eb1702a77a5e0288f2c108bc45cdb6363121a08d03d0688dfe01?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 my-auto w-7 aspect-square"
        />
      </div>

      {/* DataGrid */}
      <DataGrid
        rows={studentDetails}
        columns={visibleColumns}
        rowCount={totalStudents} // Total number of rows from the server
        pageSize={pageSize}
        pagination
        paginationMode="server"
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        disableColumnMenu
        disableSelectionOnClick
        autoPageSize
        columnHeaderHeight={100}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#f0f0f5",
            padding: "10px",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              color: "#333",
            },
          },
        }}
        slots={{ toolbar: GridToolbar }} // Add built-in toolbar
        initialState={{
          pagination: {
            paginationModel: { pageSize: isSmallScreen ? 5 : 10 },
          },
        }}
      />
    </div>
  );
};

export default StudentsDetails;
