import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assignments: [],
  assignment: null,
  createdAssignment: [],
  assignmentProgress:[]
};

const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    SET_ASSIGNMENTS(state, action) {
      const assignmentPayload = action.payload;
      // const assignmentsWithStatus = assignmentPayload?.map((assignment) => ({
      //   ...assignment,
      //   status: "Not submitted",
      // }));
      state.assignments = assignmentPayload;
    },
    SET_ASSIGNMENT(state, action) {
      const assignmentId = action.payload;
      const assignment = state.assignments.find((a) => a.id === assignmentId);
      state.assignment = assignment || null; // Set to null if not found
    },
    UPDATE_ASSIGNMENT_STATUS(state, action) {
      const assignmentId = action.payload;

      // Update the status of the assignment in the assignments array
      const updatedAssignments = state.assignments?.map((assignment) =>
        assignment.id === assignmentId
          ? { ...assignment, status: "Submitted" }
          : assignment
      );
      state.assignments = updatedAssignments;
      // Update the status of the currently selected assignment
      if (state.assignment && state.assignment.id === assignmentId) {
        state.assignment.status = "Submitted";
      }
    },

    SET_CREATE_ASSIGNMENT(state, action) {
      state.createdAssignment = action.payload;
    },
    CLEAR_CREATED_ASSIGNMENT(state, action) {
      state.createdAssignment = []
    },
    SET_PROGRESS(state, action) {
      state.assignmentProgress = action.payload
    },
  },
});

export const {
  SET_ASSIGNMENTS,
  SET_ASSIGNMENT,
  UPDATE_ASSIGNMENT_STATUS,
  SET_CREATE_ASSIGNMENT,
  CLEAR_CREATED_ASSIGNMENT,
  SET_PROGRESS
} = assignmentSlice.actions;

export const selectAssignments = (state) => state.assignment.assignments;
export const selectAssignment = (state) => state.assignment.assignment
export const selectCreatedAssignment = (state) => state.assignment.createdAssignment
export const selectProgress = (state) =>state.assignment.assignmentProgress


export default assignmentSlice.reducer;
