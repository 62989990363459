import React, { useState } from "react";
import { BACKEND_URL } from "../../services/url";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

function UploadProfilePicture() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("profilePicture", selectedImage);

    setIsLoading(true);

    try {
      const response = await axios.put(
        `${BACKEND_URL}/users/upload-profile-picture`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setPreviewUrl(null);
        const pathname = location.pathname;
        if (pathname.includes("studentdashboard")) {
          navigate("/studentdashboard");
        } else if (pathname.includes("teachersdashboard")) {
          navigate("/teachersdashboard");
        } else {
          navigate("/admindashboard");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col items-center mt-8  font-roboto space-y-4">
      <h2 className="text-2xl font-semibold">Upload Profile Picture</h2>

      {/* Image Preview */}
      {previewUrl && (
        <div className="mt-4 w-48 h-48 rounded-full overflow-hidden">
          <img
            src={previewUrl}
            alt="Profile Preview"
            className="w-full h-full object-cover"
          />
        </div>
      )}

      {/* File Input */}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="text-blue-600
          file:mr-5 file:py-2 file:px-6
          file:rounded-full file:border-0
          file:text-sm file:font-medium
          file:bg-blue-50 file:text-blue-700
          hover:file:cursor-pointer hover:file:bg-blue-100
        "
      />
      {/* Upload Button */}
      <button
        onClick={handleUpload}
        disabled={!selectedImage}
        className="px-4 py-2 mt-2 bg-blue-500 hover:bg-blue-700 text-white rounded disabled:opacity-50">
        {isLoading ? (
          <div className="inset-0 flex items-center justify-center">
            <Circles
              height="20"
              width="20"
              color="#fff"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          "upload"
        )}
      </button>
    </div>
  );
}

export default UploadProfilePicture;
