import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { selectprojects } from "../project/projectSlice";
import { selectClasses } from "../class/classSlice";
import { selectAssignments } from "../assignment/assignmentSlice";
import { useLocation } from "react-router-dom";
const initialState = {
  searchQuery: "",
  searchResults: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setSearchResults(state, action) {
      state.searchResults = action.payload;
    },
  },
});

export const { setSearchQuery, setSearchResults } = filterSlice.actions;

export default filterSlice.reducer;

// Create a custom React hook that uses useSelector
export const useFilterData = () => {
   const location = useLocation();
  const projects = useSelector(selectprojects);
  const basePath = "/studentDashboard"
  const assignments = useSelector(selectAssignments);
  const classes = useSelector(selectClasses);
  const searchQuery = useSelector((state) => state.filter.searchQuery);

  const searchResults = [];
  if (searchQuery) {
    projects.forEach((project) => {
      if (project.title.toLowerCase().includes(searchQuery.toLowerCase())) {
        searchResults.push({
          ...project,
          link: `${basePath}/project`,
        });
      }
    });
    assignments.forEach((assignment) => {
      if (assignment.title.toLowerCase().includes(searchQuery.toLowerCase())) {
        searchResults.push({
          ...assignment,
          link: `${basePath}/assignment`,
        });
      }
    });
    classes.forEach((classItem) => {
      if (classItem.class_title.toLowerCase().includes(searchQuery.toLowerCase())) {
        searchResults.push({
          ...classItem,
          link: `${basePath}/classes`,
        });
      }
    });
  }

  return { searchQuery, searchResults };
};
