export function calculateCourseProgress(startDate, duration) {
  // Check if the duration is valid
  const courseDuration = extractNumber(duration);
  if (courseDuration === null) {
    throw new Error("Invalid course duration");
  }

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const millisecondsDiff = currentDate - new Date(startDate);

  // Convert milliseconds to weeks (assuming 1 week = 7 days)
  const elapsedWeeks = Math.floor(millisecondsDiff / (1000 * 60 * 60 * 24 * 7));

  // Calculate course progress
  const courseProgress = elapsedWeeks / courseDuration;

  // Handle cases where the course has not yet started or has already ended
  if (courseProgress < 0) {
    return `0/${courseDuration}`;
  } else if (courseProgress > 1) {
    return `${courseDuration}/${courseDuration}`;
  }

  // Format the progress as a fraction
  return `${Math.floor(courseProgress * courseDuration)}/${courseDuration}`;
}

function extractNumber(text) {
  // Use a regular expression to extract numbers
  const match = text?.match(/\d+/);
  return match ? parseInt(match[0], 10) : null; // Return null if no number found
}
