import React, { useState } from "react";
import CustomModal from "../Modal";
import SubmitSuccess from "./SubmitSuccess";
import { useNavigate } from "react-router-dom";
import {
  submitAssignment,
  updateAssignmentStatus,
} from "../../../services/assignmentService";
import { useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import {
  UPDATE_ASSIGNMENT_STATUS,
  selectAssignment,
} from "../../../redux/assignment/assignmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const initialValue = {
  notes: "",
  links: "",
};

const SubmitAssignment = ({ closeModal }) => {
  const text = "Assignment Submitted Successfully";
  const desc = "We will let you know when it has been reviewed";
  const buttonText = "Go Home";
  const link = "/studentdashboard";
  const navigate = useNavigate();
  const successText = "Project submitted successfully";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [assignmentData, setAssignmentData] = useState(initialValue);
  const [selectedFile, setSelectedFile] = useState(null);
  const { notes, links } = assignmentData;
  const params = useParams();
  const assignment = useSelector(selectAssignment);
  const courseId = assignment?.course_id;

  const assignmentId = parseInt(params.id, 10);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssignmentData({
      ...assignmentData,
      [name]: value,
    });
  };
  //
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const isSubmitDisabled = !notes || !links || !selectedFile;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!notes || !links || !selectedFile) {
      return toast.error("All fields are required");
    }
    setIsLoading(true);
    try {
      const formData = new FormData();

      // Append form fields
      formData.append("assignmentId", assignmentId);
      formData.append("courseId", courseId);
      formData.append("note", assignmentData.notes);
      formData.append("link", assignmentData.links);
      formData.append("attachment", selectedFile);

      const response = await submitAssignment(formData);

      if (response.status >= 200 && response.status < 300) {
        setIsModalOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <main className="flex flex-col pt-5 font-roboto pr-10 pb-16 pl-10 text-lg bg-white rounded-lg max-w-[730px] text-teal-950 max-md:pl-5">
      <header className="flex gap-5 items-start text-xl font-medium max-md:flex-wrap max-md:max-w-full">
        <h1 className="flex-auto mt-5">Submit Assignment</h1>
        <img
          onClick={closeModal}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
          className="shrink-0 w-6 aspect-square"
          alt=""
        />
      </header>
      <section>
        <h2 className="mt-7 max-md:max-w-full">Upload file</h2>
        <div className="flex justify-center items-center px-5 py-4 mt-2.5 text-base text-sky-600 rounded-md border border-solid border-zinc-400 max-md:max-w-full">
          <div className="flex gap-2 px-2.5 py-1.5 bg-white rounded-md borderborder-solid">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e77ba94e26b1c646bdfa4a9b845eb082710361039be574b69dec793e086ec47a?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="shrink-0 w-5 aspect-square"
              alt=""
            />
            <input
              placeholder="Add file"
              type="file"
              onChange={handleFileChange}
              className="w-full border-none h-full outline-none"
            />
          </div>
        </div>
      </section>
      <section>
        <h2 className="mt-6 max-md:max-w-full">Add note</h2>
        <textarea
          name="notes"
          value={notes}
          onChange={handleInputChange}
          className="px-5 pt-4 pb-7 mt-2.5 w-full text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
          placeholder="Add note about your assignment"
        />
      </section>
      <div className="flex gap-2 self-start px-2.5 w-[30%] py-1.5 mt-2.5 text-base text-sky-600 rounded-md border border-sky-600 border-solid">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b472fe61e20bc70fe62fac511c88d114320287529becd5014aa5db7a68dabc66?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
          className="shrink-0 w-5 aspect-square"
          alt=""
        />
        <input
          placeholder="Add link"
          type="link"
          name="links"
          value={links}
          onChange={handleInputChange}
          className="h-full w-full outline-none "
        />
      </div>
      <button
        onClick={handleSubmit}
        disabled={isSubmitDisabled}
        className="px-5 py-3 mt-10 hover:bg-sky-800 font-medium text-white bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full">
        {loading ? (
          <div className="inset-0 flex items-center justify-center">
            <Circles
              height="20"
              width="20"
              color="#fff"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          "submit"
        )}
      </button>

      <CustomModal isOpen={isModalOpen}>
        <SubmitSuccess
          closeModal={() => setIsModalOpen(false)}
          text={text}
          desc={desc}
          link={link}
          buttonText={buttonText}
          handleButtonClick={() => navigate("/studentDashboard/")}
        />
      </CustomModal>
    </main>
  );
};

export default SubmitAssignment;
