import React from "react";

const NoProject = () => {
  return (
    <div className="flex flex-col ml-5 w-[35%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-5 pt-5 pb-20 mx-auto w-full bg-white rounded-lg shadow-lg max-md:mt-8">
        <div className="flex gap-5 text-xl font-medium text-teal-950">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/65fdab9e7d50629b2201d7faf1f82731b8c72d96911024fbf60537b342cb02d9?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 aspect-square w-[25px]"
          />
          <p className="text-base  font-roboto">Manage Projects</p>
        </div>
        <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ce26f140753d3927afa7a7a2b355884726514a6d7517b537b0c8f9a8220f490?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="self-center mt-16 max-w-full aspect-square w-[100px] max-md:mt-10"
        />
        <p className="self-center font-roboto text-center mt-6 text-lg text-zinc-800 w-full">
          You have no project yet
        </p>
      </div>
    </div>
  );
};

export default NoProject;
