import React, {useState, useEffect} from 'react'
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";
import axios from "axios"
import { BACKEND_URL } from '../../../services/url';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../../redux/auth/authSlice';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";


 

const Explore = () => {
  useRedirectLoggedOutUser("/login");
  const coursename  = useSelector(selectCourse) // gets course name from location parameter
  const [videoList, setVideoList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //fetch videos
  useEffect(() => {
    const fetchVideosByCourseName = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        const response = await axios.get(
          `${BACKEND_URL}/videos/${coursename}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setVideoList(response.data);
        } else {
          console.error(
            response.data?.error || "Failed to fetch videos. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching videos";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchVideosByCourseName();
  }, [coursename]);

  return (
    <div className="flex flex-col px-10 py-11 bg-neutral-50 max-md:px-5">
      <div className="text-xl font-medium text-teal-950 max-md:max-w-full">
        Learning Platform
      </div>
      <div className="mt-6 text-lg text-zinc-800 max-md:max-w-full">
        Explore other learning materials
      </div>
      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {isLoading ? (
          <Box sx={{ display: "flex w-full", justifyContent: "center" }}>
            <CircularProgress /> {/* Use Material UI's CircularProgress */}
          </Box>
        ) : videoList.length > 0 ? (
          videoList.slice(
            0,
            3
          ).map((video) => (
            <div
              key={video.id}
              className="flex flex-col grow px-5 py-6 w-full bg-white rounded-md border border-solid border-zinc-400 max-md:mt-10">
              <div className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-16 aspect-[1.71] max-md:px-5">
                <video className="object-cover absolute inset-0 size-full">
                  <source
                    alt={video.videoTitle}
                    loading="lazy"
                    type="video/mp4"
                    src={`${JSON.parse(video.videoUrl).filePath}`}
                  />
                </video>
                <div className="absolute inset-0 flex items-center justify-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${JSON.parse(video.videoUrl).filePath}`}>
                    <img
                      alt="Play"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e902613535987881575f59d7481145b10ea58cbef0188cdcb9ee47e146bc2ebb?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                      className="w-8 aspect-square"
                    />
                  </a>
                </div>
              </div>
              <div className="self-center mt-5 text-lg font-semibold text-zinc-800">
                {video.videoTitle}
              </div>
            </div>
          ))
        ) : (
          <p>No videos found</p>
        )}
      </div>
    </div>
  );
}

export default Explore