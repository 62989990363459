import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url";
import { Circles } from "react-loader-spinner";
import CustomModal from "../../StudentsDashboard/Modal";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess";


const CancelReason = ({ closeModal, classData }) => {
  const navigate = useNavigate();
  
  const handleSubmit = () => {
    navigate("/teachersdashboard");
  };

  const [isCanceling, setIsCanceling] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("")

  const text = "class cancelled Successfully";
  const desc = "";
  const buttonText = "Go Home";
  const link = "/teachersdashboard";

  const handleCancelClass = async () => {
    setIsCanceling(true);
    setErrorMessage("");
    try {
      const response = await axios.put(
        `${BACKEND_URL}/class/${classData.id}/cancel`, {
          cancelReason:cancelReason
        },{
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
      );

      if (response.status === 200) {
        setIsModalOpen(true)
      } else {
        setErrorMessage("Failed to cancel class.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while canceling the class.");
      console.error(error);
    } finally {
      setIsCanceling(false);
    }
  };
  return (
    <div className="flex flex-col pt-5 pb-14 bg-white rounded-lg max-w-[540px]">
      <div className="flex flex-col self-end mr-5 max-w-full text-2xl font-medium text-teal-950 w-[322px] max-md:mr-2.5">
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?"
          className="self-end w-6 aspect-square"
          onClick={closeModal}
        />
      </div>
      <h3 className="text-center justify-center">Cancel class?</h3>
      <div className="flex flex-col px-8 mt-14 w-full text-lg max-md:pl-5 max-md:mt-10 max-md:max-w-full">
        <p className="text-teal-950 max-md:max-w-full">
          Reason for cancelling (optional)
        </p>
        <input
          className="justify-center items-start px-5 pt-4 pb-7 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
          placeholder="State the reason for cancellation"
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
        <button
          onClick={handleCancelClass}
          className="justify-center items-center px-5 py-2 mt-9 font-medium text-white whitespace-nowrap bg-sky-600 hover:bg-sky-800 rounded-md max-md:max-w-full">
          {isCanceling ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20"
                width="20"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "submit"
          )}
        </button>
      </div>
      <CustomModal isOpen={isModalOpen}>
        <SubmitSuccess
          closeModal={() => setIsModalOpen(false)}
          text={text}
          desc={desc}
          link={link}
          buttonText={buttonText}
          handleButtonClick={() => navigate("/teachersdashboard")}
        />
      </CustomModal>
    </div>
  );
};

export default CancelReason;
