import React, { useState } from "react";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  InputBase,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProfile, selectUser } from "../../redux/auth/authSlice.js";

const SmallNav = () => {
  const [openProfileModal, setPenProfileModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const profilePicture = useSelector(selectProfile)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex flex-col items-center px-2 pb-4 pt-2 mx-auto w-full bg-white max-w-[480px] md:hidden">
      <div className="flex gap-5 justify-between w-full">
        <div className="flex gap-2.5 items-center px-5">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8308025baca7728bac7348a564267bd03ebc779b2f34eb72ceda0e5e436a28a6?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 self-stretch my-auto w-1 aspect-[0.22]"
            onClick={handleOpenMenu}
          />
          {!profilePicture ? (
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/42df70c92e08d689b0c78e02095eb3a36617dc5077d6afe06301d0920a7e87a7?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="shrink-0 aspect-square w-[45px]"
            />
          ) : (
            <img
              alt=""
              loading="lazy"
              src={profilePicture}
              className="shrink-0 aspect-square w-[50px] my-auto rounded-full "
            />
          )}
          <div className="flex flex-col self-stretch my-auto">
            <div className="text-sm text-center text-zinc-400">Hello,</div>
            <div className="text-base font-semibold text-teal-950">
              {user.lastName}
            </div>
          </div>
        </div>
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f0789946871c4a61be10732a128e0c168bdcff819ee55e4417bb3fde4cc21003?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 my-auto w-5 aspect-square"
        />
      </div>
      <div className="flex gap-5 mb-4 p-2.5 mt-4 w-[100%]  mx-4 text-xs font-medium rounded-md border border-solid border-zinc-400 text-zinc-400">
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/8a5f22ac6c370f650c41e86f5d0b753a71218dc7233af96e62dc2229ecbd150b?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 aspect-square fill-zinc-400 w-[15px]"
        />
        <input
          placeholder="Search for anything"
          className="w-full h-full outline-none border-none"
        />
      </div>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}>
        <MenuItem onClick={() => setPenProfileModal(true)}>
          <div className="flex gap-2.5">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/99b446b6bf085bfed2085c4742eb9dd9dfaee1decd6749075fd2cfee1c3173b9?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="shrink-0 aspect-square w-[50px]"
            />
            <div className="flex flex-col my-auto">
              <div className="text-lg font-medium text-teal-950">
                {user.userName}
              </div>
              <div className="mt-1 text-sm text-neutral-500">{user.email}</div>
            </div>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/teacherdashboard/profile");
            handleCloseMenu();
          }}>
          <div className="flex gap-3.5 mt-5 text-base whitespace-nowrap text-zinc-800">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/30868a278e3645d33fb862a023ec3abbe55307ddbc0dd24187acd225ba93f881?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="shrink-0 w-5 aspect-square"
            />
            <p>Profile</p>
          </div>
        </MenuItem>
        <MenuItem onClick={() => handleCloseMenu()}>
          <div className="flex gap-3.5 mt-5 text-base whitespace-nowrap text-zinc-800">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/02dfc6d1729475476388dacd5733d237ada32ddfd1702f58d2c955f98bd97a99?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="shrink-0 w-5 aspect-square"
            />
            <p>Logout</p>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SmallNav;
