import React from "react";

function NoProjectMessage() {
  return (
    <section className="flex justify-center items-center px-16 py-20 text-xl bg-neutral-50 text-zinc-800 max-md:px-5">
      <div className="flex flex-col mt-20 max-w-full w-[390px] max-md:mt-10">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/781a5b1805d171ff89a8645ceb4239bef5f0b0ca6767aa6dcc92b770af2d19de?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
          alt="No project illustration"
          className="self-center w-56 max-w-full aspect-[1.2]"
        />
        <p className="mt-12 w-full max-md:mt-10">
          No Project given yet. Kindly check back later
        </p>
      </div>
    </section>
  );
}

export default NoProjectMessage;
