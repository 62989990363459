import React, { useState, useEffect } from "react";
import { fetchSubmittedProjects } from "../../../services/projectService";
import { useParams } from "react-router-dom";
import { formatCreatedAt } from "../../../utils/convertDate";
import { SET_SUBMISSION } from "../../../redux/project/projectSlice";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";

const YourSubmission = () => {
  useRedirectLoggedOutUser("/login")
  const [submission, setSubmission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
const reviewedAttachment =
  submission?.attachments && JSON.parse(submission.attachments);
  const params = useParams();

  const projectId = parseInt(params.id, 10);

  //fetch submission
  useEffect(() => {
    const fetchSubmittedProject = async () => {
      setIsLoading(true);
      try {
        const response = await fetchSubmittedProjects(projectId);

        if (response.status >= 200 && response.status < 300) {
          setSubmission(response.data.submission);
          SET_SUBMISSION(response.data.submission);
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch assignment submission. Please try again."
          );
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment submission";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSubmittedProject();
  }, [projectId, params]);

  return (
    <div className="justify-center px-10 pt-12 pb-20 bg-neutral-50 max-md:px-5">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col items-start px-10 pt-9 pb-16 w-full bg-white rounded-lg shadow-lg text-zinc-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="self-stretch text-lg font-medium max-md:max-w-full">
              Your Submission
            </div>
            <div className="mt-9 w-full text-base text-neutral-500">
              Document
            </div>
            {reviewedAttachment !== undefined ? (
              <div className="flex gap-2.5 mt-2.5">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col w-full px-2 py-2 rounded-md bg-slate-50">
                    <div className="flex flex-col px-1.5 py-2 text-xs bg-white rounded-md">
                      <div className="mt-1.5">
                        <Thumbnail fileUrl={reviewedAttachment?.filePath} />
                      </div>
                    </div>
                    <div className="self-center mt-1.5 text-xs">
                      <a href={reviewedAttachment?.filePath} target="_blank" rel="noreferrer">
                        {reviewedAttachment?.filePath}
                      </a>
                      {reviewedAttachment?.fileName}
                    </div>
                  </div>
                </div>
                <div className="hidden lg:flex flex-col justify-center text-xs whitespace-nowrap">
                  <div className="flex flex-col items-center px-2 py-2 rounded-md bg-slate-50">
                    <img
                      alt=""
                      loading="lazy"
                      srcSet={convertToPngUrl(
                        "https://res.cloudinary.com/sawl/image/upload/v1723676241/sawl_assignments/2024-08-14T22-57-16.517Z-Angular2NotesForProfessionals_sxmwgq.pdf"
                      )}
                      className="aspect-[1.23] w-[124px]"
                    />
                    <div className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {reviewedAttachment?.fileName}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p className="mt-1.5 text-zinc-800 font-medium">
                No attachment for this submission
              </p>
            )}
            <div className="self-stretch mt-10 text-base text-neutral-500 max-md:max-w-full">
              Note
            </div>
            <div className="self-stretch mt-2.5 text-lg leading-7 max-md:max-w-full">
              {submission?.notes}
            </div>
            <div className="mt-10 text-base text-neutral-500">Link</div>
            {submission && submission.links && (
              <a
                href={submission?.links.replace(/^"|"$/g, "")}
                className="mt-2.5 text-lg text-sky-600">
                {submission?.links?.replace(/^"|"$/g, "")}
              </a>
            )}
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col p-8 mx-auto w-full bg-white border border-solid border-zinc-400 max-md:px-5 max-md:mt-10">
            <div className="text-base text-neutral-500">Status</div>
            <div className="flex gap-2.5 mt-2.5">
              <div className="justify-center px-5 py-2 text-base text-sky-600 whitespace-nowrap bg-sky-100 rounded-md">
                {submission?.status}
              </div>
              <div className="my-auto text-md text-zinc-800">
                {`on ${formatCreatedAt(submission?.created_at)}`}
              </div>
            </div>
            <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
            <p className="self-start mt-5 text-base text-neutral-500">Grade</p>
            <p className="self-start mt-2 text-lg font-medium text-zinc-800">
              Pending
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourSubmission;
