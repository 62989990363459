import React from 'react';
import { FaSquareInstagram } from "react-icons/fa6";

function SocialIcons() {
  const icons = [
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/105ebf27a870fbbbb1fd929f11ab698b581241f01a3c31108176996dc009266a?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4", alt: "Social media icon 1", url:"https://www.linkedin.com/in/ibukun-isaac-aina-phd-781149130" },
    // { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/370fa5fa6469acefc3ff1e0452d186c7a10bacbf5c2b9e702af7df3f977e454f?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4", alt: "Social media icon 2", className: "my-auto" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/c6b5232e489bddd3f367938b294ddc41a1d5e63a51b62f4f21c79f21878495fd?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4", alt: "Social media icon 3", url:"www.ainaibukun.com" },
    // { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/109dcc6d8530c3040294ce06d80ba3205a70c2730a107b970138d49478bdc62b?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4", alt: "Social media icon 4" }
  ];

  return (
    <div className="flex gap-2.5 self-start mt-8 ml-0 max-md:ml-2.5">
      {icons.map((icon, index) => (
        <a href={icon.url} target="_blank" rel="noreferrer">
          <img
            key={index}
            loading="lazy"
            src={icon.src}
            alt={icon.alt}
            className={`shrink-0 w-6 aspect-square ${icon.className || ""}`}
          />
        </a>
      ))}
    </div>
  );
}

export default SocialIcons;