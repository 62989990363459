import React from 'react'
import noClass from '../../../images/Noclass.png'

const EmptyClass = () => {
  return (
      <div className="flex flex-col items-center justify-center min-h-screen gap-4">
  <img src={noClass} alt="empty class" className="w-56 h-44 md:w-64 md:h-52" />
  <p className="text-center text-xl font-roboto leading-6 text-[#2F2F2F] px-4">
    No classes scheduled yet, kindly check back later
  </p>
</div>

  )
}

export default EmptyClass