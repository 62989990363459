import React from 'react'

const ClassProgress = () => {
  return (
     <div className="flex flex-col ml-2 w-[25%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow p-4 w-full bg-white rounded-lg shadow-lg max-md:mt-5">
              <p className="text-base text-neutral-500">Class Progress</p>
              <div className="flex overflow-hidden relative flex-col self-center px-6 py-8 mt-[30px] aspect-square max-md:px-5">
                <img alt=""
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/01657acd03919d47e39f8ca15f0eca2309a4bc3111fbdbbb3f17334efbc6256f?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="object-cover absolute inset-0 size-full"
                />
                <p className="relative self-center text-xl font-medium text-teal-950">
                  4/12
                </p>
                <p className="relative mt-1 text-sm text-neutral-500">
                  Weeks done
                </p>
              </div>
            </div>
          </div>
  )
}

export default ClassProgress