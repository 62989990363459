import React, { useState, useEffect } from "react";
import { fetchSubmittedProjects } from "../../../services/projectService";
import { useParams } from "react-router-dom";
import { formatCreatedAt } from "../../../utils/convertDate";
import { Box, CircularProgress } from "@mui/material";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";

const ReviewedProject = () => {
  useRedirectLoggedOutUser("/login")
  const [reviewed, setReviewed] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const reviewedAttachment = reviewed?.attachments && JSON.parse(reviewed.attachments)

  const params = useParams();

  const projectId = parseInt(params.id, 10);

  //fetch reviewed
  useEffect(() => {
    const fetchSubmittedProject = async () => {
      setIsLoading(true);
      try {
        const response = await fetchSubmittedProjects(projectId);

        if (response.status >= 200 && response.status < 300) {
          setReviewed(response.data.submission);
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch project reviewed. Please try again."
          );
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching project reviewed";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSubmittedProject();
  }, [projectId, params]);

  return (
    <div className="justify-center px-4 pt-12 pb-20 bg-neutral-50 max-md:px-5">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col items-start px-10 pt-9 pb-16 w-full bg-white rounded-lg shadow-lg text-zinc-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="self-stretch text-lg font-medium max-md:max-w-full">
              Your reviewed Project
            </div>
            {isLoading && (
              <Box sx={{ display: "flex w-full", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            <div className="mt-9 text-base text-neutral-500">Document</div>
            {reviewedAttachment !== undefined ? (
              <div className="flex gap-2.5 mt-2.5">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                    <div className="flex flex-col px-1.5 py-2 text-xs bg-white rounded-md">
                      <div className="mt-1.5">
                        <Thumbnail fileUrl={reviewedAttachment?.filePath} />
                      </div>
                    </div>
                    <div className="self-center mt-1.5 text-xs">
                      {reviewedAttachment?.fileName}
                    </div>
                  </div>
                </div>
                <div className="hidden xl:flex flex-col justify-center text-xs whitespace-nowrap">
                  <div className="flex flex-col items-center px-2 py-2 rounded-md bg-slate-50">
                    <img
                      alt=""
                      loading="lazy"
                      srcSet={convertToPngUrl(
                        "https://res.cloudinary.com/sawl/image/upload/v1723676241/sawl_assignments/2024-08-14T22-57-16.517Z-Angular2NotesForProfessionals_sxmwgq.pdf"
                      )}
                      className="aspect-[1.23] w-[124px]"
                    />
                    <div className="mt-1.5">{reviewedAttachment?.fileName}</div>
                  </div>
                </div>
              </div>
            ) : (
              <p className="mt-1.5 text-zinc-800 font-medium">
                No attachment for this submission
              </p>
            )}

            <div className="self-stretch mt-10 text-base text-neutral-500 max-md:max-w-full">
              Note
            </div>
            <div className="self-stretch mt-2.5 text-lg leading-7 max-md:max-w-full">
              {reviewed?.notes}
            </div>
            <div className="mt-10 text-base text-neutral-500">Link</div>
            <a
              href={reviewed?.links?.replace(/^"|"$/g, "")}
              className="mt-2.5 text-lg text-sky-600">
              {reviewed?.links?.replace(/^"|"$/g, "")}
            </a>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-8 pt-8 pb-16 mx-auto mt-12 w-full bg-white border border-solid border-zinc-400 max-md:px-5 max-md:mt-10">
            <div className="text-base text-neutral-500">Status</div>
            <div className="flex gap-2.5 mt-2.5">
              <div className="justify-center px-5 py-2 text-base text-green-800 whitespace-nowrap bg-green-100 rounded-md">
                {reviewed?.status}
              </div>
              <div className="my-auto text-lg text-zinc-800">
                {` on ${formatCreatedAt(reviewed?.updated_at)}`}
              </div>
            </div>
            <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
            <div className="self-start mt-5 text-base text-neutral-500">
              Grade
            </div>
            <div className="self-start mt-2 text-lg font-medium text-zinc-800">
              {`${reviewed?.grade}/100`}
            </div>
            <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
            <div className="mt-5 text-base text-neutral-500">Comment</div>
            <div className="mt-5 text-base text-neutral-500">
              Teacher
              <br />
              <span className="text-sm text-neutral-500">
                {formatCreatedAt(reviewed?.updated_at)}
              </span>
            </div>
            <div className="mt-2.5 text-base text-zinc-800">
              {reviewed?.comment}
            </div>

            <input
              placeholder="Type your comment...
            "
              className="justify-center items-start px-5 py-4 mt-6 text-sm rounded-md border border-solid border-zinc-400 text-neutral-500"
            />

            <button className="justify-center text-center items-center px-5 py-3 mt-16 text-lg font-medium text-white whitespace-nowrap bg-sky-600 rounded-md max-md:mt-10">
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewedProject;
