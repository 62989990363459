import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { CiGrid42 } from "react-icons/ci";
import { FiBookOpen } from "react-icons/fi";
import { CgNotes } from "react-icons/cg";
import { MdOutlineTableChart } from "react-icons/md";
import { GrSearch } from "react-icons/gr";
import ScrollToTop from "../ScrollToTop";


function StudentFooter({setActiveComponent}){
  const [hoveredButton, setHoveredButton] = useState(null);
  const [selectedButton, setSelectedButton] = useState("Dashboard");
  const navigate = useNavigate();

  const handleButtonClick = (buttonId) => {
    setActiveComponent(buttonId);
    setSelectedButton(buttonId);

    // Set the selected button to have the blue background
    //navigate(`/studentdashboard/${buttonId}`); // Navigate to the corresponding path
  };

  const handleButtonHover = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleButtonLeave = () => {
    setHoveredButton(null);
  };
  return (
    <div className="w-full flex flex-col mt-4 items-center text-sky-600 justify-center px-5 py-2.5 text-xs bg-white shadow-sm fixed bottom-0 md:hidden">
      <div className="flex gap-4 justify-between items-center">
        <Link
          to="/studentdashboard"
          className="flex flex-col self-stretch my-auto whitespace-nowrap">
          <CiGrid42 size={24}
            className={`self-center w-6 aspect-square ${
          selectedButton === "dashboard"
            ? "bg-[#127ABB] text-white"
            : "hover:bg-[#127ABB] hover:text-white"
        }`}
        onClick={() => handleButtonClick("dashboard")}
        onMouseEnter={() => handleButtonHover("dashboard")}
        onMouseLeave={handleButtonLeave}
          />
          <div className="mt-1.5">Dashboard</div>
        </Link>
        <Link
           to="/studentdashboard/classes"
          className="flex flex-col self-stretch my-auto font-medium">
          <FiBookOpen size={24}
            className={`self-center w-6 aspect-square ${
          selectedButton === "classes"
            ? "bg-[#127ABB] text-white"
            : "hover:bg-[#127ABB] hover:text-white"
        }`}
        onClick={() => handleButtonClick("classes")}
        onMouseEnter={() => handleButtonHover("classes")}
        onMouseLeave={handleButtonLeave}
          />
          <div className="mt-1.5">Classes</div>
        </Link>
        <Link
         to="/studentdashboard/assignment"  className="flex flex-col self-stretch my-auto">
          <CgNotes size={24}
             className={`self-center w-6 aspect-square ${
          selectedButton === "assignment"
            ? "bg-[#127ABB] text-white"
            : "hover:bg-[#127ABB] hover:text-white"
        }`}
        onClick={() => handleButtonClick("assignment")}
        onMouseEnter={() => handleButtonHover("assignment")}
        onMouseLeave={handleButtonLeave}
          />
          <div className="mt-1.5">Assignments</div>
        </Link>
        <Link
           to="/studentdashboard/project"
          className="flex flex-col self-stretch my-auto whitespace-nowrap">
          <MdOutlineTableChart size={24}
             className={`self-center w-6 aspect-square ${
          selectedButton === "project"
            ? "bg-[#127ABB] text-white"
            : "hover:bg-[#127ABB] hover:text-white"
        }`}
        onClick={() => handleButtonClick("project")}
        onMouseEnter={() => handleButtonHover("project")}
        onMouseLeave={handleButtonLeave}
          />
          <div className="mt-1.5">Project</div>
        </Link>
        <Link
           to="/studentdashboard/explore"
          className="flex flex-col self-stretch whitespace-nowrap">
          <GrSearch size={24}
            className={`self-center w-6 aspect-square ${
          selectedButton === "explore"
            ? "bg-[#127ABB] text-white"
            : "hover:bg-[#127ABB] hover:text-white"
        }`}
        onClick={() => handleButtonClick("explore")}
        onMouseEnter={() => handleButtonHover("explore")}
        onMouseLeave={handleButtonLeave}
          />
          <div className="mt-2.5">Explore</div>
        </Link>
      </div>
    </div>
  );
};

export default StudentFooter;
