import { createSlice } from "@reduxjs/toolkit";

const name = JSON.parse(localStorage.getItem("first_name"));

const initialState = {
  isLoggedIn: false,
  paymentDetails: null,
  name: name ? name : "",
  paymentOption: "full",
  paymentMethod: "card",
  stripeIntentSecret: "",
  profilePicture:"",
  orderId: "",
  transactionId: "",
  user1: {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    phoneNumber: null,
    selectedCourse: "",
  },
  user: {
    studentId: "",
    firstName: "",
    lastName: "",
    country: "",
    email: "",
    phoneNumber: null,
    username: "",
    course: "",
    token: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_LOGIN(state, action) {
      state.isLoggedIn = action.payload;
    },
    SET_NAME(state, action) {
      state.name = action.payload;
    },
    SET_USER1(state, action) {
      const profile = action.payload;
      state.user1.firstName = profile.firstName;
      state.user1.lastName = profile.lastName;
      state.user1.email = profile.email;
      state.user1.country = profile.country;
      state.user1.phoneNumber = profile.phoneNumber;
      state.user1.selectedCourse = profile.selectedCourse;
    },
    SET_PAY_METHOD(state, action) {
      state.paymentMethod = action.payload;
    },
    SET_USER(state, action) {
      const profile = action.payload;
      state.user.studentId = profile.id;
      state.user.firstName = profile.first_name;
      state.user.lastName = profile.last_name;
      state.user.course = profile.courseName;
      state.user.phoneNumber = profile.phone_number;
      state.user.username = profile.username;
      state.user.country = profile.country;
      state.user.email = profile.email;
      state.user.token = profile.token;
    },
    SET_USER_COURSE(state, action) {
      state.user.course = action.payload;
    },
    SET_PROFILE(state, action) {
      state.profilePicture = action.payload
    },
    SET_PAYMENT_OPTION(state, action) {
      state.paymentOption = action.payload;
    },
    SET_STRIPE_INTENT_SECRET(state, action) {
      state.stripeIntentSecret = action.payload;
    },
    SET_ORDER_ID(state, action) {
      state.orderId = action.payload;
    },
    SET_TRANSACTIONID(state, action) {
      state.transactionId = action.payload;
    },
    CLEAR_PAYMENT_DATA(state) {
      state.stripeIntentSecret = "";
      state.orderId = "";
      state.transactionId = "";
    },

    SET_PAYMENT_DETAILS(state, action) {
      state.paymentDetails = action.payload;
    },
  },
});

export const {
  SET_LOGIN,
  SET_PAY_METHOD,
  SET_PROFILE,
  SET_NAME,
  SET_USER,
  SET_USER_COURSE,
  SET_USER1,
  SET_PAYMENT_OPTION,
  SET_STRIPE_INTENT_SECRET,
  SET_ORDER_ID,
  SET_TRANSACTIONID,
  CLEAR_PAYMENT_DATA,
  SET_PAYMENT_DETAILS,
} = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectName = (state) => state.auth.name;
export const selectUser = (state) => state.auth.user;
export const selectCountry = (state) => state.auth.user.country;
export const selectCountry1 = (state) => state.auth.user1.country;
export const selectPaymentOption = (state) => state.auth.paymentOption;
export const selectCourse = (state) => state.auth.user.course;
export const selectCourse1 = (state) => state.auth.user1.selectedCourse;
export const selectStripeSecret = (state) => state.auth.stripeIntentSecret;
export const selectOrderId = (state) => state.auth.orderId;
export const selectTransactionId = (state) => state.auth.transactionId;
export const selectPaymentDetails = (state) => state.auth.paymentDetails;
export const selectPayMethod = (state) => state.auth.paymentMethod;
export const selectProfile = (state) => state.auth.profilePicture

export default authSlice.reducer;
