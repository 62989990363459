import React, { useState, useEffect } from "react";
import { selectClasses } from "../../../redux/class/classSlice";
import { useSelector } from "react-redux";
import vt from "../../../images/videoThumb.jpg"


function VideoListPage() {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const allClasses = useSelector(selectClasses);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // Filter classes to only include those with "Done" status AND a 'class_recording'
    const doneClassesWithRecordings = Array.isArray(allClasses)
      ? allClasses.filter(
          (classItem) =>
            classItem.status === "Done" && classItem.class_recordings
        )
      : [];

    setVideos(doneClassesWithRecordings);

    console.log("done classes with recordings: ", doneClassesWithRecordings);
  }, [allClasses]);


  // const videos = [
  //   {
  //     id: 1,
  //     title: "Video 1",
  //     thumbnail: "thumbnail1.jpg",
  //     url: "https://www.youtube.com/embed/VIDEO_ID_1",
  //   },
  //   {
  //     id: 2,
  //     title: "Video 2",
  //     thumbnail: "thumbnail2.jpg",
  //     url: "https://www.youtube.com/embed/VIDEO_ID_2",
  //   },
  //   // Add more videos as needed
  // ];

const handleVideoClick = (classItem) => {
  setSelectedVideo(classItem); // Set the selected video to the entire classItem object
};
  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl text-zinc-600 font-bold mb-4">Class Recordings</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {videos
          .filter((video) => video.class_recordings)
          .map((classItem) => (
            <div
              key={classItem.id}
              className="cursor-pointer relative bg-white rounded-md border border-gray-300 overflow-hidden" // Adjusted background and border
            >
              <div className="relative pt-[56.25%]">
                {" "}
                {/* Maintain aspect ratio */}
                <img // Use the image thumbnail
                  src={vt}
                  alt={classItem.class_title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={classItem.class_recordings}
                  className="absolute inset-0 flex items-center justify-center">
                  <img
                    alt="Play"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e902613535987881575f59d7481145b10ea58cbef0188cdcb9ee47e146bc2ebb?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                    className="w-12 h-12" // Adjusted play button size
                  />
                </a>
              </div>
              <div className="p-4">
                {" "}
                {/* Added padding for title */}
                <h3 className="text-lg font-medium w-full text-gray-800">
                  {classItem.class_title}
                </h3>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default VideoListPage;
