import React, { useState, useEffect } from "react";
import lg from "../../images/Light logo.png";
import bg from "../../images/registerimg.png";
import { useNavigate } from "react-router-dom";
import SubmitSuccess from "../StudentsDashboard/Assignment/SubmitSuccess";
import {
  registerUser,
  validateEmail,
  checkExistingStudent,
  registerTutor,
} from "../../services/authServices";
import { toast } from "react-toastify";
import axios from "axios";
import { BACKEND_URL } from "../../services/url";
import { Circles } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { SET_USER1 } from "../../redux/auth/authSlice";
import CustomModal from "../StudentsDashboard/Modal";


const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  phoneNumber: "",
  selectedCourse: "",
};

const RegisterTutor = ({closeModal}) => {
  const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [credentials, setCredentials] = useState({});

 const text = "Tutor created Successfully";
 const desc = `Username:${credentials.username}\nPassword: ${credentials.randomPassword}`;
 const buttonText = "Go Home";
 const link = "/studentdashboard";

  const navigateToPage = (path) => {
    navigate(path);
    // setNav(false); // Close the menu after navigation
  };

  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
 
  const { firstName, lastName, email, country, phoneNumber, selectedCourse } =
    formData;
  const [courseOptions, setCourseOptions] = useState([]);
  const [errors, setErrors] = useState({});

  //fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/fetch-courses`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setCourseOptions(response.data);
        } else {
          console.error(
            response.data?.error || "Failed to fetch courses. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching courses";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateInput(name, value);
  };

  const validateInput = (name, value) => {
    let error = {};
    switch (name) {
      case "firstName":
        if (!/^[a-zA-Z]+$/.test(value)) {
          error = {
            ...error,
            firstName: "First name should only contain letters",
          };
        } else if (value.length < 3) {
          error = {
            ...error,
            firstName: "First name should be at least 3 characters long",
          };
        } else {
          // Remove the error if the input is valid
          error.firstName = "";
        }
        break;
      case "lastName":
        if (!/^[a-zA-Z]+$/.test(value)) {
          error = {
            ...error,
            lastName: "Last name should only contain letters",
          };
        } else if (value.length < 3) {
          error = {
            ...error,
            lastName: "Last name should be at least 3 characters long",
          };
        } else {
          // Remove the error if the input is valid
          error.lastName = "";
        }
        break;
      case "email":
        if (!validateEmail(value)) {
          error = { ...error, email: "Invalid email address" };
        } else {
          // Remove the error if the input is valid
          error.email = "";
        }
        break;
      case "phoneNumber":
        // if (!/^\d{3}-\d{3}-\d{4}$/.test(value)) {
        //   error = {
        //     ...error,
        //     phoneNumber: "Invalid phone number format (XXX-XXX-XXXX)",
        //   };
        // }
        if (value.length < 10) {
          error = {
            ...error,
            phoneNumber: "phoneNumber should be at least 10 characters long",
          };
        } else {
          // Remove the error if the input is valid
          error.phoneNumber = "";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, ...error });
  };
  const isSubmitDisabled =
    !firstName ||
    !lastName ||
    !email ||
    !country ||
    !phoneNumber ||
    !selectedCourse;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !country ||
      !selectedCourse
    ) {
      return toast.error("All fields are required");
    }
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }
    setIsLoading(true);
    try {
          const userData = {
            firstName,
            lastName,
            email,
            country,
            phoneNumber,
            selectedCourse,
          };

          const response = await registerTutor(userData);

          if (response.status >= 200 && response.status < 300) {
           const { username, randomPassword } = response.data;
           setCredentials({ username, randomPassword });
           setIsOpen(true);
          }
          return response.data;
        }
      
    catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  const countryCodes = [
    {
      name: "Niger",
      code: "+227",
      shortForm: "NER",
      currencyCode: "XOF",
      currencySymbol: "CFA",
    },
    {
      name: "Nigeria",
      code: "+234",
      shortForm: "NG",
      currencyCode: "NGN",
      currencySymbol: "₦",
    },
    {
      name: "Rwanda",
      code: "+250",
      shortForm: "RWA",
      currencyCode: "RWF",
      currencySymbol: "FRw",
    },
    {
      name: "Sao Tome and Principe",
      code: "+239",
      shortForm: "STP",
      currencyCode: "STN",
      currencySymbol: "Db",
    },
    {
      name: "Senegal",
      code: "+221",
      shortForm: "SEN",
      currencyCode: "XOF",
      currencySymbol: "CFA",
    },
    {
      name: "Seychelles",
      code: "+248",
      shortForm: "SYC",
      currencyCode: "SCR",
      currencySymbol: "₨",
    },
    {
      name: "Sierra Leone",
      code: "+232",
      shortForm: "SLE",
      currencyCode: "SLL",
      currencySymbol: "Le",
    },
    {
      name: "Somalia",
      code: "+252",
      shortForm: "SOM",
      currencyCode: "SOS",
      currencySymbol: "Sh",
    },
    {
      name: "South Africa",
      code: "+27",
      shortForm: "ZAF",
      currencyCode: "ZAR",
      currencySymbol: "R",
    },
    {
      name: "South Sudan",
      code: "+211",
      shortForm: "SSD",
      currencyCode: "SSP",
      currencySymbol: "£",
    },
    {
      name: "Sudan",
      code: "+249",
      shortForm: "SDN",
      currencyCode: "SDG",
      currencySymbol: "ج.س.",
    },
    {
      name: "Tanzania",
      code: "+255",
      shortForm: "TZA",
      currencyCode: "TZS",
      currencySymbol: "TSh",
    },
    {
      name: "Togo",
      code: "+228",
      shortForm: "TGO",
      currencyCode: "XOF",
      currencySymbol: "CFA",
    },
    {
      name: "Tunisia",
      code: "+216",
      shortForm: "TUN",
      currencyCode: "TND",
      currencySymbol: "د.ت",
    },
    {
      name: "Uganda",
      code: "+256",
      shortForm: "UGA",
      currencyCode: "UGX",
      currencySymbol: "USh",
    },
    {
      name: "Zambia",
      code: "+260",
      shortForm: "ZMB",
      currencyCode: "ZMW",
      currencySymbol: "ZK",
    },
    {
      name: "Zimbabwe",
      code: "+263",
      shortForm: "ZWE",
      currencyCode: "ZWL",
      currencySymbol: "Z$",
    },
    {
      name: "United States",
      code: "+1",
      shortForm: "US",
      currencyCode: "USD",
      currencySymbol: "",
    },
    {
      name: "United Kingdom",
      code: "+44",
      shortForm: "GB",
      currencyCode: "GBP",
      currencySymbol: "£",
    },
    {
      name: "Germany",
      code: "+49",
      shortForm: "DEU",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "France",
      code: "+33",
      shortForm: "FRA",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Italy",
      code: "+39",
      shortForm: "ITA",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Spain",
      code: "+34",
      shortForm: "ESP",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Netherlands",
      code: "+31",
      shortForm: "NLD",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Belgium",
      code: "+32",
      shortForm: "BEL",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Sweden",
      code: "+46",
      shortForm: "SWE",
      currencyCode: "SEK",
      currencySymbol: "kr",
    },
    {
      name: "Norway",
      code: "+47",
      shortForm: "NOR",
      currencyCode: "NOK",
      currencySymbol: "kr",
    },
    {
      name: "Canada",
      code: "+1",
      shortForm: "CAN",
      currencyCode: "CAD",
      currencySymbol: "",
    },
    {
      name: "Mexico",
      code: "+52",
      shortForm: "MEX",
      currencyCode: "MXN",
      currencySymbol: "",
    },
    {
      name: "Brazil",
      code: "+55",
      shortForm: "BRA",
      currencyCode: "BRL",
      currencySymbol: "R",
    },
    {
      name: "Argentina",
      code: "+54",
      shortForm: "ARG",
      currencyCode: "ARS",
      currencySymbol: "",
    },
    {
      name: "Chile",
      code: "+56",
      shortForm: "CHL",
      currencyCode: "CLP",
      currencySymbol: "",
    },
    {
      name: "Colombia",
      code: "+57",
      shortForm: "COL",
      currencyCode: "COP",
      currencySymbol: "",
    },
    {
      name: "Peru",
      code: "+51",
      shortForm: "PER",
      currencyCode: "PEN",
      currencySymbol: "S/",
    },
    {
      name: "Venezuela",
      code: "+58",
      shortForm: "VEN",
      currencyCode: "VES",
      currencySymbol: "Bs.S",
    },
    {
      name: "Ecuador",
      code: "+593",
      shortForm: "ECU",
      currencyCode: "USD",
      currencySymbol: "",
    },
    {
      name: "Bolivia",
      code: "+591",
      shortForm: "BOL",
      currencyCode: "BOB",
      currencySymbol: "Bs",
    },
    {
      name: "Paraguay",
      code: "+595",
      shortForm: "PRY",
      currencyCode: "PYG",
      currencySymbol: "₲",
    },
    {
      name: "Uruguay",
      code: "+598",
      shortForm: "URY",
      currencyCode: "UYU",
      currencySymbol: "U",
    },
    {
      name: "Panama",
      code: "+507",
      shortForm: "PAN",
      currencyCode: "PAB",
      currencySymbol: "B/.",
    },
    {
      name: "Costa Rica",
      code: "+506",
      shortForm: "CRI",
      currencyCode: "CRC",
      currencySymbol: "₡",
    },
    {
      name: "Puerto Rico",
      code: "+1",
      shortForm: "PRI",
      currencyCode: "USD",
      currencySymbol: "</span>",
    },
    {
      name: "Dominican Republic",
      code: "+1",
      shortForm: "DOM",
      currencyCode: "DOP",
      currencySymbol: "RD$",
    },
    {
      name: "Jamaica",
      code: "+1",
      shortForm: "JAM",
      currencyCode: "JMD",
      currencySymbol: "J$",
    },
    {
      name: "Trinidad and Tobago",
      code: "+1",
      shortForm: "TTO",
      currencyCode: "TTD",
      currencySymbol: "TT$",
    },
    {
      name: "Barbados",
      code: "+1",
      shortForm: "BRB",
      currencyCode: "BBD",
      currencySymbol: "",
    },
    {
      name: "Bahamas",
      code: "+1",
      shortForm: "BHS",
      currencyCode: "BSD",
      currencySymbol: "</span>",
    },
    {
      name: "Belize",
      code: "+501",
      shortForm: "BLZ",
      currencyCode: "BZD",
      currencySymbol: "BZ$",
    },
    {
      name: "Honduras",
      code: "+504",
      shortForm: "HND",
      currencyCode: "HNL",
      currencySymbol: "L",
    },
    {
      name: "El Salvador",
      code: "+503",
      shortForm: "SLV",
      currencyCode: "USD",
      currencySymbol: "",
    },
    {
      name: "Guatemala",
      code: "+502",
      shortForm: "GTM",
      currencyCode: "GTQ",
      currencySymbol: "Q",
    },
    {
      name: "Nicaragua",
      code: "+505",
      shortForm: "NIC",
      currencyCode: "NIO",
      currencySymbol: "C</span>",
    },
    {
      name: "Japan",
      code: "+81",
      shortForm: "JPN",
      currencyCode: "JPY",
      currencySymbol: "¥",
    },
    {
      name: "China",
      code: "+86",
      shortForm: "CHN",
      currencyCode: "CNY",
      currencySymbol: "¥",
    },
    {
      name: "India",
      code: "+91",
      shortForm: "IND",
      currencyCode: "INR",
      currencySymbol: "₹",
    },
    {
      name: "South Korea",
      code: "+82",
      shortForm: "KOR",
      currencyCode: "KRW",
      currencySymbol: "₩",
    },
    {
      name: "Australia",
      code: "+61",
      shortForm: "AUS",
      currencyCode: "AUD",
      currencySymbol: "",
    },
    {
      name: "New Zealand",
      code: "+64",
      shortForm: "NZL",
      currencyCode: "NZD",
      currencySymbol: "</span>",
    },
  ];

  return (
    <div className="w-full mb-20 py-16 flex ">
      {/* {isLoading && <LoadingModal open={isLoading} />} */}

      <div className="bg-white  w-full xl:w-[60%] ">
        <div className="px-8 flex flex-row justify-between py-8">
          <p className="text-3xl font-semibold">Create tutor login</p>
          <button onClick={closeModal}>
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="shrink-0 w-6 aspect-square"
            />
          </button>
        </div>

        <form
          action=""
          className=" mb-20 px-10 flex flex-col justify-center gap-4">
          <div className="flex flex-col  w-[100%]  gap-1  ">
            <label htmlFor="" className="font-semibold">
              First Name
            </label>
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              name="firstName"
              onChange={handleInputChange}
              className="border-2 border-gray-400 rounded-md py-1.5 px-2 placeholder-gray-500 placeholder text-sm"
            />
            {errors.firstName ? (
              <p className="text-red-500 text-sm">{errors.firstName}</p>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-col  w-[100%]  gap-1  ">
            <label htmlFor="" className="font-semibold">
              Last Name
            </label>
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              name="lastName"
              onChange={handleInputChange}
              className="border-2 border-gray-400 rounded-md py-1.5 px-2 placeholder-gray-500 placeholder text-sm"
            />
            {errors.lastName ? (
              <p className="text-red-500 text-sm">{errors.lastName}</p>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-col  w-[100%]  gap-1  ">
            <label htmlFor="" className="font-semibold">
              Email
            </label>
            <input
              type="text"
              placeholder="example@domain.com"
              value={email}
              name="email"
              onChange={handleInputChange}
              className="border-2 border-gray-400 rounded-md py-1.5 px-2 placeholder-gray-500 placeholder text-sm"
            />
            {errors.email ? (
              <p className="text-red-500 text-sm">{errors.email}</p>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-col w-[100%]  gap-1  ">
            <label htmlFor="" className="font-semibold">
              Where did you get to know about us?
            </label>
            <select
              name="referredFrom"
              id=""
              onChange={handleInputChange}
              className="border-2 border-gray-400 rounded-md py-1.5 px-2 placeholder-gray-500 placeholder text-sm">
              <option value="" disabled selected>
                Select an option
              </option>
              <option value="google">Google</option>
              <option value="friend">Friend</option>
              <option value="social-media">Social Media</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="flex justify-between gap-6  md:gap-6">
            <div className="flex flex-col w-[50%] gap-1 ">
              <label htmlFor="" className="font-semibold">
                Country
              </label>
              <select
                name="country"
                id=""
                value={country}
                onChange={handleInputChange}
                className="border-2 border-gray-400 rounded-md py-1.5 px-2 placeholder-gray-500 placeholder text-sm">
                <option
                  value=""
                  disabled
                  selected
                  className="text-xs md:text-sm">
                  US +1
                </option>
                {countryCodes.map((country, index) => (
                  <option
                    key={index}
                    value={`${country.shortForm}-${country.code}-${country.currencyCode}`}
                    className="text-xs md:text-sm">
                    {`${country.shortForm} ${country.code}`}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-[50%] gap-1 ">
              <label htmlFor="" className="font-semibold">
                Phone Number
              </label>
              <input
                type="tel"
                value={phoneNumber}
                name="phoneNumber"
                onChange={handleInputChange}
                className="border-2 border-gray-400 rounded-md py-1.5 px-2 placeholder-gray-500 placeholder text-sm"
              />
              {errors.phoneNumber ? (
                <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex flex-col w-[100%]  gap-1  ">
            <label htmlFor="" className="font-semibold">
              Select Course
            </label>
            <select
              name="selectedCourse"
              id=""
              value={selectedCourse}
              onChange={handleInputChange}
              className="border-2 border-gray-400 rounded-md py-1.5 px-2 placeholder-gray-500 placeholder text-sm">
              <option value="" disabled selected>
                Select an option
              </option>
              {courseOptions.map((option) => (
                <option key={option.id} value={option.courseName}>
                  {option.courseName}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col w-[100%] gap-1  ">
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              className={`bg-new-blue text-white hover:bg-blue-900 ${
                isSubmitDisabled && "opacity-50 cursor-not-allowed"
              }`}>
              {isLoading ? (
                <div className="inset-0 flex items-center justify-center">
                  <Circles
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                "submit"
              )}
            </button>
          </div>
        </form>
      </div>
      {isOpen && (
        <CustomModal isOpen={isOpen}>
          <SubmitSuccess
            closeModal={() => setIsOpen(false)}
            text={text}
            desc={desc}
            link={link}
            buttonText={buttonText}
            handleButtonClick={() => closeModal()}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default RegisterTutor;
