import React from "react";
import { Link } from "react-router-dom";

const DueAssignment = ({ assignment }) => {
  
function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate(); // Get day of the month (UTC)
    const month = date.toLocaleString("default", { month: "long" }); // Get full month name
    const year = date.getUTCFullYear(); // Get the full year (UTC)

    return `${day}  ${month},  ${year}`;
  }

  return (
    <div className="flex flex-col ml-5 w-[26%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-6 pt-5 pb-8 mx-auto w-full font-medium bg-white shadow-lg max-md:px-5 max-md:mt-6">
        <div className="flex gap-3 text-xl text-teal-950">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e7cc9a4c024ae943c7b7ee4024d1ae6462b48d8b3b9e093acbeda55722c790ec?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 aspect-square w-[30px]"
          />
          <div className="my-auto font-roboto text-sm">Submit Assignment</div>
        </div>
        <div className="shrink-0 mt-3.5 h-px bg-gray-200 border border-gray-200 border-solid" />
        {assignment?(
          <>
            <div className="mt-5 text-base leading-7 text-zinc-800">
              <span className="">One assignment due:</span> <br />
              <span className="text-zinc-800">{formatDate(assignment.due_date) }</span>
            </div>
            <Link to="/studentdashboard/assignment" className="mt-5 text-sky-600 text-base hover:underline max-md:mr-0.5">
              View assignment
            </Link>
          </>
        ) : (
          <>
            <p className="mt-5 text-base leading-7 text-neutral-500">
              You have no pending assignments
            </p>
            <p className="mt-5 text-base text-zinc-400 max-md:mr-0.5">
              View assignment
            </p>
          </>
        )}
     
      </div>
    </div>
  );
};

export default DueAssignment;
