import React from "react";
import Hero from "../Hero";
import AboutSection from "../AboutSection";
import MeetTheCEO from "../MeetTheCEO";
import ServicesSection from "../ServiceSection";
import FeaturesSection from "../FeaturesSection";
import CallToAction from "../CallToAction";
import Footer from "../../Footer";

const AboutPage = () => {
  return (
    <div className="flex flex-col items-center bg-white">
      <Hero /> {/* Hero component likely already responsive */}
      <div className="px-4 w-full">
        {" "}
        {/* Add px-4 for horizontal padding */}
        <AboutSection />
        <MeetTheCEO />
        <ServicesSection />
        <FeaturesSection />
        <CallToAction />
      </div>
      <Footer /> {/* Footer component likely already responsive */}
    </div>
  );
};

export default AboutPage;
