import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import CreateProject from "../Project/CreateProject";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url";

const ManageProjects1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionCount, setSubmissionCount] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubmissions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/assignment/submissions`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          const submissions = response.data; // Assuming the backend returns an array of submissions
          // Count submissions with "submitted" status
          const count = submissions.filter(
            (submission) =>
              submission.status === "submitted" &&
              submission.project_id !== null
          ).length;
          setSubmissionCount(count);
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch assignment. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching classes";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSubmissions();
  }, []);

  return (
    <div className="flex flex-col ml-5 w-[35%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow p-5 mx-auto w-full text-lg font-medium bg-white rounded-lg shadow-lg max-md:mt-8">
        <div className="flex gap-5 text-xl text-teal-950">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3223779b01b9d4cb7b6d0dde1637d28770195313d5253b0e55a1cce79b48d200?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 aspect-square w-[25px]"
          />
          <div>Review Projects</div>
        </div>
        <div className="shrink-0 mt-5  bg-gray-200 border border-gray-200 border-solid" />

        {submissionCount > 0 ? (
          <div className="flex mt-[50%] justify-center items-center">
            <p className="text-base text-center leading-7 text-neutral-500">
              {`You have ${submissionCount} project submissions to review`}
            </p>
          </div>
        ) : (
          <>
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/bad347fc977948bf1a5d10290cdd427ee317dce8224ea3bc1b9de392a34cd0f8?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="self-center mt-16 max-w-full aspect-square w-[100px] max-md:mt-10"
            />
            <div className="mx-6 mt-6 text-center text-zinc-800 max-md:mx-2.5">
              No submitted projects yet
            </div>
          </>
        )}
        <div className="flex-grow" />
        {submissionCount > 0 ? (
          <>
            <button
              onClick={() => navigate("/teachersdashboard/project")}
              className="justify-center text-center items-center px-8 py-2.5 mb-5 text-sky-600 rounded-md border border-sky-600 border-solid max-md:px-5">
              Review all
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => setIsModalOpen(true)}
              className="justify-center text-center items-center px-8 py-2.5 mb-5 text-sky-600 rounded-md border border-sky-600 border-solid max-md:px-5">
              Create new
            </button>
          </>
        )}
      </div>
      <CustomModal isOpen={isModalOpen}>
        <CreateProject closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default ManageProjects1;
