import React from "react";
import { formatDate } from "../../../utils/convertDate";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";

const TeachersAssignmentDetails = ({assignment}) => {
  const attachments = assignment?.attachments && JSON.parse(assignment.attachments)
   const absoluteUrl = assignment.link.startsWith("https://")
     ? assignment.link
     : `https://${assignment.link}`;
  return (
    <div className="flex flex-col justify-between items-start px-8 py-5 bg-white rounded-lg shadow-lg text-neutral-500 max-md:px-5">
      <p className="mt-5 text-base">Assignment name</p>
      <p className="mt-2.5 text-lg text-zinc-800">{assignment.title}</p>
      <p className="self-stretch mt-6 text-base max-md:max-w-full">
        Assignment instruction
      </p>
      <p className="self-stretch mt-2.5 text-lg leading-7 text-zinc-800 max-md:max-w-full">
        {assignment.instructions}
      </p>
      <p className="mt-6 text-base">Link</p>
      <a href={absoluteUrl} className="mt-2.5 text-lg text-sky-600">
        {assignment.link}
      </a>
      <div className="mt-6 text-base">File</div>
      <div className="flex gap-2.5 mt-2.5 text-zinc-800">
        <div className="flex flex-col justify-center">
          <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
            <div className="flex flex-col md:w-[500px] px-1.5 py-2 text-xs bg-white rounded-md">
              {attachments && <Thumbnail fileUrl={attachments?.filePath} />}
            </div>
            <p className="self-center mt-1.5 text-xs">
              <a href={attachments?.filePath} target="_blank" rel="noreferrer">{attachments?.fileName}</a>
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center text-xs whitespace-nowrap">
          <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
            <img
              alt=""
              loading="lazy"
              srcSet={convertToPngUrl(attachments?.filePath)}
              className="self-center aspect-[1.23] w-[124px]"
            />
            <p className="mt-1.5"> {attachments?.fileName}</p>
          </div>
        </div>
      </div>
      <p className="mt-6 text-base">Assignment duration</p>
      <p className="mt-2.5 text-lg text-zinc-800">
        {formatDate(assignment.start_date)} -{" "}
        {formatDate(assignment.start_date)}
      </p>
    </div>
  );
}


export default TeachersAssignmentDetails;