import React, { useState } from "react";
import { AiTwotoneCalendar } from "react-icons/ai";
import { MdAccessTime } from "react-icons/md";
import { Link } from "react-router-dom";
import CardDetails from "./CardDetails";
import { formatDate } from "../../../utils/convertDate";

const ClassesCards = ({ cardData,}) => {
  const [activeButton, setActiveButton] = useState("All"); // Initialize with 'All'
  const [showClassDetails, setShowClassDetails] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null); // Add this state

  const handleCardClick = (card) => { // Add card parameter
    setSelectedCard(card); // Set the selected card
    setShowClassDetails(true);
  };
  const closeCard = () => {
    setShowClassDetails(false)
  }

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);

  };

  // Filter cards based on activeButton
  const filteredCards = cardData.filter((card) => {
    if (activeButton === "All") return true;
    return card.status === activeButton;
  });


 function formatTimeTo12Hour(timeString) {
   const [hours, minutes] = timeString?.split(":");
   let period = "AM";

   let hoursNum = parseInt(hours, 10);

   // Automatically determine AM/PM
   if (hoursNum >= 12) {
     period = "PM";
     if (hoursNum > 12) {
       hoursNum -= 12; // Adjust for 12-hour format
     }
   }

   return `${hoursNum}:${minutes} ${period}`;
 }



  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-full py-7 mt-8 bg-white rounded-lg shadow-md max-md:max-w-full">
        <div className="flex justify-center items-center px-2 py-2 text-sm bg-white shadow-md text-zinc-800 sm:px-5 sm:py-2.5 sm:text-base md:px-8 lg:px-16 xl:text-lg">
          <div className="md:flex justify-between items-center w-full sm:gap-2">
            <button
              className={`justify-center self-stretch px-2 py-1.5 text-xs font-semibold rounded-md ${
                activeButton === "All" ? "bg-sky-600 text-white" : ""
              } sm:px-5 sm:py-2.5 sm:text-sm md:text-base`}
              onClick={() => handleButtonClick("All")}>
              All
            </button>
            {/* ... Repeat for each button ... */}
            <button
              className={`border-none self-stretch my-auto text-xs ${
                activeButton === "Upcoming" ? "bg-[#127ABB] text-white" : ""
              } sm:text-sm md:text-base`}
              onClick={() => handleButtonClick("Upcoming")}>
              Upcoming
            </button>
            <button
              className={`border-none self-stretch my-auto text-xs ${
                activeButton === "Pending" ? "bg-[#127ABB] text-white" : ""
              } sm:text-sm md:text-base`}
              onClick={() => handleButtonClick("Pending")}>
              Pending
            </button>

            <button
              className={`border-none self-stretch my-auto text-xs ${
                activeButton === "Done" ? "bg-[#127ABB] text-white" : ""
              } sm:text-sm md:text-base`}
              onClick={() => handleButtonClick("Done")}>
              Done
            </button>

            <button
              className={`justify-center border-none self-stretch px-3 py-1.5 text-xs rounded-md ${
                activeButton === "Cancelled" ? "bg-[#127ABB] text-white" : ""
              } sm:px-5 sm:py-2.5 sm:text-sm md:text-base`}
              onClick={() => handleButtonClick("Cancelled")}>
              Cancelled
            </button>
            {/* ... */}
          </div>
        </div>

        <div className="mt-8 px-2 max-md:max-w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5  gap-5 w-full ">
          {filteredCards.map((card) => (
            <div
              onClick={() => handleCardClick(card)}
              className="flex flex-col w-full grow m-h-[250px] py-5 bg-white rounded-lg border border-solid border-zinc-400"
              key={card.id}>
              <div className="flex flex-col px-1.5 text-neutral-500">
                <div className="font-medium text-base text-zinc-800">
                  {card.class_title}
                </div>
                <div className="flex gap-2.5 mt-5">
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ade10c8ffd50be37262ab4682e5a243373076c0ec6dffee791605510158ea661?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                    className="shrink-0 w-5 aspect-square"
                  />
                  <div>{formatDate(card.class_date)}</div>
                </div>
                <div className="flex gap-2.5 mt-2.5">
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7fdb21ed0cfc03bb06bb732e3e89bc60a19f79751c230b5cadd7572e6663355a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                    className="shrink-0 w-5 aspect-square"
                  />
                  <div>{` ${formatTimeTo12Hour(
                    card.start_time
                  )} - ${formatTimeTo12Hour(card.end_time)} (UK time)`}</div>
                </div>
              </div>
              <button
                className={`justify-center text-center items-center px-5 mt-6 whitespace-nowrap ${
                  card.status === "Upcoming" || card.status === "Pending"
                    ? "bg-sky-100 text-sky-800"
                    : card.status === "Done"
                    ? "bg-green-100 text-green-800"
                    : card.status === "Cancelled"
                    ? "bg-red-100 text-red-600"
                    : ""
                }`}>
                {card.status}
              </button>
            </div>
          ))}
        </div>
      </div>
      {showClassDetails && selectedCard && (
        <CardDetails cardData={selectedCard} closeCard={closeCard} />
      )}
    </div>
  );
};

export default ClassesCards;
