import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url";
import { Circles } from "react-loader-spinner";

const ManageCohortsPage = () => {
  const [cohorts, setCohorts] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [cohortId, setCohortId] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [teacherId, setTeacherId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [students, setStudents] = useState([]);

  const [selectedCohort, setSelectedCohort] = useState(null);


  const handleCohortChange = (event) => {
    setSelectedCohort(event.target.value);
    setCohortId(event.target.value);
  };

  const handleAddStudent = (studentId) => {
    // Add student to cohort logic here
    console.log(`Add student ${studentId} to cohort ${selectedCohort}`);
  };
  const handleAddTutorToCohort = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BACKEND_URL}/cohorts/${selectedCohort}/teachers`,
        { teacherId: teacherId }
      );

      if (response.status >= 200 && response.status < 300) {
        setIsLoading(false);
        setIsSuccess(true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchCohorts = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/cohorts`);
        const data = response.data.cohorts;
        const cohortData = data && data.map((cohort) => cohort);
        setCohorts(cohortData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCohorts();
  }, []);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/users/find/teachers`);
        const data = response.data;
        const teachersData = data && data.map((teacher) => teacher);
        setTeachers(teachersData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTeachers();
  }, []);
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/users/find/students`);
        const data = response.data;
        const teachersData = data && data.map((teacher) => teacher);
        setStudents(teachersData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudents();
  }, []);

  console.log("cohort", selectedCohort, "teacher: ", teacherId)
  return (
    <div className="max-w-7xl mt-10 mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Manage Cohorts</h1>

      {/* Table 1: Attach Teacher to Cohort */}
      <div className="mt-6">
        <h3 className="flex flex-col text-center mb-4 font-roboto ">
          Attach Teachers to Cohort
        </h3>
        <TableContainer component={Paper} className="mb-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Teacher ID</TableCell>
                <TableCell>Teacher Name</TableCell>
                <TableCell>Cohort</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teachers.map((teacher) => (
                <TableRow key={teacher.id}>
                  <TableCell>{teacher.id}</TableCell>
                  <TableCell>{`${teacher.first_name} ${teacher.last_name}`}</TableCell>
                  <TableCell>
                    <Select
                      value={selectedCohort}
                      onChange={(event) => {
                        handleCohortChange(event);
                        setCohortId(event.target.value); 
                      }}>
                      {cohorts.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {new Date(option?.start_date).toLocaleString(
                            "default",
                            {
                              month: "long",
                            }
                          )}{" "}
                          {new Date(option?.start_date).getFullYear()}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setTeacherId(teacher.id);
                        handleAddTutorToCohort();
                      }}>
                      {isLoading ? (
                        <Circles size={24} /> // Show loader when loading
                      ) : isSuccess ? (
                        "Success!" // Show success message
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* Table 2: Add Student to Cohort */}
      <div className="mt-6">
        <h3 className="flex flex-col text-center mb-4 font-roboto">
          Attach Student to Cohort
        </h3>
        <TableContainer component={Paper} className="mb-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student ID</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Cohort</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student) => (
                <TableRow key={student.id}>
                  <TableCell>{student.id}</TableCell>
                  <TableCell>{`${student.first_name} ${student.last_name}`}</TableCell>
                  <TableCell>
                    <Select
                      value={selectedCohort}
                      onChange={(event) => {
                        handleCohortChange(event);
                        setCohortId(event.target.value);
                      }}>
                      {cohorts.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {new Date(option?.start_date).toLocaleString(
                            "default",
                            {
                              month: "long",
                            }
                          )}{" "}
                          {new Date(option?.start_date).getFullYear()}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddStudent(student.id)}>
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* Table 3: Add Students to Cohort in Batch */}
      <div className="mt-6">
        <h3 className="flex flex-col text-center mb-4 font-roboto">
          Add Students to Cohort in Batch
        </h3>
        <TableContainer component={Paper} className="mb-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student ID</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Cohort</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student) => (
                <TableRow key={student.id}>
                  <TableCell>{student.id}</TableCell>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>
                    <Select
                      value={selectedCohort}
                      onChange={(event) => {
                        handleCohortChange(event);
                        setCohortId(event.target.value);
                      }}>
                      {cohorts.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddStudent(student.id)}>
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ManageCohortsPage;
