import React, { useState, useEffect } from "react";
import { selectClasses } from "../../../redux/class/classSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/convertDate";
import CustomModal from "../Modal";
import ViewClassLink from "./ViewClassLink";

const UpcomingClass = ({ openModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useSelector(selectClasses);
  const upcomingOrPendingClasses =
    classes?.length > 0 &&
    classes.filter(
      (classItem) =>
        classItem.status === "Upcoming" || classItem.status === "Pending"
    );

  const newClass =
    upcomingOrPendingClasses.length > 0 &&
    upcomingOrPendingClasses.sort((a, b) => {
      const dateA = new Date(a.class_date);
      const dateB = new Date(b.class_date);
      return dateA - dateB; // Sort in ascending order of date
    });

  // Get the most recent upcoming/pending class (or null if none)
  const mostRecentClass = newClass[0] || null;

  function formatTimeTo12Hour(timeString) {
    const [hours, minutes] = timeString?.split(":");
    let period = "AM";

    let hoursNum = parseInt(hours, 10);

    // Automatically determine AM/PM
    if (hoursNum >= 12) {
      period = "PM";
      if (hoursNum > 12) {
        hoursNum -= 12; // Adjust for 12-hour format
      }
    }

    return `${hoursNum}:${minutes} ${period}`;
  }

  return (
    <div className="flex flex-col w-[26%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-6 pt-5 pb-2 mx-auto w-full bg-white shadow-lg max-md:px-5 max-md:mt-6">
        <div className="flex gap-2 items-center text-xl font-medium text-teal-950">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fef7f243bf26af5adbc48a817de8ab770b86ecdd129f76049e5bcd5cc277e18d?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 aspect-square w-[25px]"
          />
          <p className="text-sm font-medium font-roboto">Upcoming Class</p>
        </div>
        <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />

        {/* Conditional Rendering */}
        {/* Only show "No upcoming class" if there's no mostRecentClass AND NOT loading */}
        {!mostRecentClass && (
          <p className="text-base font-roboto justify-center mt-4 flex flex-col text-center text-neutral-500">
            There are no upcoming classes
          </p>
        )}

        {/* Render class details only if mostRecentClass is available */}
        {mostRecentClass && (
          <>
            <div className="flex gap-2 mt-5">
              <p className=" font-roboto text-sm text-neutral-500">Date:</p>
              <p className="font-medium font-roboto text-sm text-zinc-800">
                {formatDate(mostRecentClass?.class_date)}
              </p>
            </div>
            <div className="flex gap-2 mt-5">
              <p className="text-sm font-roboto text-neutral-500">Time:</p>
              <p className="font-medium font-roboto text-sm text-zinc-800">
                {`${formatTimeTo12Hour(mostRecentClass?.start_time)} (UK TIME)`}
              </p>
            </div>
            <div className="flex gap-1.5 mt-8 text-base font-roboto text-sky-600">
              <Link onClick={() => setIsModalOpen(true)}>View class link</Link>
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/923427ccd3b359a0366b82d29414b536e9a1434c4ff3f875a67ff5202e891320?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto aspect-[1.22] w-[11px]"
              />
            </div>
            <div className="shrink-0 h-px bg-sky-600 border border-sky-600 border-solid w-[80px]" />
          </>
        )}

        <CustomModal isOpen={isModalOpen}>
          <ViewClassLink
            closeModal={() => setIsModalOpen(false)}
            classData={mostRecentClass}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default UpcomingClass;
