import React, { useState } from "react";
import axios from "axios";
import CustomModal from "../../StudentsDashboard/Modal";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess";
import { BACKEND_URL } from "../../../services/url";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";

const AddClassRecording = ({ closeModal, onRecordLinkSubmit, classData }) => {
  const [recordLink, setRecordLink] = useState("");
  const [addingRecording, setIsAddingRecording] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openOnRecordAdded, setIsOpenOnRecordAdded] = useState(false);
  const navigate = useNavigate();

  const text = "class recording added successfully";
  const desc = "";
  const buttonText = "Go Home";
  const link = "/teachersdashboard";

  const handleSubmit = async () => {
    // onRecordLinkSubmit(recordLink)
    setIsAddingRecording(true);
    setErrorMessage("");
    try {
      const response = await axios.put(
        `${BACKEND_URL}/class/${classData.id}/recordings`,
        {
          recordingUrl: recordLink,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setIsOpenOnRecordAdded(true);
      } else {
        setErrorMessage("Failed to add class recordings.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while adding class recordings.");
      console.error(error);
    } finally {
      setIsAddingRecording(false);
    }
  };

  return (
    <div className="flex flex-col font-roboto pt-5 pb-14 bg-white rounded-lg max-w-[540px]">
      <div className="flex flex-col self-end mr-5 max-w-full text-2xl font-medium text-teal-950 w-[355px] max-md:mr-2.5">
        <img
          onClick={closeModal}
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?"
          className="self-end w-6 aspect-square"
        />
      </div>
      <h3 className="mt-5 text-center">Add class recording</h3>
      <div className="flex flex-col px-8 mt-12 w-full text-lg max-md:pl-5 max-md:mt-10 max-md:max-w-full">
        <p className="text-teal-950 max-md:max-w-full">Class recording link</p>
        <input
          name="recordLink"
          value={recordLink}
          onChange={(e) => setRecordLink(e.target.value)}
          className="shrink-0 mt-2.5 rounded-md px-5 border border-solid border-zinc-400 h-[49px] max-md:max-w-full"
        />
        <button
          onClick={handleSubmit}
          className="justify-center hover:bg-sky-800 items-center px-5 py-3 mt-10 font-medium text-white whitespace-nowrap bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full">
          {addingRecording ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20"
                width="20"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "Add"
          )}
        </button>
      </div>
      <CustomModal isOpen={openOnRecordAdded}>
        <SubmitSuccess
          closeModal={() => setIsOpenOnRecordAdded(false)}
          text={text}
          desc={desc}
          link={link}
          buttonText={buttonText}
          handleButtonClick={() => navigate("/teachersdashboard")}
        />
      </CustomModal>
    </div>
  );
};

export default AddClassRecording;
