import * as React from "react";
import { formatDate, formatTimeTo12Hour } from "../../../utils/convertDate";

const ViewClassLink = ({closeModal, classData}) => {
  return (
    <div className=" md:flex gap-0  items-start py-6 pr-5 pb-10 mt-12 pl-10 text-lg bg-white rounded-lg text-neutral-500 max-md:flex-wrap max-md:pl-5">
      <div className="flex z-10 flex-col grow shrink-0 items-start self-end mt-2 basis-0 w-fit max-md:max-w-full">
        <div className="text-base">Class title:</div>
        <h2 className="mt-1.5 font-medium text-zinc-800">
          {classData.class_title}
        </h2>
        <div className="mt-5 text-base">Class link:</div>
        <a href="https://meet.goto.com/123456789" rel="noreferrer" target="_blank" className="mt-1.5 text-sky-600">
         {classData.class_link}
        </a>
        <p className="mt-8 text-base">Duration:</p>
        <p className="mt-1.5 text-zinc-800">{formatDate(classData.class_date)}</p>
        <p className="mt-1.5 text-zinc-800">{`${formatTimeTo12Hour(classData.start_time)} (UK TIME)`}</p>
        <p className="self-stretch mt-8 text-base max-md:max-w-full">
          Description
        </p>
        <p className="self-stretch mt-1 leading-6 text-zinc-800 max-md:max-w-full">
         {classData.description}
        </p>
      </div>
      <img
        onClick={closeModal}
              alt=""
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
        className=" text-red-500 shrink-0 self-start w-6 aspect-square"
      />
    </div>
  );
}

export default ViewClassLink


