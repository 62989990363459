import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_LOGIN } from "../redux/auth/authSlice";
import { getLoginStatus, getAdminLoginStatus } from "../../src/services/authServices";
import { toast } from "react-toastify";

const useRedirectLoggedOutAdmin = (path) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggingInRef = useRef(false);

  useEffect(() => {
    const redirectLoggedOutUser = async () => {
      if (isLoggingInRef.current) return;

      isLoggingInRef.current = true;
      const isLoggedIn = await getAdminLoginStatus();
      dispatch(SET_LOGIN(isLoggedIn));
      if (!isLoggedIn) {
        toast.info("Access denied. Please log in as an admin to continue.");
        navigate(path);
      }

      isLoggingInRef.current = false;
    };
    redirectLoggedOutUser();
  }, [navigate, path, dispatch]);
};

export default useRedirectLoggedOutAdmin;
