import * as React from "react";


//type TitleProps = { tile: string }

// //const TitleComponent: React.FC<TitleProps> = (props) => {
//   return (
//     <h1 className="title">
//       {props.title}
//     </h1>
//   );
// }

const AboutUs1 = () => {
return (
  <div className="flex flex-col items-center bg-white">
    <div className="flex justify-center items-center self-stretch px-2.5 py-5 w-full text-lg bg-white shadow-sm max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 justify-between w-full max-w-[1200px] max-md:flex-wrap max-md:max-w-full">
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 max-w-full aspect-[2.27] w-[120px]"
        />
        <div className="flex gap-5 justify-between my-auto max-md:flex-wrap max-md:max-w-full">
          <div className="flex gap-5 justify-between items-center px-2.5 text-zinc-800 max-md:flex-wrap">
            <div className="self-stretch my-auto">Home</div>
            <div className="flex gap-2.5 justify-center self-stretch p-2.5 whitespace-nowrap">
              <div>Courses</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9596151c0e053c15843162624414248d2fd3f85d01284b0c2b45e56bc0d1e443?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 self-start w-5 aspect-square"
              />
            </div>
            <div className="self-stretch my-auto">About Us</div>
            <div className="self-stretch my-auto">Contact</div>
          </div>
          <div className="flex gap-5 justify-between">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f5bd9a42673413c132dd0f1139be87e095ad785b1be396bfd205013f72dd2d3f?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="shrink-0 my-auto aspect-square w-[25px]"
            />
            <div className="flex gap-4">
              <div className="justify-center px-5 py-2.5 text-sky-600 rounded-md border border-sky-600 border-solid">
                Log in
              </div>
              <div className="justify-center px-5 py-2.5 text-white whitespace-nowrap bg-sky-600 rounded-md">
                Register
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex overflow-hidden relative flex-col justify-center items-center self-stretch px-16 py-20 w-full text-white min-h-[411px] max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/330a3faa6916b1fd0a29d9999f9dc658d65d339876764dc8c0d6d23b5a4cfd59?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
        className="object-cover absolute inset-0 size-full"
      />
      <div className="flex relative flex-col mt-14 mb-7 max-w-full w-[751px] max-md:mt-10">
        <div className="self-center text-6xl font-semibold max-md:text-4xl">
          About Us
        </div>
        <div className="mt-4 text-xl font-medium leading-8 text-center max-md:max-w-full">
          We're a reputable tech company dedicated to transforming potential
          into excellence and crafting the most efficient tech products
        </div>
      </div>
    </div>
    <div className="mt-20 w-full max-w-[1174px] max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[46%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch px-5 my-auto text-lg leading-7 text-zinc-800 max-md:mt-10 max-md:max-w-full">
            <div className="text-4xl font-semibold text-teal-950 max-md:max-w-full">
              Who We Are
            </div>
            <div className="mt-4 max-md:max-w-full">
              Smart Approaches is a reputable tech company committed to
              empowering individuals with the skills and knowledge necessary
              to thrive in the 21st century. We believe in nurturing talent
              through comprehensive training and mentorship, enabling the next
              generation of tech experts to emerge, regardless of their
              academic background or prior knowledge.
            </div>
            <div className="mt-6 max-md:max-w-full">
              At Smart Approaches, we also specialize in creating dynamic,
              user-friendly, and visually appealing websites. Our team of
              experienced developers, designers, business analysts, and
              project managers is dedicated to delivering high-quality web
              solutions that meet the unique needs of our clients. We aim to
              foster innovation and provide top-notch training that empowers
              individuals to excel in the tech industry.
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[54%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8001096e1948621b0b7c32c4441fc769f55e36adc25b2e374bfc05928bdb77e1?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="grow w-full aspect-[1.23] max-md:mt-10 max-md:max-w-full"
          />
        </div>
      </div>
    </div>
    <div className="flex justify-center items-center self-stretch p-16 mt-20 w-full bg-white max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="w-full max-w-[1026px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[36%] max-md:ml-0 max-md:w-full">
            <div className="flex overflow-hidden relative flex-col pt-20 text-lg font-medium text-white aspect-square">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="object-cover absolute inset-0 size-full"
              />
              <div className="relative justify-center px-8 py-3.5 mt-72 rounded-none bg-teal-950 bg-opacity-50 max-md:px-5 max-md:mt-10">
                Dr. Aina Ibukun Isaac
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[64%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-20 py-8 mt-10 w-full bg-sky-100 border border-solid border-teal-950 max-md:px-5 max-md:max-w-full">
              <div className="ml-9 text-4xl font-semibold text-teal-950 max-md:max-w-full">
                Meet The CEO
              </div>
              <div className="mt-5 ml-9 text-lg leading-7 text-zinc-800 max-md:max-w-full">
                Dr. Aina Ibukun Isaac, the CEO of Smart Approaches is a
                globally recognized expert in business analysis, data
                analysis, and operational optimization and research, with a
                distinguished academic background, including a B.Sc., M.Sc.,
                and Ph.D. in Mathematics (specializing in optimization).
              </div>
              <div className="mt-5 ml-9 text-lg leading-7 text-zinc-800 max-md:max-w-full">
                Dr. Aina Ibukun Isaac is a global speaker, and entrepreneur
                with several awards, recognitions, and publications for his
                work in the tech and research sector.
              </div>
              <div className="flex gap-2.5 self-start mt-8 ml-9 max-md:ml-2.5">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/105ebf27a870fbbbb1fd929f11ab698b581241f01a3c31108176996dc009266a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 w-6 aspect-square"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/61cfc2678a5fce6718def1291d51c855d9c3d746fa5fd1ad51d3f12845337b4c?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 my-auto w-5 aspect-square"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6b5232e489bddd3f367938b294ddc41a1d5e63a51b62f4f21c79f21878495fd?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 w-6 aspect-square"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/109dcc6d8530c3040294ce06d80ba3205a70c2730a107b970138d49478bdc62b?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 w-6 aspect-square"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-14 text-4xl font-semibold text-teal-950 max-md:mt-10">
      What We Do
    </div>
    <div className="mt-9 text-lg leading-7 text-center text-zinc-800 w-[718px] max-md:max-w-full">
      Our services are designed to meet the diverse needs of our clients,
      ensuring that we deliver high-quality solutions.
    </div>
    <div className="px-5 mt-9 w-full max-w-[1201px] max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-4 py-4 w-full bg-white rounded-md border border-solid border-zinc-400 max-md:mt-5">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="w-full aspect-[1.56]"
            />
            <div className="mt-5 text-xl font-medium text-teal-950">
              Training and Mentorship
            </div>
            <div className="mt-4 text-base leading-6 text-zinc-800">
              Equipping individuals with outstanding soft skills and technical
              skills relevant to today’s tech landscape.
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-4 py-4 w-full bg-white rounded-md border border-solid border-zinc-400 max-md:mt-5">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="w-full aspect-[1.56]"
            />
            <div className="mt-5 text-xl font-medium text-teal-950">
              Software Development
            </div>
            <div className="mt-4 text-base leading-6 text-zinc-800">
              Building innovative and efficient tech products from
              conceptualization to execution that solve real-world problems.
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-4 py-4 w-full bg-white rounded-md border border-solid border-zinc-400 max-md:mt-5">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="w-full aspect-[1.56]"
            />
            <div className="mt-5 text-xl font-medium text-teal-950">
              IT Consultancy
            </div>
            <div className="mt-4 text-base leading-6 text-zinc-800">
              Providing expert advice and solutions to help businesses
              navigate the complex world of technology.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="justify-center px-8 py-2.5 mt-11 text-lg font-semibold text-white bg-sky-600 rounded-md max-md:px-5 max-md:mt-10">
      Contact us
    </div>
    <div className="flex justify-center items-center self-stretch px-16 py-10 mt-20 w-full bg-sky-100 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col w-full max-w-[1108px] max-md:max-w-full">
        <div className="self-center text-4xl font-semibold text-teal-950">
          Why Choose Us
        </div>
        <div className="mt-11 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-center px-5 py-8 w-full bg-white rounded-md border border-dashed border-zinc-400 max-md:mt-6">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1c5d4fb766fad2cc287b0406bd0ac16b3784d52a6b358c4f0c17737840fa1ea?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="w-14 aspect-square"
                />
                <div className="mt-5 text-xl font-semibold text-teal-950">
                  Practical Training
                </div>
                <div className="self-stretch mt-4 text-base leading-5 text-center text-zinc-800">
                  Gain practical insights and hands-on experience to apply
                  your knowledge
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-center px-5 py-8 w-full bg-white rounded-md border border-dashed border-zinc-400 max-md:mt-6">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/cde1bbdf35f6e9811304c025feade4edbef57c0dc84dba7da7213d3c49c72bb4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="w-14 aspect-square"
                />
                <div className="mt-5 text-xl font-semibold text-teal-950">
                  Expert Instruction
                </div>
                <div className="self-stretch mt-4 text-base leading-5 text-center text-zinc-800">
                  The training team consists of the best hands with ample
                  experience to guide you
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-center px-5 py-8 w-full bg-white rounded-md border border-dashed border-zinc-400 max-md:mt-6">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/09f6f336698a10786a5160df3be5afa034ce6e6bff88f03e7aaf4715fb975f53?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="w-14 aspect-square"
                />
                <div className="mt-5 text-xl font-semibold text-teal-950">
                  Lifetime Access
                </div>
                <div className="self-stretch mt-4 text-base leading-5 text-center text-zinc-800">
                  Enjoy continued access to all class materials and coaching
                  during and after the training
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-center px-5 py-8 w-full bg-white rounded-md border border-dashed border-zinc-400 max-md:mt-6">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/afcc922b2d31bf7d457336f4eab023b3f943ebeaa6acdd69e1986dcbce19e1be?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="w-14 aspect-square"
                />
                <div className="mt-5 text-xl font-semibold text-teal-950">
                  Remote Learning
                </div>
                <div className="self-stretch mt-4 text-base leading-5 text-center text-zinc-800">
                  Become a tech expert from the comfort of your home
                  conveniently
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-20 w-full max-w-[1094px] max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex overflow-hidden relative flex-col grow justify-center fill-sky-600 min-h-[405px] max-md:mt-10 max-md:max-w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff5e2e95413886ca50a985fa9111562ab2ae08edaeda8fd530d6e7ade440520d?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="object-cover absolute inset-0 size-full"
            />
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="z-10 mt-0 w-full aspect-square max-md:max-w-full"
            />
          </div>
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch px-5 my-auto text-teal-950 max-md:mt-10 max-md:max-w-full">
            <div className="text-xl max-md:max-w-full">
              Ready to Start Your Learning Journey?
            </div>
            <div className="mt-4 text-4xl font-semibold max-md:max-w-full">
              Become a Student
            </div>
            <div className="mt-4 text-2xl leading-10 text-zinc-800 max-md:max-w-full">
              Join millions of people from around the world learning together.
              Online learning is as easy and natural as chatting.
            </div>
            <div className="justify-center self-start px-8 py-2.5 mt-10 text-lg font-semibold text-sky-600 rounded-md border border-sky-600 border-solid max-md:px-5">
              Register now
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col items-center self-stretch px-5 pt-16 pb-8 mt-14 w-full bg-teal-950 max-md:mt-10 max-md:max-w-full">
      <div className="w-full max-w-[1196px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[37%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/850ae043a1a91325786723d8e23fb81363e9508051029c71c7d9976e9919a147?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/850ae043a1a91325786723d8e23fb81363e9508051029c71c7d9976e9919a147?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/850ae043a1a91325786723d8e23fb81363e9508051029c71c7d9976e9919a147?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/850ae043a1a91325786723d8e23fb81363e9508051029c71c7d9976e9919a147?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/850ae043a1a91325786723d8e23fb81363e9508051029c71c7d9976e9919a147?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/850ae043a1a91325786723d8e23fb81363e9508051029c71c7d9976e9919a147?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/850ae043a1a91325786723d8e23fb81363e9508051029c71c7d9976e9919a147?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/850ae043a1a91325786723d8e23fb81363e9508051029c71c7d9976e9919a147?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="max-w-full aspect-[1.79] w-[120px]"
              />
              <div className="mt-6 text-base leading-6 text-white">
                We are a reputable tech company committed to training and
                mentoring great minds. We equip individuals with outstanding
                soft skills and technical skills relevant to this 21st century
                and build future-forward Tech experts regardless of your
                academic background or prior knowledge.
              </div>
              <div className="flex gap-5 pr-20 mt-9 max-md:pr-5">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/58a300805b3502419af9eab5db326453068c6f1a84a3d7a4421e1d894752a7e3?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 aspect-square w-[35px]"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a2d21c1f241dd8ba0092bcd6af942f1bccf8ea6c407032678d4900f5891f65d?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 aspect-square w-[35px]"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb827421cae4188be698bdaace8340ecb6815bb512d8307f2296a8d431749908?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 aspect-square w-[35px]"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/b78f574a182cedd0fe45bebe15b32a5e35e6493d0065c23847c1885fa849682d?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 aspect-square w-[35px]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[63%] max-md:ml-0 max-md:w-full">
            <div className="max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[29%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col text-base text-white max-md:mt-10">
                    <div className="text-lg font-semibold">Courses</div>
                    <div className="mt-5">Business analysis</div>
                    <div className="mt-5">Data analysis</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-[27%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow text-base text-white max-md:mt-10">
                    <div className="text-lg font-semibold">Company</div>
                    <div className="mt-5">About Us</div>
                    <div className="mt-5">Contact Us</div>
                    <div className="mt-5">FAQ</div>
                    <div className="mt-5">Terms of service</div>
                    <div className="mt-5">Privacy policy</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-[44%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col text-base text-white max-md:mt-10">
                    <div className="text-lg font-semibold">Contact Us</div>
                    <div className="flex gap-2.5 mt-5 whitespace-nowrap">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/936caa91ce1698e7bed19e5a489580961138ae42ff258b1c89d967a786125383?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                        className="shrink-0 w-5 aspect-square"
                      />
                      <div>+447778019210</div>
                    </div>
                    <div className="flex gap-2.5 mt-5">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a5ea7399d172b6641314f3e11fc3f9facc1a26fad8a47105cc7ff5d00f0a696?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                        className="shrink-0 w-5 aspect-square"
                      />
                      <div>United Kingdom</div>
                    </div>
                    <div className="flex gap-2.5 mt-5 whitespace-nowrap">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a076ecb6a67110447ef067e23c738ce3bcfb5ab9c44a2d3ceab87d62bf2433a9?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                        className="shrink-0 w-5 aspect-square"
                      />
                      <div>admin@smartapproaches.org</div>
                    </div>
                    <div className="flex gap-2.5 mt-5">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/763dacda69b14a4a8bcf6b913c611f308763eb3ecda188ad08a8437f716fa8a2?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                        className="shrink-0 self-start w-5 aspect-square"
                      />
                      <div>
                        Daily: 10:00 Am - 5:00 Pm Monday & Holiday: Closed
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch mt-16 w-full border border-solid bg-sky-950 border-sky-950 min-h-[1px] max-md:mt-10 max-md:max-w-full" />
      <div className="mt-9 text-lg text-white">
        ©2024 Smart Approaches. All Right Reserved
      </div>
    </div>
  </div>
);
}

export default AboutUs1