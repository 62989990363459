import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CourseMaterialsList from "./CourseMaterialList";
import ManageProjects from "./ManageProjects";
import watchnow from "../../../images/watchnow.png";
import NoProject from "./NoProject";
import ExploreSection from "./ExploreSection";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import DueAssignment from "../Assignment/DueAssignment";
import MakePayment from "../MakePaymentButton";
import {
  selectCourse,
  selectPaymentDetails,
  selectPayMethod,
  selectUser,
  SET_STRIPE_INTENT_SECRET,
  SET_ORDER_ID,
  SET_TRANSACTIONID,
  SET_PROFILE,
} from "../../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAssignments,
  SET_ASSIGNMENTS,
} from "../../../redux/assignment/assignmentSlice";
import {
  SET_PROJECTS,
  selectprojects,
} from "../../../redux/project/projectSlice";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";
import Loader from "../../../components/Loader/Loader";
import { SET_CLASSES, selectClasses } from "../../../redux/class/classSlice";
import UpcomingClass from "./UpcomingClass";
import { getUser } from "../../../services/authServices";

const Dashboard = () => {
  useRedirectLoggedOutUser("/login");
  const paymentMethod = useSelector(selectPayMethod);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const projects = useSelector(selectprojects);
  const assignments = useSelector(selectAssignments);
  const classes = useSelector(selectClasses);
  const [isLoading, setIsLoading] = useState(false);
  const course = useSelector(selectCourse);
  const user = useSelector(selectUser);
  const paymentDetails = useSelector(selectPaymentDetails);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [profile, setProfile] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    async function getUserData() {
      const data = await getUser(user.email);
      setProfile(data);
      dispatch(SET_PROFILE(data?.profilePicture));
      setIsLoading(false);
    }
    getUserData();
  }, [user.email, dispatch]);

  // filter the assignment array and select only assignments with not submitted status
  const notSubmittedAssignments = Array.isArray(assignments)
    ? assignments.filter(
        (assignment) =>
          assignment.status === "Not submitted" &&
          new Date(assignment.due_date) > new Date()
      )
    : []; // Or handle the case where assignments is not an array differently

  // Calculate time differences and find the closest due date
  notSubmittedAssignments.length > 0 &&
    notSubmittedAssignments.sort((a, b) => {
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      return dateA - dateB; // Sort in ascending order of date
    });

  // Get the most recent assignment with a not status
  const mostRecentNotSubmittedAssignment = notSubmittedAssignments[0] || null;

  // check the most recent not submitted project
  const notSubmittedProjects = Array.isArray(projects)
    ? projects.filter(
        (project) =>
          project.status === "Not submitted" &&
          new Date(project.due_date) > new Date()
      )
    : [];

  // Calculate time differences and find the closest due date
  notSubmittedProjects?.length > 0 &&
    notSubmittedProjects.sort((a, b) => {
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      return dateA - dateB; // Sort in ascending order of date
    });

  // Get the most recent assignment with a not status
  const mostRecentNotSubmittedProject = notSubmittedProjects[0] || null;

  // fetch classes
  useEffect(() => {
    const fetchClasses = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/class/${course}`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        // Handle both success and potential error statuses
        if (
          response.status >= 200 &&
          response.status < 300 &&
          response.data.classes.length > 0
        ) {
          dispatch(SET_CLASSES(response.data.classes));
        } else {
          console.error(
            response.data?.error ||
              "Failed to fetch classes. Please try again.",
            error
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchClasses();
  }, [course, dispatch, error]);

  // fetch assignment
  useEffect(() => {
    const fetchAssignment = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/assignment/`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          dispatch(SET_ASSIGNMENTS(response.data));
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch assignment. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAssignment();
  }, [dispatch]);

  // fetch Projects
  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/project/`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          dispatch(SET_PROJECTS(response.data));
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch projects. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching projects";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProjects();
  }, [dispatch]);

  const makeSecondPayment = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${BACKEND_URL}/payments/make-second-payment`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (paymentMethod === "paypal") {
        // Handle PayPal response
        const approvalUrl = response.data.approvalUrl;
        if (approvalUrl) {
          dispatch(SET_ORDER_ID(response.data.id));
          dispatch(SET_TRANSACTIONID(response.data.transactionId));
          window.location.href = approvalUrl; // Redirect to PayPal for approval
        } else {
          // Handle the case where no approval URL is returned
          console.error("An error occurred while initializing PayPal payment.");
        }
      } else if (paymentMethod === "card") {
        // Handle Stripe response
        const { clientSecret, transactionId } = response.data;
        dispatch(SET_STRIPE_INTENT_SECRET(clientSecret));
        dispatch(SET_TRANSACTIONID(transactionId));
        // Navigate to Stripe checkout page
        navigate(
          `/stripe-checkout?clientSecret=${clientSecret}&transactionId=${transactionId}&paymentMode=installment`
        );
      }
    } catch (error) {
      // Handle errors here
      console.error("Error making second payment:", error);
      setError("An error occurred while processing the payment");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col py-4 md:py-10 bg-neutral-50 max-md:px-5">
      {/* {isLoading && <Loader open={isLoading} />} */}
      <div className="px-8 py-2 md:py-5 bg-sky-200 shadow-lg max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[54%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow font-medium max-md:mt-10 max-md:max-w-full">
              <h2 className="hidden md:flex md:text-3xl font-roboto text-teal-950 max-md:max-w-full">
                {user.firstName === ""
                  ? "Hello John doe"
                  : `Hello ${user.lastName}`}
              </h2>
              <p className="mt-0 md:mt-6 font-roboto text-lg text-neutral-500 max-md:max-w-full">
                {` Welcome to your ${course} course dashboard`}
              </p>
              <Link
                to="/studentdashboard/course_document"
                className="mt-4 font-roboto text-base hover:underline text-sky-600 max-md:max-w-full">
                Access course document
              </Link>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[46%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow p-5 w-full bg-white rounded-md max-md:mt-10 max-md:max-w-full">
              <p className="text-lg font-roboto font-medium text-teal-950">
                Your course progress
              </p>
              <p className="mt-5 text-sm font-roboto text-neutral-500">
                0% complete
              </p>
              <div className="flex flex-col justify-center mt-1.5 bg-gray-200">
                <div className="flex flex-col justify-center items-start bg-gray-200 rounded-md max-md:pr-5">
                  <div className="shrink-0 w-0 bg-green-800 rounded-md h-[7px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row grow mt-8 md:mt-12 max-md:mt-10 max-md:max-w-full ">
        <div className="flex grow max-md:flex-col max-md:gap-0">
          <UpcomingClass />
          <DueAssignment assignment={mostRecentNotSubmittedAssignment} />
          <Link
            className="flex flex-col ml-5 w-full md:w-[45%] max-md:ml-0 max-md:w-full"
            to="/studentDashboard/classes/videos">
            <div className="grow py-px pr-px w-full bg-sky-900 rounded-md border border-sky-900 border-solid max-md:mt-6 max-md:max-w-full">
              <img
                alt=""
                src={watchnow}
                className="w-full h-[100%] object-fill"
              />
            </div>
          </Link>
        </div>
      </div>
      {paymentDetails && !paymentDetails.paymentComplete && (
        <div
          onClick={makeSecondPayment}
          className="flex justify-center items-center bottom-4  fixed right-0 top-0  md:hidden">
          <MakePayment />
        </div>
      )}
      {paymentDetails && !paymentDetails.paymentComplete && (
        <div
          className="hidden md:flex fixed right-0 justify-end"
          onClick={makeSecondPayment}>
          <MakePayment />
        </div>
      )}

      {/* Table and manage projects
       */}
      <div className="mt-10 max-md:mt-0 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[65%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow rounded-lg shadow-lg max-md:mt-8 max-md:max-w-full">
              <CourseMaterialsList />
            </div>
          </div>

          {mostRecentNotSubmittedProject ? (
            <ManageProjects project={mostRecentNotSubmittedProject} />
          ) : (
            <NoProject />
          )}
        </div>
      </div>
      <ExploreSection />
    </div>
  );
};

export default Dashboard;
