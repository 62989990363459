import React, { useState } from "react";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../../services/url";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess";
import CustomModal from "../../StudentsDashboard/Modal";
import { useNavigate } from "react-router-dom";

const AddQuestion = ({ closeModal }) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const navigate = useNavigate()

  const [faqData, setFaqData] = useState({
    question: "",
    answer: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFaqData({
      ...faqData,
      [name]: value,
    });
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();

      // Append form fields
      formData.append("question", faqData.question);
      formData.append("answer", faqData.answer);

      const response = await axios.post(
        `${BACKEND_URL}/faqs/create`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        // Successful creation
        setSuccessText(response.data.message || "Faq added successfully!");
        setOpenSuccess(true);
        //closeModal(); // Close the modal after successful submission
      } else {
        toast.error("Failed to add faq.");
      }
    } catch (error) {
      // Handle errors
      const errorMessage =
        error.response?.data?.error || "An error occurred while adding the faq";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col px-4 w-full mt-4 pt-5 pb-16 bg-white rounded-lg shadow-lg max-w-[730px] max-md:px-5">
      {/* Heading and Close Button */}
      <div className="flex justify-between items-center mb-5 md:mb-8">
        <h2 className="text-xl font-medium  hover:bg-sky-800 text-teal-950">
          Add Question
        </h2>
        <img
          onClick={closeModal}
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 w-6 aspect-square"
        />
      </div>

      {/* Question Form */}
      <form>
        {/* Question Input */}
        <div className="flex flex-col">
          <label htmlFor="question" className="mt-2 text-lg text-teal-950">
            Question
          </label>
          <textarea
            id="question"
            name="question"
            value={faqData.question}
            onChange={handleInputChange}
            className="mt-2.5 px-5 py-3 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500"
            placeholder="Input question"
          />
        </div>

        {/* Answer Input */}
        <div className="flex flex-col mt-8">
          <label htmlFor="answer" className="text-lg text-teal-950">
            Answer
          </label>
          <textarea
            id="answer"
            name="answer"
            value={faqData.answer}
            onChange={handleInputChange}
            className="mt-2.5 px-5 py-3 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500"
            placeholder="Answer to the question"
          />
        </div>

        {/* Add Question Button */}
        <button
          onClick={handleSubmit}
          type="submit"
          className="mt-10 w-full px-5 py-3 font-medium text-white bg-sky-600 rounded-md max-md:mt-10">
          {isLoading ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20"
                width="20"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "  Add question"
          )}
        </button>
      </form>
      <CustomModal isOpen={openSuccess}>
        <SubmitSuccess
          closeModal={() => setOpenSuccess(false)}
          text={successText}
          buttonText="Go Home"
          handleButtonClick={() => window.location.reload()}
        />
      </CustomModal>
    </div>
  );
};

export default AddQuestion;
