import React from "react";
import { useSelector } from "react-redux"
import { selectProject } from "../../../redux/project/projectSlice"
import { useNavigate } from "react-router-dom";
import { convertDate, formatDate } from "../../../utils/convertDate";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";

const SubmittedProject = () => {
  useRedirectLoggedOutUser("/login")
  const project = useSelector(selectProject)
    const projectAttachment =
      project?.attachments && JSON.parse(project.attachments);
  const navigate = useNavigate()

  return (
    <div className="flex flex-col justify-center p-10 bg-neutral-50 max-md:px-5">
      <div className="flex gap-5 justify-between px-8 py-4 w-full bg-white rounded-lg shadow-lg max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="my-auto text-xl font-medium text-teal-950">Project</div>
        <div className="flex gap-5 text-base">
          <div className="my-auto text-green-800">Submitted</div>
          <p
            onClick={() =>
              navigate(`/studentdashboard/project/submitted/view/${project.id}`)
            }
            className="justify-center px-5 py-2.5 text-sky-600 rounded-md border border-sky-600 border-solid">
            View submission
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-start px-12 py-10 mt-10 bg-white rounded-lg shadow-lg text-neutral-500 max-md:px-5 max-md:max-w-full">
        <div className="text-sm">Project title:</div>
        <p className="mt-2.5 text-base font-medium text-zinc-800">
          {project.title}
        </p>
        <p className="mt-5 text-sm max-md:max-w-full">Project requirement:</p>
        <p className="mt-2.5 text-base leading-6 text-zinc-800 max-md:max-w-full">
          {project.instructions}
        </p>
        <p className="mt-5 text-sm">Attachments</p>
        {projectAttachment ? (
          <div className="flex gap-2.5 mt-2.5 text-zinc-800">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                <div className="flex flex-col px-1.5 py-2 text-xs bg-white rounded-md">
                  <div className="mt-1.5">
                    <Thumbnail fileUrl={projectAttachment?.filePath} />
                  </div>
                </div>
                <div className="self-center mt-1.5 text-xs">
                  <a href={projectAttachment?.filePath} target="_blank" rel="noreferrer">
                  {projectAttachment?.fileName}
                  </a>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex flex-col justify-center text-xs whitespace-nowrap">
              <div className="flex flex-col items-center px-2 py-2 rounded-md bg-slate-50">
                <img
                  alt=""
                  loading="lazy"
                  srcSet={convertToPngUrl(
                    "https://res.cloudinary.com/sawl/image/upload/v1723676241/sawl_assignments/2024-08-14T22-57-16.517Z-Angular2NotesForProfessionals_sxmwgq.pdf"
                  )}
                  className="aspect-[1.23] w-[124px]"
                />
                <p className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {projectAttachment?.fileName}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <p className="flex font-medium gap-2.5 mt-2.5 text-zinc-800">
            No attachment submitted for this project
          </p>
        )}
        <div className="mt-5 text-sm">Links</div>
        <p className="mt-2.5 text-base leading-6 text-sky-600">
          {project.link}
        </p>
        <p className="mt-5 text-sm">Duration</p>
        <p className="mt-2.5 text-base leading-6 text-zinc-800">
          {`${formatDate(project.start_date)} - ${formatDate(
            project.due_date
          )}`}
        </p>
      </div>
    </div>
  );
}


export default SubmittedProject;