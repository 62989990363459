import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import CreateAssignment from "./CreateAssignment";
import AssignmentCards from "./AssignmentCards";
import { BACKEND_URL } from "../../../services/url";
import { fetchSubmissionCounts } from "../../../services/assignmentService";
import NoAssignment from "./NoAssignment";
import { Box, CircularProgress } from "@mui/material";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";

const TeachersAssignment = () => {
  useRedirectLoggedOutUser("/login")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assignmentData, setAssignmentData] = useState([]);
  

  // const assignment = [
  //   {
  //     title: "User flow diagram",
  //     publishedOn: "15 May 2024",
  //     dueDate: "20 May 2024",
  //     submittedCount: 5,
  //     totalCount: 20,
  //     width: "250px",
  //   },
  //   {
  //     title: "SRS documentation",
  //     publishedOn: "09 May 2024",
  //     dueDate: "11 May 2024",
  //     submittedCount: 20,
  //     totalCount: 20,
  //     width: "100%",
  //   },
  //   {
  //     title: "User story acceptance criteria",
  //     publishedOn: "09 May 2024",
  //     dueDate: "29 May 2024",
  //     submittedCount: 0,
  //     totalCount: 20,
  //     width: "100%",
  //   },
  // ];

  // fetch Assignment Counts
  useEffect(() => {
    const fetchAssignmentCount = async () => {
      setIsLoading(true);
      try {
        const response = await fetchSubmissionCounts();

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setAssignmentData(response.data);
        } else {
          console.error(
            response.data?.error ||
              "Failed to fetch assignments. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment counts";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAssignmentCount();
  }, []);

  return (
    <>
      {isLoading && (
        <Box sx={{ display: "flex w-full", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && assignmentData.length === 0 ? (
        <NoAssignment />
      ) : (
        <div className="flex flex-col font-roboto items-center px-12 pt-11 pb-20 bg-neutral-50 max-md:px-5">
          <div className="flex gap-5 w-full max-w-[1040px] max-md:flex-wrap max-md:max-w-full">
            <h3 className="flex-auto my-auto text-xl font-medium text-teal-950">
              Assignments
            </h3>
            <button
              onClick={() => setIsModalOpen(true)}
              className="justify-center hover:bg-gray-200 px-5 py-2.5 text-base text-sky-600 rounded-md border border-sky-600 border-solid">
              Create new assignment
            </button>
          </div>
          {assignmentData &&
            assignmentData.map((assignment, index) => (
              <AssignmentCards key={index} {...assignment} />
            ))}
          <CustomModal isOpen={isModalOpen}>
            <CreateAssignment closeModal={() => setIsModalOpen(false)} />
          </CustomModal>
        </div>
      )}
    </>
  );
};

export default TeachersAssignment;
