import React, { useState } from "react";
import CustomModal from "../Modal";
import SubmitProject from "./SubmitProject";
import { useSelector } from "react-redux";
import { selectProject } from "../../../redux/project/projectSlice";
import { convertDate, formatDate } from "../../../utils/convertDate";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";

const NotSubmittedProject = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const project = useSelector(selectProject);
  const projectAttachment =
    project?.attachments && JSON.parse(project.attachments);

  return (
    <div className="flex font-roboto flex-col justify-center p-10 bg-neutral-50 max-md:px-5">
      <div className="flex gap-5 justify-between px-8 py-4 w-full bg-white rounded-lg shadow-lg max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="my-auto text-xl font-medium text-teal-950">
          {project?.title}
        </div>
        <div className="flex gap-5">
          <div className="my-auto text-xl font-medium text-zinc-800">
            <span className="text-base  text-neutral-500">Due:</span>{" "}
            <span className="text-lg text-zinc-800">
              {formatDate(project?.due_date)}
            </span>
          </div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="justify-center px-2 md:px-5 py-0 md:py-2.5 text-base text-sky-600 hover:bg-sky-200 rounded-md border border-sky-600 border-solid">
            Submit now
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center items-start px-12 py-10 mt-10 bg-white rounded-lg shadow-lg text-neutral-500 max-md:px-5 max-md:max-w-full">
        <div className="text-sm">Project title:</div>
        <div className="mt-2.5 text-base font-medium text-zinc-800">
          {project?.title}
        </div>
        <div className="mt-5 text-sm max-md:max-w-full">
          Project requirement:
        </div>
        <div className="mt-2.5 text-base leading-6 text-zinc-800 max-md:max-w-full">
          {project?.instructions}
        </div>

        <div className="mt-5 text-sm">Attachments</div>
        {projectAttachment !== undefined && projectAttachment?.length > 0 ? (
          <div className="flex gap-2.5 mt-2.5 text-zinc-800">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                <div className="flex flex-col px-1.5 py-2 text-xs bg-white rounded-md">
                  <div className="mt-1.5">
                    <Thumbnail fileUrl={projectAttachment[0].filePath} />
                  </div>
                </div>
                <div className="self-center mt-1.5 text-xs">
                  <a
                    href={projectAttachment[0].fileName}
                    target="_blank"
                    rel="noreferrer">
                    {projectAttachment?.fileName}
                  </a>
                </div>
              </div>
            </div>
            <div className="hidden lg:hidden xl:flex-col justify-center text-xs whitespace-nowrap">
              <div className="flex flex-col items-center px-2 py-2 rounded-md bg-slate-50">
                <img
                  alt=""
                  loading="lazy"
                  srcSet={convertToPngUrl(projectAttachment?.filePath)}
                  className="aspect-[1.23] w-[124px]"
                />
                <p className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {projectAttachment?.fileName}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <p className="flex font-medium gap-2.5 mt-2.5 text-zinc-800">
            No attachment set for this project
          </p>
        )}

        <div className="mt-5 text-sm">Links</div>
        <div className="mt-2.5 text-base leading-6 text-sky-600">
          {project?.link}
        </div>
        <div className="mt-5 text-sm">Duration</div>
        <div className="mt-2.5 text-base leading-6 text-zinc-800">
          {`${formatDate(project?.start_date)} - ${formatDate(
            project?.due_date
          )}`}
        </div>
      </div>
      <CustomModal isOpen={isModalOpen}>
        <SubmitProject closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default NotSubmittedProject;
