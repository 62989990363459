import React, { useState } from "react";
import CreateAssignment from "./CreateAssignment";
import CustomModal from "../../StudentsDashboard/Modal";

const NoAssignment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div className="flex justify-center items-center px-16 py-14 bg-neutral-50 max-md:px-5">
      <div className="flex flex-col items-center mt-10 max-w-full w-[466px] max-md:mt-10">
        <img alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b15556446a157cec9a86614401c4c80e756247a519484fd10a60b735c2f44848?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="w-56 max-w-full aspect-[1.2]"
        />
        <p className="self-stretch mt-12 text-xl text-zinc-800 max-md:mt-10 max-md:max-w-full">
          No assignment yet. All assignments will appear here.
        </p>
        <button onClick={() => setIsModalOpen(true)} className="justify-center px-5 py-2.5 mt-12 text-base text-white bg-sky-600 rounded-md max-md:mt-10">
          Create assignment
        </button>
      </div>
      <CustomModal isOpen={isModalOpen}>
        <CreateAssignment closeModal={() => setIsModalOpen(false)}/>
      </CustomModal>
    </div>
  );
}


export default NoAssignment;


