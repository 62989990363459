import React from "react";
import SocialIcons from "./SocialIcons";

const MeetTheCEO = () => {
  return (
    <section className="flex flex-col items-center pt-10 mx-auto bg-white max-md:px-5">
      {/* Main Content Container */}
      <div className="w-full max-w-[1026px] max-md:max-w-full">
        <div className="flex flex-col md:flex-row gap-0">
          {/* Image Section (full width on mobile) */}
          <div className="md:w-1/3 max-md:w-full">
            <figure className="relative">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff1d3fb826b8c046ece5dc39b5cfa0d3e2f5d1a0aaff813c9a7ee0d1bc6bda01?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
                alt="Dr. Aina Ibukun Isaac"
                className="w-full h-auto rounded-lg max-md:rounded-none" // Adjust image shape on mobile
              />
              <figcaption className="absolute bottom-0 left-0 w-[60%] px-4 py-3.5 rounded-tr-full bg-teal-950 bg-opacity-50 text-white rounded-b-lg max-md:rounded-none">
                Dr. Aina Ibukun Isaac
              </figcaption>
            </figure>
          </div>
          {/* Text Content (full width on mobile) */}
          <main className="md:w-2/3 left-0 max-md:w-full mt-0 md:mt-10">
            {/* Adjust margin on medium screens */}
            <article className="flex flex-col grow z-40 px-10 py-8 w-full bg-sky-100 border border-solid border-teal-950 rounded-lg max-md:px-5">
              <h1 className="text-4xl font-semibold text-teal-950">
                Meet The CEO
              </h1>
              <p className="mt-5 text-base leading-7 text-zinc-800">
                Dr. Aina Ibukun Isaac, the CEO of Smart Approaches is a globally
                recognized expert in business analysis, data analysis, and
                operational optimization and research, with a distinguished
                academic background, including a B.Sc., M.Sc., and Ph.D. in
                Mathematics (specializing in optimization).
              </p>
              <p className="mt-5 text-base leading-7 text-zinc-800">
                Dr. Aina Ibukun Isaac is a global speaker, and entrepreneur with
                several awards, recognitions, and publications for his work in
                the tech and research sector.
              </p>
              <SocialIcons />
            </article>
          </main>
        </div>
      </div>
    </section>
  );
};

export default MeetTheCEO;
