import React from "react";
import { useNavigate } from "react-router-dom";

const CallToAction = () => {
  const navigate = useNavigate();

  return (
    <section className="mt-20 mb-20 md:p-10 w-full max-w-[1094px] mx-auto max-md:mt-10 max-md:max-w-full">
      {/* Main flex container with reversed order on mobile */}
      <div className="flex flex-col-reverse md:flex-row gap-5">
        {/* Image Container (full width on mobile) */}
        <div className="flex flex-col w-full md:w-6/12 max-md:ml-0">
          <div className="flex relative flex-col grow justify-center min-h-[205px] max-md:mt-10">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff5e2e95413886ca50a985fa9111562ab2ae08edaeda8fd530d6e7ade440520d?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              alt="Background"
              className="object-cover absolute inset-0"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3392548ec378301ecb30db6a5a40f46f0e7e108c9bb1310493a3c9cffd0bd034?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              alt="Foreground"
              className="z-20 mt-0 w-[70%] aspect-square max-md:max-w-full"
            />
          </div>
        </div>
        {/* Text Content (full width on mobile) */}
        <div className="flex flex-col w-full md:w-6/12 max-md:ml-0">
          <div className="flex flex-col self-stretch px-5 my-auto text-teal-950 max-md:mt-10">
            <h3 className="text-xl">Ready to Start Your Learning Journey?</h3>
            <h2 className="mt-4 text-4xl font-semibold">Become a Student</h2>
            <p className="mt-4 text-2xl leading-10 text-zinc-800">
              Join millions of people from around the world learning together.
              Online learning is as easy and natural as chatting.
            </p>
            <button
              onClick={() => navigate("/register")}
              className="self-start px-8 py-2.5 mt-10 text-lg font-semibold hover:bg-neutral-100 text-sky-600 rounded-md border border-sky-600 border-solid max-md:px-5">
              Register now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
