import React, { useState, useEffect } from "react";
import Cohort from "./Dashboard/Cohort";
import ClassProgress from "./Dashboard/ClassProgress";
import UpcomingClass1 from "./Dashboard/UpcomingClass1";
import ReviewAssignment1 from "./Dashboard/ReviewAssignment1";
import CourseMaterials1 from "./Dashboard/CourseMaterials1";
import ManageProjects1 from "./Dashboard/ManageProjects1";
import { BACKEND_URL } from "../../services/url";
import { useDispatch, useSelector } from "react-redux";
import { SET_CLASSES } from "../../redux/class/classSlice";
import axios from "axios";
import { selectCourse } from "../../redux/auth/authSlice";
import useRedirectLoggedOutTutor from "../../customHook/useRedirectLoggedOutTutor";
import { getUser } from "../../services/authServices";
import { selectUser } from "../../redux/auth/authSlice";
import { SET_PROFILE } from "../../redux/auth/authSlice";


const TeachersDashboard = () => {
  // useRedirectLoggedOutTutor("/login");
  const user = useSelector(selectUser);
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const course = useSelector(selectCourse);

  const [profile, setProfile] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    async function getUserData() {
      const data = await getUser(user.email);
      setProfile(data);
      dispatch(SET_PROFILE(data?.profilePicture));
      setIsLoading(false);
    }
    getUserData();
  }, [user.email, dispatch]);

  // fetch classes
  useEffect(() => {
    const fetchClasses = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/class/${course}`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setClasses(response.data.classes);
          dispatch(SET_CLASSES(response.data.classes));
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch assignment. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching classes";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchClasses();
  }, []);

  return (
    <div className="flex flex-col px-4 py-8 bg-neutral-50 font-roboto max-md:px-5">
      <div className="max-md:max-w-full h-auto">
        <div className="flex gap-2 max-md:flex-col max-md:gap-0">
          <Cohort />
          <ClassProgress />
          <UpcomingClass1 />
          <ReviewAssignment1 />
        </div>
      </div>
      <div className="mt-12 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <CourseMaterials1 />
          <ManageProjects1 />
        </div>
      </div>
    </div>
  );
};

export default TeachersDashboard;
