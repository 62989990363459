import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectProjectProgress,
  selectProject,
} from "../../../redux/project/projectSlice";
import { formatCreatedAt } from "../../../utils/convertDate";
import { postReview } from "../../../services/projectService";
import CustomModal from "../../StudentsDashboard/Modal";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";

const ReviewProjectSubmission = () => {
  const [showReview, setShowReview] = useState(false);
  const progress = useSelector(selectProjectProgress);
  const project = useSelector(selectProject);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReviewed, setIsReviewed] = useState(false)
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const progressAttachment = progress?.attachments && JSON.parse(progress.attachments)
  const link = JSON.parse(progress.links);
  
  const absoluteUrl = link.startsWith("https://")
   ? link
    : `https://${link}`;
  
  const submissionId = progress?.id;
  const studentId = progress?.student_id;

  const [grade, setGrade] = useState(null);
  const [comment, setComment] = useState("");

  const handlePostReview = async (e) => {
    const convertedGrade = parseInt(grade, 10);
    e.preventDefault();

    const data = {
      grade: convertedGrade,
      comment: comment,
    };
    setIsLoading(true);
    try {
      const response = await postReview(submissionId, studentId, data);
      if (response.status >= 200 && response.status < 300) {
        setIsReviewed(false)
        setResponse(response.message);
        setIsModalOpen(true);
      } else {
        //navigate("/login");
      }
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        if (error.response.status === 401) {
          errorMessage = "Session expired. Please log in again.";
          //navigate("/login");
        } else if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        }
      }
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col font-roboto md:px-0 xl:px-6 py-14 bg-neutral-50 max-md:px-5">
      <h3 className="text-xl font-medium text-teal-950 max-md:max-w-full">
        {`${progress.studentName} Submission`}
      </h3>
      <div className="mt-9 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow justify-center items-start px-10 pt-10 pb-20 w-full bg-white border border-solid border-zinc-400 text-zinc-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
              <p className="text-lg">Document</p>
              <div className="flex gap-2.5 mt-2.5">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                    <div className="flex flex-col px-1.5 md:w-[500px] py-2 text-xs bg-white rounded-md">
                      {progressAttachment && (
                        <Thumbnail fileUrl={progressAttachment?.filePath} />
                      )}
                    </div>
                    <p className="self-center mt-1.5 text-xs">
                      <a
                        href={progressAttachment?.filePath}
                        target="_blank"
                        rel="noreferrer">
                        {progressAttachment && progressAttachment?.fileName}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="hidden xl:flex flex-col justify-center text-xs whitespace-nowrap">
                  <div className="flex flex-col items-center px-2 py-2 rounded-md bg-slate-50">
                    <img
                      alt=""
                      loading="lazy"
                      srcSet={convertToPngUrl(progressAttachment?.filePath)}
                      className="aspect-[1.23] w-[124px]"
                    />
                    <p className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {progressAttachment?.fileName}
                    </p>
                  </div>
                </div>
              </div>
              <p className="self-stretch mt-10 text-base text-neutral-500 max-md:max-w-full">
                Note
              </p>
              <p className="self-stretch mt-2.5 text-lg leading-7 max-md:max-w-full">
                {progress.notes}
              </p>
              <p className="mt-10 text-base text-neutral-500">Link</p>
              <a
                href={absoluteUrl}
                target="_blank"
                rel="noreferrer"
                className="mt-2.5 text-lg text-sky-600">
                {JSON.parse(progress.links)}
              </a>
            </div>
          </div>
          <div
            className={`flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full ${
              showReview ? "h-auto" : "h-[30px]"
            }`}>
            <div className="flex flex-col grow px-8 pt-8 pb-16 mx-auto w-full font-medium bg-white border border-solid border-zinc-400 max-md:px-5 max-md:mt-10">
              <div className="self-center text-xl text-teal-950">Review</div>
              <div className="flex gap-5 justify-between px-5 py-2 mt-8 text-lg bg-white rounded-lg border border-solid border-zinc-400 text-teal-950">
                <p className="my-auto">{progress.studentName}</p>
                {showReview ? (
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e52e7a0a4bf3cfb5b511d453a0cc8de51182b6f562067ce2473061b779b62e4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                    className="shrink-0 w-7 aspect-square"
                    onClick={() => setShowReview(!showReview)}
                  />
                ) : (
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/41f5e4b240ec9c1c66c390274ecfcb8d0fa7b45d1b18044b232dda2fa9bacd56?"
                    className="shrink-0 w-7 aspect-square"
                    onClick={() => setShowReview(!showReview)}
                  />
                )}
              </div>
              <div className="mt-6 text-base text-zinc-800">Status</div>
              <div className="flex gap-2 mt-2.5">
                <p
                  className={`justify-center px-3 py-2 text-sm whitespace-nowrap rounded-md ${
                    progress.status === "submitted"
                      ? "bg-sky-100 text-sky-600"
                      : "bg-green-100 text-green-600"
                  }`}>
                  {progress.status}
                </p>
                <p className="my-auto text-sm text-zinc-800">{`on ${formatCreatedAt(
                  progress.created_at
                )}`}</p>
              </div>
              <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />

              {showReview && (
                <>
                  <p className="mt-5 text-base text-zinc-800">Grade</p>
                  <div className="flex gap-1.5 mt-2 text-lg whitespace-nowrap text-zinc-800">
                    <input
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                      className="shrink-0 bg-white border text-center border-solid border-zinc-400 h-[35px] w-[130px]"
                    />
                    <p className="my-auto">{`/ ${project.overallGrade}`}</p>
                  </div>
                  <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
                  <p className="mt-5 text-base text-zinc-800">Comment</p>
                  <input
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="justify-center items-start px-5 py-4 mt-2.5 text-sm rounded-md border border-solid border-zinc-400 text-neutral-500"
                    placeholder="Type your comment..."
                  />
                  {isReviewed ? null : (
                    <button
                      onClick={handlePostReview}
                      className="justify-center text-cenetr hover:bg-sky-800 items-center px-5 py-2 mt-12 text-lg text-white whitespace-nowrap bg-sky-600 rounded-md max-md:mt-10">
                      {isLoading ? (
                        <div className="inset-0 flex items-center justify-center">
                          <Circles
                            height="20"
                            width="20"
                            color="#fff"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Post"
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal isOpen={isModalOpen}>
        <SubmitSuccess
          text="Project successfully reviewed"
          buttonText="Go Back"
          closeModal={() => setIsModalOpen(false)}
          handleButtonClick={() =>
            navigate(`/teachersdashboard/project/list/${project.id}`)
          }
        />
      </CustomModal>
    </div>
  );
};

export default ReviewProjectSubmission;
