import axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_URL } from "./url";

export const scheduleClass= async (scheduleData) => {
  try {
      const response = await axios.post(`${BACKEND_URL}/class/schedule`, scheduleData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
   
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.error) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};
