import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCourse } from "../../../redux/auth/authSlice";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { formatCreatedAt } from "../../../utils/convertDate";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { Menu, MenuItem, Button } from "@mui/material";
import MaterialTable from "@material-table/core";

const CourseMaterialsList = () => {
  const courseName = useSelector(selectCourse);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDocumentId, setselectedDocumentId] = useState(null);

  const handleOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setselectedDocumentId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setselectedDocumentId(null);
  };
  const handleViewDocument = async (documentId) => {
    const document = courseMaterialsDataa.find((doc) => doc.id === documentId);
    if (document) {
      const filePath = document.filePath;
      window.open(filePath, "_blank");
    }
    handleCloseMenu();
  };

  const handleDownloadDocument = async (documentId) => {
    const document = courseMaterialsDataa.find((doc) => doc.id === documentId);
    if (document) {
      const filePath = document.filePath;
      const fileName = document.fileName || "default.pdf"; // Set a default filename if missing

      try {
        const response = await axios.get(filePath, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
        });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        // Handle potential missing headers
        const contentDisposition = response.headers["content-disposition"];
        let downloadFileName = fileName;
        if (contentDisposition) {
          const existingFilename = contentDisposition
            .split("filename=")[1]
            ?.trim();
          if (existingFilename) {
            downloadFileName = existingFilename;
          }
        }

        link.href = url;
        link.download = downloadFileName;
        link.click();

        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading document:", error);
      }
    }
  };

  // State to store fetched course documents
  const [courseMaterialsDataa, setCourseMaterialsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery("(max-width:640px)");

  const columns = [
    {
      title: "File Name",
      field: "fileName",
      render: (rowData) => {
        const fileName = rowData.fileName;
        const maxLength = 15;

        if (fileName.length > maxLength) {
          const truncatedFileName = fileName.substring(0, maxLength) + "...";
          return (
            <div className="flex gap-1 text-[13px] " title={fileName}>
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e21485f22d5121af7d4802bfeee9f325113aa36def7a726e80311fbf1f2f9eed?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 w-6 aspect-square"
              />
              <p>{truncatedFileName}</p>
            </div>
          );
        } else {
          return (
            <div className="flex gap-1 text-[13px]">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e21485f22d5121af7d4802bfeee9f325113aa36def7a726e80311fbf1f2f9eed?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 w-6 aspect-square"
              />
              <p>{fileName}</p>
            </div>
          );
        }
      },
      width: 200,
    },
    {
      title: "Date Upload",
      field: "dateUploaded",
      render: (rowData) => (
        <div className="flex text-[13px] my-auto">
          {formatCreatedAt(rowData.dateUploaded)}
        </div>
      ),
      //hidden: true,
      width: 118,
    },
    {
      title: "File Size",
      field: "fileSize",
      render: (rowData) => (
        <div className="flex text-[13px] my-auto">{rowData.fileSize}</div>
      ),
      //hidden: true,
      width: 90,
    },
    {
      title: "Action",
      field: "action",
      render: (rowData) => (
        <div className="flex  text-neutral-500  ">
          <Button>
            <HiOutlineDotsHorizontal
              size={24}
              onClick={(e) => {
                handleOpenMenu(e, rowData.id);
              }}
              className="cursor-pointer"
            />

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              <MenuItem onClick={() => handleViewDocument(selectedDocumentId)}>
                View
              </MenuItem>
              {/* <MenuItem
                onClick={() => handleDownloadDocument(selectedDocumentId)}>
                Download
              </MenuItem> */}
            </Menu>
          </Button>
        </div>
      ),
      width: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    // Fetch course documents based on courseName
    const fetchCourseDocuments = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/${courseName}/documents`,
          {
            withCredentials: true,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          const dataWithIds =
            response.data.courseDocuments &&
            response.data.courseDocuments.map((item, index) => ({
              ...item,
              dateUploaded: response.data?.dateUploaded,
              id: item.id || `row-${index}`, // Add a default id if it's missing
            }));
          setCourseMaterialsData(dataWithIds);
        } else {
          throw new Error(
            response.data?.error || "Failed to fetch course documents."
          );
        }
      } catch (error) {
        setError(
          error.response?.data?.error ||
            "An error occurred while fetching course documents"
        );
        console.error("Error fetching course documents:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (courseName) {
      fetchCourseDocuments(); // Fetch documents when courseName is available
    }
  }, [courseName]);

  useEffect(() => {
    const newColumns = columns.map((col) => ({
      ...col,
      hide: isMobile && !["File Name", "Action"].includes(col.field), // Show only Student and Action on mobile
    }));
    setVisibleColumns(newColumns);
  }, [isMobile]);

  return (
    <>
      <div className="flex gap-5 font-roboto justify-between px-8 py-2.5 font-medium rounded-lg border border-solid bg-zinc-100 border-zinc-400 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <p className="text-lg font-roboto text-teal-950">Course Materials</p>
        <Link
          to="/studentdashboard/classes/course_materials"
          className="text-base text-sky-600 font-roboto">
          View all
        </Link>
      </div>

      <MaterialTable
        columns={columns}
        data={courseMaterialsDataa}
        options={{
          headerStyle: {
            backgroundColor: "#f0f0f5",
            color: "#333",
          },
          rowStyle: {
            backgroundColor: "#fff",
          },
          showTitle: false, // Disable table title
          search: false, // Disable search
          toolbar: false,
        }}
      />
    </>
  );
};

export default CourseMaterialsList;
