import axios from 'axios';
import { BACKEND_URL } from './url';
import {toast} from "react-toastify"

const PaymentService = {
  createPayPalOrder: async (orderData) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/create-paypal-order`,
        orderData,
        {
          withCredentials: true, //
        }
      );
      if (response.statusText === "OK") {
        toast.success("You have successfully initiated payment");
      }
      return response.data.id; // Return the PayPal order ID
    } catch (error) {
      throw new Error(
        error.response?.data?.error || "Failed to create PayPal order"
      );
    }
  },

  capturePayPalOrder: async (orderId,transactionId) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/payments/capture-paypal-order`,
        { orderId,transactionId },
        {
          withCredentials: true,
        }
      );

      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.error || "Failed to capture PayPal order"
      );
    }
  },

  createStripePaymentIntent: async (paymentData) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/payments/create-stripe-payment-intent`,
        paymentData,
        {
          withCredentials: true,
        }
      );
      if (response.statusText === "OK") {
        toast.success("You have successfully initiated payment");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  },

  getPaypalPaymentStatus: async (transactionId) => {
    try {
      // Make Request to Backend
      const response = await axios.get(
        `${BACKEND_URL}/payments/paypal-payment-status/${transactionId}`,
        {
          withCredentials: true, // Important for cross-origin requests
        }
      );

      return response;
    } catch (error) {
      // 3. Error Handling
      const errorMessage =
        error.response?.data?.error || "Failed to fetch payment status";
      throw new Error(errorMessage);
    }
  },

  getStripePaymentIntentStatus: async (transactionId) => {
    try {
      // Make Request to Backend
      const response = await axios.get(
        `${BACKEND_URL}/payments/payment-status/${transactionId}`,
        {
          withCredentials: true, // Important for cross-origin requests
        }
      );

      return response;
    } catch (error) {
      // 3. Error Handling
      const errorMessage =
        error.response?.data?.error || "Failed to fetch payment status";
      throw new Error(errorMessage);
    }
  },
};

export default PaymentService;
