import React from "react";
import { Link } from "react-router-dom";
import { formatCreatedAt, formatDate } from "../../../utils/convertDate";

const ProjectCard = ({id, title, start_date, due_date, status, comment, handleClick }) => {
  const statusClasses = {
    reviewed: "text-green-800 bg-green-100",
    submitted: "text-sky-600 bg-sky-100",
    "Not Submitted": "bg-gray-200",
  };

  return (
    <Link
      key={id}
      onClick={() => handleClick(id)}
      to={`/studentdashboard/project/${status}/${id}`}
      className="w-full flex grow m-h-[250px]">
      <div className="flex flex-col px-5 pt-5 pb-6 mx-auto w-full bg-white rounded-md shadow-lg max-md:mt-4">
        <h3 className="text-base font-medium text-zinc-800">{title}</h3>
        <p className="mt-5 text-sm text-neutral-500">Duration</p>
        <p className="mt-1.5 text-sm text-zinc-800">
          {`${formatDate(start_date)} - ${formatDate(due_date)}`}
        </p>
        <div className="flex gap-5 justify-between mt-5 w-full whitespace-nowrap">
          <span
            className={`px-5 py-1.5 text-sm rounded-md ${statusClasses[status]}`}>
            {status}
          </span>
          {comment !== undefined && (
            <div className="flex gap-1.5 justify-center my-auto text-base text-center text-neutral-500">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/be74bf9c9e2214ee9efa84423eb053c824da7a838c8e6176e2011f48a6516998?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
                className="shrink-0 w-5 aspect-square"
                alt=""
              />
              <span>{comment}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
