import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  project: null,
  submission: null,
  createdProject: [],
  projectProgress: null,
};

const projectslice = createSlice({
  name: "project",
  initialState,
  reducers: {
    SET_PROJECTS(state, action) {
      const projectPayload = action.payload;
      // const projectsWithStatus = projectPayload?.map((project) => ({
      //   ...project,
      //   status: "Not submitted",
      // }));
      state.projects = projectPayload;
    },
    SET_PROJECT(state, action) {
      const projectId = action.payload;
      const project = state.projects.find((a) => a.id === projectId);
      state.project = project || null;
    },

    SET_PROJECT_COUNT(state, action) {
      const projectId = action.payload;
      const project = state.projects.find((a) => a.countId === projectId);
      state.project = project || null;
    },
    UPDATE_PROJECT_STATUS(state, action) {
      const projectId = action.payload;

      // Update the status of the PROJECT in the projects array
      const updatedprojects = state.projects?.map((project) =>
        project.id === projectId ? { ...project, status: "Submitted" } : project
      );
      state.projects = updatedprojects;

      // Update the status of the currently selected assignment
      if (state.project && state.project.id === projectId) {
        state.project.status = "Submitted";
      }
    },
    SET_CREATE_PROJECT(state, action) {
      state.createdProject = action.payload;
    },
    CLEAR_CREATED_PROJECT(state, action) {
      state.createdProject = [];
    },
    SET_SUBMISSION(state, action) {
      state.submission = action.payload;
    },

    SET_PROJECT_PROGRESS(state, action) {
      state.projectProgress = action.payload;
    },
  },
});

export const {
  SET_PROJECTS,
  SET_PROJECT,
  UPDATE_PROJECT_STATUS,
  SET_SUBMISSION,
  SET_PROJECT_PROGRESS,
  SET_PROJECT_COUNT,
  SET_CREATE_PROJECT,
  CLEAR_CREATED_PROJECT
} = projectslice.actions;

export const selectprojects = (state) => state.project.projects;
export const selectProject = (state) => state.project.project;
export const selectSubmission = (state) => state.project.submission;
export const selectProjectProgress = (state) => state.project.projectProgress;
export const selectCreatedProject = (state) =>
  state.project.createdProject;

export default projectslice.reducer;
