import React, { useState, useEffect, useMemo} from "react";

function Counter({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
 

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [targetDate]);

  function calculateTimeLeft(targetDate) {
    const difference = new Date(targetDate) - new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        weeks: Math.floor(difference / (1000 * 60 * 60 * 24 * 7)),
        days: Math.floor((difference / (1000 * 60 * 60 * 24)) % 7),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      };
    }

    return timeLeft;
  }

  return (
    <div>
      {timeLeft.weeks > 0 && <span>{timeLeft.weeks} weeks, </span>}
      {timeLeft.days > 0 && <span>{timeLeft.days} days, </span>}
      <span>{timeLeft.hours} hours</span>
    </div>
  );
}




export default Counter;
