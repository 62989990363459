import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import DocumentPreview from "../../DocumentPreview";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess";
import CustomModal from "../../StudentsDashboard/Modal";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";

const Coursedetails = () => {
  useRedirectLoggedOutAdmin("/login");
  const { state } = useLocation();
  const [toggleGeneralPrice, setToggleGeneralPrice] = useState(false);
  const [openSuccess, setOPenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    id,
    courseprop,
    courseName,
    courseCode,
    startDate,
    courseDetails,
    duration,
    language,
    countrySymbol,
    courseDocuments,
    generalPrice,
    firstInstallment,
    secondInstallment,
  } = state;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    // Append form fields
    formData.append("courseName", courseName);
    formData.append("courseCode", courseCode);
    formData.append(
      "startDate",
      new Date(startDate).toISOString().split("T")[0]
    );
    formData.append("courseDetails", courseDetails);
    formData.append("language", language);
    formData.append("duration", duration);
    formData.append("generalPrice", countrySymbol + generalPrice);
    formData.append("firstInstallment", countrySymbol + firstInstallment);
    formData.append("secondInstallment", countrySymbol + secondInstallment);

    for (let i = 0; i < courseDocuments.length; i++) {
      formData.append("courseDocuments", courseDocuments[i]);
    }

    if (id) {
      setIsLoading(true);
      try {
        const response = await axios.put(
          `${BACKEND_URL}/course/${id}/update`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
            timeout: 120000,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          // Successful creation
          setSuccessMessage(
            response.data.message || "course updated successfully!"
          );
          setOPenSuccess(true);
        } else {
          toast.error("Failed to edit course.");
        }
      } catch (error) {
        setIsLoading(false);

        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while updating course";
        toast.error(errorMessage);
      }
    } else {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${BACKEND_URL}/course/create`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
            timeout: 120000,
          }
        );

        if (response.status === 201) {
          // Successful creation
          setSuccessMessage(
            response.data.message || "course added successfully!"
          );
          setOPenSuccess(true);
        } else {
          toast.error("Failed to add course.");
        }
      } catch (error) {
        // Handle errors

        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while adding course";
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSuccessButtonClick = (link) => {
    navigate(link);
    window.location.reload();
    setOPenSuccess(false);
  };

  const handleEditClick = () => {
    if (courseprop && courseprop !== undefined) {
      navigate(`/admindashboard/courses/course-form/${courseprop}`);
    } else {
      navigate("/admindashboard/courses/add_course");
    }
  };

  return (
    <div className="flex flex-col px-4 pt-10 pb-20 bg-neutral-50 max-md:px-5">
      <div className="flex flex-col px-12 pt-8 pb-20 bg-white rounded-lg shadow-lg max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 w-full max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto text-2xl font-medium text-teal-950">
            {`${state.courseName} Course (${state.courseCode})`}
          </h1>
          <div className="flex gap-4">
            <p className="my-auto text-base cursor-pointer font-light text-red-600">
              Delete course
            </p>
            <button
              onClick={handleEditClick}
              className="justify-center px-5 py-2 text-lg border-none font-medium text-white bg-sky-600 rounded-md">
              Edit course
            </button>
          </div>
        </div>
        <div className="flex gap-5 justify-between items-start mt-8 max-md:flex-wrap">
          <div className="flex flex-col items-start self-stretch text-base leading-7 text-zinc-800 max-md:max-w-full">
            <h2 className="self-stretch text-xl font-semibold text-teal-950 max-md:max-w-full">
              Course Overview
            </h2>
            <p className="self-stretch mt-5 leading-6 max-md:max-w-full">
              {/* In this course, you will learn to analyze data, identify trends,
              and make strategic decisions that drive business success.
              Practical projects and expert guidance ensure you're ready for the
              challenges of the corporate world. */}
              {state.courseDetails}
            </p>
            {/* <h2 className="mt-8 text-xl font-semibold text-teal-950">
              What You Will Learn
            </h2>
            <div className="flex gap-3.5 mt-5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Introduction to Business Analysis</p>
            </div>
            <div className="flex gap-3.5 mt-1.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Stakeholder management</p>
            </div>
            <div className="flex gap-3.5 mt-1.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Business analysis techniques</p>
            </div>
            <div className="flex gap-3.5 mt-1.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Software Development Life Cycle (SDLC)</p>
            </div>
            <div className="flex gap-3.5 mt-1.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Requirement gathering techniques</p>
            </div>
            <div className="flex gap-3.5 mt-1.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Business analysis deliverables</p>
            </div>
            <p className="mt-1.5">and much more...</p>
            <h2 className="self-stretch mt-8 text-xl font-semibold text-teal-950 max-md:max-w-full">
              Tools to Learn
            </h2>
            <div className="flex gap-5 justify-between self-stretch mt-5 max-md:flex-wrap max-md:max-w-full">
              <ul className="flex flex-row gap-1">
                <li>
                  <span className="justify-center w-2 h-2 font-extrabold p-0.5">
                    .
                  </span>
                  Microsoft Visio
                </li>
                <li>
                  <span className="justify-center w-3 h-3 font-extrabold p-0.5">
                    .
                  </span>
                  Slack
                </li>
                <li>
                  <span className="justify-center w-3 h-3 font-extrabold p-0.5">
                    .
                  </span>
                  jira and
                </li>
                <li>
                  <span className="justify-center w-3 h-3 font-extrabold p-0.5">
                    .
                  </span>
                  Confluence
                </li>
              </ul>
            </div>
            <div className="flex gap-5 justify-between self-stretch mt-5 max-md:flex-wrap max-md:max-w-full">
              <ul className="flex flex-row gap-1">
                <li>
                  <span className="justify-center w-2 h-2 font-extrabold p-0.5">
                    .
                  </span>
                  Figma
                </li>
                <li>
                  <span className="justify-center w-3 h-3 font-extrabold p-0.5">
                    .
                  </span>
                  Microsoft suite(Excel, powerpoint, word)
                </li>
                <br />
                <li>
                  <span className="justify-center w-3 h-3 font-extrabold p-0.5">
                    .
                  </span>
                  Wireframe.cc
                </li>
              </ul>
            </div>
            <h2 className="mt-8 text-xl font-semibold text-teal-950">
              Skills You Will Gain
            </h2>
            <div className="flex gap-3.5 mt-5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>CV Writing</p>
            </div>
            <div className="flex gap-3.5 mt-2.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>LinkedIn optimization</p>
            </div>
            <div className="flex gap-3.5 mt-2.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Job interview coaching</p>
            </div>
            <p className="mt-8 text-xl font-semibold text-teal-950">
              Other Benefits Includes
            </p>
            <div className="flex gap-3.5 mt-5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Access to watch recorded class videos</p>
            </div>
            <div className="flex gap-3.5 mt-2.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Job interview past questions and answers</p>
            </div>
            <div className="flex gap-3.5 mt-2.5">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>On job support</p>
            </div>
            <div className="flex gap-3.5 mt-2.5 whitespace-nowrap">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e500d64694de471cbd2f74ba419804a0937acf9550ee8d035d955b84c2085436?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 my-auto w-6 aspect-square"
              />
              <p>Mentorship</p>
            </div> */}
          </div>
          <div className="shrink-0 w-px bg-gray-200 border border-gray-200 border-solid h-[906px]" />
          <div className="flex flex-col">
            <p className="text-sm text-neutral-500">Duration</p>
            <p className="mt-2.5 text-base text-zinc-800">{state.duration}</p>
            <p className="mt-5 text-sm text-neutral-500">Language</p>
            <p className="mt-2.5 text-base text-zinc-800">{state.language}</p>
            {/* <p className="mt-5 text-sm text-neutral-500">
              Next cohort start date
            </p>
            <p className="mt-2.5 text-base text-zinc-800">
              27th February, 2024
            </p> */}
            <p className="mt-8 text-sm text-neutral-500">Course price</p>
            <div className="flex flex-col justify-between px-2 py-2.5 w-full bg-white rounded-md border border-solid border-zinc-400">
              <div className="flex gap-5 justify-between text-base text-zinc-800">
                <div>General price</div>
                <img
                  onClick={() => setToggleGeneralPrice(!toggleGeneralPrice)}
                  alt=""
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c400e2acb5e43f270a9c4b0eade809228bdc4b27fe183568a6acad4b45b278b?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 w-6 aspect-square"
                />
              </div>
              {toggleGeneralPrice && (
                <div className="flex gap-5 justify-between mt-6">
                  <div className="flex flex-col">
                    <div className="text-sm text-neutral-500">
                      One-time payment
                    </div>
                    <div className="mt-2.5 text-base font-medium text-zinc-800">
                      {` ${
                        state.countrySymbol +
                        parseFloat(state.generalPrice?.replace(/[£,]/g, ""))
                      }`}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-sm text-neutral-500">Installment</div>
                    <div className="mt-2.5 text-base font-medium text-zinc-800">
                      {` ${state.countrySymbol +  parseFloat(state.firstInstallment?.replace(/[£,]/g, ""))} + ${
                        state.countrySymbol +  parseFloat(state.secondInstallment?.replace(/[£,]/g, ""))}
                      `}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="flex flex-col justify-between px-5 py-2.5 mt-4 w-full bg-white rounded-md border border-solid border-zinc-400">
              <div className="flex gap-5 justify-between text-base text-zinc-800">
                <div>Specific country</div>
                <img
                  onClick={() => setToggleSpecificPrice(!toggleSpecificPrice)}
                  alt=""
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c400e2acb5e43f270a9c4b0eade809228bdc4b27fe183568a6acad4b45b278b?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 w-6 aspect-square"
                />
              </div>
              {toggleSpecificPrice && (
                <>
                  <div className="flex gap-1.5 pr-20 mt-5 text-sm whitespace-nowrap text-zinc-800">
                    <div className="justify-center pr-2.5 border-r border-gray-200 border-solid">
                      Nigeria
                    </div>
                    <div className="justify-center px-2.5 border-r border-gray-200 border-solid">
                      Ghana
                    </div>
                    <div className="justify-center px-2.5 border-r border-gray-200 border-solid">
                      Kenya
                    </div>
                  </div>
                  <div className="flex gap-4 justify-between mt-5">
                    <div className="flex flex-col">
                      <div className="text-sm text-neutral-500">
                        One-time payment
                      </div>
                      <div className="mt-2.5 text-base font-medium text-zinc-800">
                        ₦800,000
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-sm text-neutral-500">
                        Installment
                      </div>
                      <div className="mt-2.5 text-base font-medium text-zinc-800">
                        ₦450,000 + ₦450,000
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div> */}
            <div className="flex flex-col px-5 py-7 mt-14 w-full bg-white rounded-lg shadow-lg max-md:mt-10">
              <p className="text-lg font-semibold text-teal-950">
                Course documents
              </p>
              <div className="flex gap-5 justify-between mt-5 text-zinc-800">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col h-auto px-2 py-2 rounded bg-slate-50 w-[135px]">
                    {" "}
                    {state.previewUrl?.length > 0 &&
                      state.previewUrl?.map((url, index) => (
                        <div className="document-preview" key={index}>
                          {/* Conditional rendering (PDF vs. image) */}
                          {state.courseDocuments[index]?.type ===
                          "application/pdf" ? (
                            <div className="flex flex-col">
                              <DocumentPreview
                                fileUrl={url}
                                fileType={state.courseDocuments[index]?.type}
                              />
                              <div className="text-sm break-words">
                                {state.courseDocuments[index]?.name}
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-col">
                              <img
                                src={url}
                                className="w-[80%] h-[100px]"
                                alt="Course document preview"
                              />
                              <div>{state.courseDocuments[index]?.name}</div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <button
                onClick={handleSubmit}
                className="justify-center text-center hover:bg-sky-800 items-center px-2 border-none  py-2.5 mt-16 text-base text-white bg-sky-600 rounded-md border border-sky-600 max-md:px-5 max-md:mt-10">
                {isLoading ? (
                  <div className="inset-0 flex items-center justify-center">
                    <Circles
                      height="20" // Adjust the height
                      width="20" // Adjust the width
                      color="#fff" // Choose your desired color
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  `${state?.id ? "Update course" : "Upload course"}`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomModal isOpen={openSuccess}>
        <SubmitSuccess
          text={successMessage}
          closeModal={() => setOPenSuccess(false)}
          handleButtonClick={() => handleSuccessButtonClick("/admindashboard")}
          buttonText="Go Home"
        />
      </CustomModal>
    </div>
  );
};

export default Coursedetails;
