import React, { useState, createContext, useContext } from "react";
import AdminNav from "../AdminNav.js";
import AdminFooter from "./AdminFooter.js";
import AdminSidebar from "./AdminSideBar.js";
import SmallNav from "../TeachersDashboard/SmallNav.js";
import useRedirectLoggedOutAdmin from "../../customHook/useRedirectLoggedOutAdmin.js";
// 1. Create Context to Avoid Prop Drilling
const ActiveComponentContext = createContext(null);

function AdminLayout({ children }) {
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  useRedirectLoggedOutAdmin("/login")

  return (
    <ActiveComponentContext.Provider
      value={{ activeComponent, setActiveComponent }}>
      <div className="min-h-screen flex flex-col">
        {" "}
        {/* Full-height layout */}
        <AdminNav />
        <SmallNav />
        <main className="flex-grow flex bg-[#FAFAFA]">
          {" "}
          {/* Main content area takes up remaining space */}
          <div className="hidden md:block border-r border-[#A7AEB91A]">
            {" "}
            {/* Hide sidebar on small screens */}
            <AdminSidebar />
          </div>
          <div className="w-full px-4 mb-20 py-8 lg:px-10">
            {" "}
            {/* Content padding */}
            {children}
          </div>
        </main>
        {/* <AdminFooter /> */}
      </div>
    </ActiveComponentContext.Provider>
  );
}

// 2. Custom Hook for Easy Access to Context
function useActiveComponent() {
  const context = useContext(ActiveComponentContext);
  if (!context) {
    throw new Error("useActiveComponent must be used within an AdminLayout");
  }
  return context;
}

// 3. Example of how you'd use the hook in a child component
function ChildComponent() {
  const { activeComponent } = useActiveComponent();
  // ... use activeComponent for conditional rendering or logic ...
}

export default AdminLayout;
