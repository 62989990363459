import React, { useState } from "react";
import CourseSection from "./CourseSection";
import Cohort from "./Cohort";
import CreateTeachersLogin from "./CreateTeachersLogin";
import Upload from "./Upload";
import WebsiteInfo from "./WebsiteInfo";
import ChartSection from "./ChartSection";
import StudentsDetails from "./StudentsDetailsTable";
import CustomModal from "../../StudentsDashboard/Modal";
import StudentsDetailsTable from "./Tables";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";

const Admindashboard = () => {
  // useRedirectLoggedOutAdmin("/login")
  
  return (
    <div className="flex flex-col px-16 md:p-2 b py-8 md:mt-10 bg-neutral-50 md:px-0 min-h-screen">
      <div className="max-w-screen-xl mx-auto">
        {/* course section */}
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-9 max-md:max-w-full">
              <CourseSection />
              <ChartSection />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[31%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-9">
              <Cohort />
              <CreateTeachersLogin />
              <Upload />
              <WebsiteInfo />
            </div>
          </div>
        </div>
      </div>

      {/* <StudentsDetails table/> */}
      <StudentsDetails />
    </div>
  );
};

export default Admindashboard;
