import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCourse } from "../../../redux/auth/authSlice";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url";
import { convertToPngUrl } from "../../../utils/pdfToPng";
import download from "../../../images/download.png";

const DocumentCard = ({ fileType, fileSize, fileName, filePath, iconSrc }) => {
  return (
    <div className="flex flex-col rounded-none">
      <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50 h-[154px] w-[154px]">
        <div className="flex flex-col text-xs bg-white rounded-md">
          <a href={filePath} target="_blank" rel="noreferrer">
            <img
              alt={fileName}
              srcSet={convertToPngUrl(filePath)}
              className="w-[124px] h-[124px]"
            />
          </a>
        </div>
        <a
          href={filePath}
          target="_blank"
          rel="noreferrer"
          className="flex gap-1.5 justify-between mt-1.5 text-xs whitespace-nowrap">
          <div>{fileName}</div>
          <img
            loading="lazy"
            src={download}
            alt=""
            className="shrink-0 w-4 aspect-square"
          />
        </a>
      </div>
    </div>
  );
};

function BusinessAnalysisCourse() {
  const course = useSelector(selectCourse);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [courseDocuments, setCourseDocuments] = useState([]);

  useEffect(() => {
    // Fetch course documents based on courseName
    const fetchCourseDocuments = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/${course}/documents`,
          {
            withCredentials: true,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          const dataWithIds =
            response.data.courseDocuments && // Conditional Check
            response.data.courseDocuments.map((item) => ({
              ...item,
              dateUploaded: response.data?.dateUploaded,
              id: item.id || uuidv4(),
            }));
          setCourseDocuments(dataWithIds);
        } else {
          throw new Error(
            response.data?.error || "Failed to fetch course documents."
          );
        }
      } catch (error) {
        setError(
          error.response?.data?.error ||
            "An error occurred while fetching course documents"
        );
        console.error("Error fetching course documents:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (course) {
      fetchCourseDocuments(); // Fetch documents when courseName is available
    }
  }, [course]);

  return (
    <main className="flex flex-col px-12 py-16 bg-neutral-50 max-md:px-5">
      <header className="self-start text-2xl font-medium text-teal-950 max-md:max-w-full">
        {`${course} Course Documents`}
      </header>
      <section className="grid grid-cols-4 gap-5 mt-8 ml-0 text-zinc-800 max-md:grid-cols-1">
        {courseDocuments.map((doc, index) => (
          <DocumentCard key={index} {...doc} />
        ))}
      </section>
    </main>
  );
}

export default BusinessAnalysisCourse;
