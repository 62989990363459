import { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "./CloudinaryFilePreview.css"; // Assuming your custom CSS file
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";

const Thumbnail = ({ fileUrl, pageNumber = 1 }) => {
  const [fileType, setFileType] = useState(null);
  const [fileBlob, setFileBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const thumbnailPluginInstance = thumbnailPlugin({
    fileBlob,
    pageNumber,
  });

  const scrollModePluginInstance = scrollModePlugin();

  useEffect(() => {
    const fetchFile = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch file");
        }

        const blob = await response.blob();
        setFileBlob(blob);
        setFileType(blob.type);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFile();
  }, [fileUrl]);

  const renderFile = () => {
    if (fileType.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(fileBlob)}
          alt="file preview"
          className="w-full h-auto"
        />
      );
    } else if (fileType === "application/pdf" || fileType === "text/html") {
      return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={fileUrl}
            plugins={[thumbnailPluginInstance, scrollModePluginInstance]}
            defaultScale={1.5}
            initialPage={pageNumber - 1} // Page number is zero-based
            className="w-full h-flex-grow overflow-auto" // Updated class for full width and overflow
          />
        </Worker>
      );
    } else if (
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileType === "application/vnd.ms-excel"
    ) {
      return (
        <div className="text-center">
          Spreadsheet file preview is not supported yet.
        </div>
      );
    } else {
      return (
        <div className="text-center">File type not supported for preview.</div>
      );
    }
  };

  return (
    <div className="pdf-thumbnail-container">
      {isLoading && <div className="text-center">Loading...</div>}
      {error && <div className="text-center text-red-500">Error: {error}</div>}
      {!isLoading && !error && fileBlob && renderFile()}
    </div>
  );
};

export default Thumbnail;
