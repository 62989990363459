import React, { useState } from "react";
import TeachersNav from "./TeachersNav.js";
import TeachersSideBar from "./TeachersSideBar.js";
import TeachersFooter from "./TeachersFooter.js";
import SmallNav from "./SmallNav.js";
import useRedirectLoggedOutTutor from "../../customHook/useRedirectLoggedOutTutor.js";

const TeachersLayout = ({ children }) => {
  useRedirectLoggedOutTutor("/login")
  const [activeComponent, setActiveComponent] = useState("Dashboard");

  const handleSetActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <>
      <TeachersNav />
      <SmallNav />
      <div className="w-full h-[100%] border-l-2 border-[#A7AEB91A] flex ">
        <TeachersSideBar setActiveComponent={handleSetActiveComponent} />
        <div className="pb-24 md:pb-8 bg-[#FAFAFA] w-full flex-col pl-0 lg:px-4 ">
          {children}
          <TeachersFooter setActiveComponent={handleSetActiveComponent} />
        </div>
      </div>
    </>
  );
};
export default TeachersLayout;
