import * as React from "react";
import { convertDate } from "../../../utils/convertDate";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/convertDate";

const ManageProjects = ({ project }) => {
  return (
    <div className="flex flex-col ml-5 w-[35%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-5 pt-5 pb-10 mx-auto w-full bg-white rounded-lg shadow-lg max-md:mt-8">
        <div className="flex gap-5 text-xl font-medium text-teal-950">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/65fdab9e7d50629b2201d7faf1f82731b8c72d96911024fbf60537b342cb02d9?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 aspect-square w-[25px]"
          />
          <p className="text-base  font-roboto">Manage Projects</p>
        </div>
        <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
        <div className="text-center flex flex-col">
          <p className="mt-6">You have a pending project</p>
          <p className="mt-6 text-base text-neutral-500">Project topic:</p>
          <p className="mt-1.5 w-full text-base">{project?.title}</p>
          <p className="mt-5 text-base text-neutral-500">Due date:</p>
          <p className="mt-1.5">{formatDate(project?.due_date)}</p>
          <Link
            to="/studentdashboard/project"
            className="justify-center items-center text-center px-8 py-2.5 mt-10 text-sky-600 rounded-md border border-sky-600 border-solid">
            View details
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ManageProjects;