import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import AddQuestion from "./AddQuestion";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { Menu, MenuItem } from "@mui/material";
import EditQuestion from "./EditQuestion";
import { BACKEND_URL } from "../../../services/url";
import { toast } from "react-toastify";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";


const Faqs = () => {
  useRedirectLoggedOutAdmin("/login")
  const [activeIndex, setActiveIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [faqsData, setFaqsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const faqs = [
    {
      id: 1,
      question: "Are classes online or physical?",
      answer: "All classes hold online (virtually).",
    },
    {
      id: 2,
      question: "Are classes live sessions or prerecorded?",
      answer:
        "All classes are live sessions. However, live sessions are recorded in case you miss any class or want to go over what was taught in the class.",
    },
    {
      id: 3,
      question:
        "Do I need to have background knowledge in tech before joining the training?",
      answer:
        "No, you don’t need to have background knowledge in tech. The courses are designed to teach you all you need to know from the basics, simplified and extensive.",
    },
    {
      id: 4,
      question: "Can payment be done in installments?",
      answer: "Yes, a maximum of two installments per course.",
    },
    {
      id: 5,
      question: "Can I take two courses at a time?",
      answer:
        "Yes. The training is flexible, and classes for each course hold on different days.",
    },

    // ... add more FAQ objects
  ];

  // Fetchfaqs
  useEffect(() => {
    const fetchFaqs = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/faqs`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setFaqsData(response.data);
        } else {
          toast.error(
            response.data?.error || "Failed to fetch FAQs. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching faqs";
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFaqs();
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleOpenMenu = (event, faq) => {
    setSelectedFaq(faq); // Updates the editableFaq state directly
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditFaq = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const handleDeleteFaq = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.delete(
        `${BACKEND_URL}/faqs/delete-faq/${selectedFaq.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        // Successful updating
        toast.success(response.data.message || "Faq deleted successfully!");
        window.location.reload();
      } else {
        toast.error("Failed to delete faq.");
      }
    } catch (error) {
      // Handle errors
      const errorMessage =
        error.response?.data?.error ||
        "An error occurred while deleting the faq";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      handleCloseMenu();
    }
  };
  return (
    <div className="flex flex-col px-4 h-full py-8 bg-neutral-50">
      {/* Main Content Container */}
      <div className="max-w-screen-xl bg-white rounded-lg shadow-lg p-5 md:p-12">
        <div className="flex flex-row justify-between items-center mb-4 md:mb-8">
          <h2 className="text-2xl font-medium text-teal-950">FAQs</h2>
          <button
            onClick={() => setIsModalOpen(true)}
            className="px-4 py-2 text-base  hover:bg-sky-800 text-white bg-sky-600 rounded-md mt-2 md:mt-0">
            Add new question
          </button>
        </div>

        <div>
          {isLoading ? (
            <Box sx={{ display: "flex w-full", justifyContent: "center" }}>
              <CircularProgress /> {/* Use Material UI's CircularProgress */}
            </Box>
          ) : faqsData.length > 0 ? (
            faqsData.map((faq, index) => (
              <div
                key={index}
                className="border border-solid border-zinc-400 rounded-md p-5 mt-5">
                <button
                  type="button"
                  className="flex justify-between items-center w-full text-lg font-semibold text-teal-950 focus:outline-none">
                  <span
                    onClick={() => toggleAccordion(faq.id)}
                    className="flex items-center gap-2.5">
                    <img
                      alt=""
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/35cb39156ed3dd139d417829a6dce2cee065ef397912a5a466d609d8061067e7?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    {faq.question}
                  </span>
                  <HiOutlineDotsVertical
                    onClick={(e) => handleOpenMenu(e, faq)}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}>
                    <MenuItem onClick={handleOpenEditFaq}>Edit</MenuItem>
                    <MenuItem onClick={handleDeleteFaq}>Delete</MenuItem>
                  </Menu>
                </button>
                {/* Answer section */}
                {activeIndex === faq.id && (
                  <div className="mt-5 leading-7 text-zinc-800">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No FAQs found.</p>
          )}
        </div>
      </div>
      <CustomModal isOpen={openEditModal}>
        <EditQuestion
          closeModal={() => setOpenEditModal(false)}
          faq={selectedFaq}
        />
      </CustomModal>
      <CustomModal isOpen={isModalOpen}>
        <AddQuestion closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default Faqs;
