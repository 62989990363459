import React, { useState } from "react";
import CustomModal from "../Modal";
import SubmitAssignment from "./SubmitAssignment";
import { useSelector } from "react-redux";
import { selectAssignment } from "../../../redux/assignment/assignmentSlice";
import { formatDate } from "../../../utils/convertDate";
import DocumentPreview from "../../DocumentPreview";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";

const NotSubmitted = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const assignment = useSelector(selectAssignment);
  const selectedAssignmentAttachment =
    assignment?.attachments &&
    JSON.parse(assignment.attachments);


  const [toggleDetails, setToggleDetails] = useState(false);

  return (
    <div className="flex flex-col px-12 py-14 font-roboto bg-neutral-50 max-md:px-5">
      <h2 className="text-xl font-medium text-teal-950 max-md:max-w-full">
        {assignment.title}
      </h2>
      <div className="mt-8 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
            <div
              className={`flex flex-col justify-between items-start px-8 py-5 w-full bg-white rounded-lg shadow-lg text-neutral-500 max-md:px-5 max-md:mt-10 max-md:max-w-full ${
                toggleDetails ? "h-auto" : "h-[100px"
              }`}>
              <div className="flex gap-5 justify-between self-stretch text-xl font-medium text-teal-950 max-md:flex-wrap max-md:max-w-full">
                <h2 className="my-auto">Assignment details</h2>
                {toggleDetails ? (
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e52e7a0a4bf3cfb5b511d453a0cc8de51182b6f562067ce2473061b779b62e4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                    className="shrink-0 w-7 aspect-square"
                    onClick={() => setToggleDetails(!toggleDetails)}
                  />
                ) : (
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/41f5e4b240ec9c1c66c390274ecfcb8d0fa7b45d1b18044b232dda2fa9bacd56?"
                    className="shrink-0 w-7 aspect-square"
                    onClick={() => setToggleDetails(!toggleDetails)}
                  />
                )}
              </div>
              {toggleDetails && (
                <div className="flex flex-col justify-center items-start px-10 py-8 mt-10 bg-white rounded-lg shadow-lg max-md:px-5 max-md:max-w-full">
                  <p className="mt-5 text-base">Assignment name</p>
                  <p className="mt-2.5 text-sm text-zinc-800">
                    {assignment.title}
                  </p>
                  <p className="self-stretch mt-6 text-base max-md:max-w-full">
                    Assignment instruction
                  </p>
                  <p className="self-stretch mt-2.5 text-sm leading-7 text-zinc-800 max-md:max-w-full">
                    {assignment.instructions}
                  </p>
                  <p className="mt-6 text-base">Link</p>
                  <a
                    className="mt-2.5 text-lg text-sky-600"
                    href={assignment.link}>
                    {assignment.link}
                  </a>
                  <div className="mt-6 text-base">File</div>
                  {selectedAssignmentAttachment ? (
                    <div className="flex gap-2.5 mt-2.5 text-zinc-800">
                      <div className="flex flex-col justify-center">
                        <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                          <Thumbnail
                            fileUrl={selectedAssignmentAttachment?.filePath}
                          />
                          {selectedAssignmentAttachment && (
                            <a
                              href={selectedAssignmentAttachment?.filePath}
                              target="_blank"
                              rel="noreferrer"
                              className="self-center mt-1.5 text-xs">
                              {selectedAssignmentAttachment?.fileName}
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="hidden lg:flex flex-col justify-center text-xs whitespace-nowrap">
                        <div className="flex flex-col px-2 w-[80px] py-2 rounded-md bg-slate-50">
                          <img
                            alt=""
                            loading="lazy"
                            srcSet={convertToPngUrl(
                              selectedAssignmentAttachment?.filePath
                            )}
                            className="self-center aspect-[1.23] w-[90px]"
                          />
                          <div className="items-center">
                            <p className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                              {selectedAssignmentAttachment?.fileName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="self-center mt-1.5 text-xs">
                      No attachment for this assignment
                    </p>
                  )}
                  <p className="mt-6 text-base">Assignment duration</p>
                  <p className="mt-2.5 text-lg text-zinc-800">
                    {`${formatDate(assignment?.start_date)} - ${formatDate(
                      assignment?.due_date
                    )}`}
                  </p>
                </div>
              )}
            </div>
          </div>
          <aside className="flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col px-8 pt-8 pb-16 w-full text-base bg-white border border-solid border-zinc-400 text-neutral-500 max-md:px-5 max-md:mt-10">
              <div>Status</div>
              <div className="justify-center text-center px-5 py-2 mt-2.5 bg-gray-200 rounded-md text-zinc-800">
                Not Submitted
              </div>
              <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
              <div className="self-start mt-5">Grade</div>
              <div className="self-start mt-2 text-lg font-medium text-zinc-800">
                Nil
              </div>
              <button
                onClick={() => setIsModalOpen(true)}
                className="justify-center text-center items-center px-5 py-2 mt-8 text-lg font-medium text-white whitespace-nowrap hover:bg-sky-800 bg-sky-600 rounded-md max-md:mt-10">
                Submit
              </button>
            </div>
          </aside>
        </div>
      </div>
      <CustomModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}>
        <SubmitAssignment closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default NotSubmitted;
