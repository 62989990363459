import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TeachersAssignmentDetails from "./TeachersAssignmentDetails";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import { useParams } from "react-router-dom";
import { fetchProgress } from "../../../services/assignmentService";
import { useDispatch } from "react-redux";
import { SET_PROGRESS } from "../../../redux/assignment/assignmentSlice";
import { formatDate, formatCreatedAt } from "../../../utils/convertDate";
import useRedirectLoggedOutTutor from "../../../customHook/useRedirectLoggedOutTutor";

const TeachersAssignmentList = () => {
  useRedirectLoggedOutTutor("/login")
  const [showdetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assignment, setAssignment] = useState([]);
  const params = useParams();
  const [assignmentId, setAssignmentId] = useState(null);
  const [assignmentprogress, setAssignmentProgress] = useState([]);

  const dispatch = useDispatch();

  const handleSetProgress = (progressId) => {
    const progress = assignmentprogress.find((a) => a.id === progressId);

    dispatch(SET_PROGRESS(progress));
  };

  // fetch assignment
  useEffect(() => {
    const fetchAssignments = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/assignment/${params.name}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setAssignment(response.data);

          setAssignmentId(response.data.id);
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch assignments. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAssignments();
  }, []);

  // fetch Assignment progress list
  useEffect(() => {
    const fetchAssignmentProgress = async () => {
      setIsLoading(true);
      try {
        const response = await fetchProgress(assignmentId);

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setAssignmentProgress(response.data);
        } else {
          console.error(
            response.data?.error ||
              "Failed to fetch assignment progress. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment progress";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    if (assignmentId) {
      fetchAssignmentProgress();
    }
  }, [assignmentId]);

  return (
    <div className="flex flex-col py-14 font-medium bg-neutral-50 text-teal-950 max-md:px-5">
      <div className="text-3xl max-md:max-w-full">{assignment?.title}</div>
      <div className="flex gap-5 justify-between px-8 py-5 mt-10 text-xl bg-white rounded-lg shadow-lg max-md:flex-wrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="my-auto">Assignment details</div>
        {showdetails ? (
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e52e7a0a4bf3cfb5b511d453a0cc8de51182b6f562067ce2473061b779b62e4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 w-7 aspect-square"
            onClick={() => setShowDetails(false)}
          />
        ) : (
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/41f5e4b240ec9c1c66c390274ecfcb8d0fa7b45d1b18044b232dda2fa9bacd56?"
            className="shrink-0 w-7 aspect-square"
            onClick={() => setShowDetails(true)}
          />
        )}
      </div>
      {showdetails && <TeachersAssignmentDetails assignment={assignment} />}
      <div className="flex flex-col justify-center mt-12 text-lg bg-white rounded-lg shadow-lg text-zinc-800 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 justify-between px-8 py-5 text-xl font-medium bg-white rounded-lg text-teal-950 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="my-auto">Assignment progress</div>
          {/* ... (image) */}
        </div>

        <table className="w-full table-auto">
          <thead>
            <tr>
              {/* Table headers */}
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Student
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Assignment status
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date of submission
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Grade /100
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Comment
              </th>
              <th className="px-6 py-3 bg-zinc-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {/* First Row */}
            {assignmentprogress &&
              assignmentprogress.map((progress) => (
                <tr key={progress.id}>
                  <td className="px-6 py-4 whitespace-nowrap font-medium">
                    {progress.studentName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div
                      className={`justify-center self-stretch px-5 py-2 text-base whitespace-nowrap rounded-md ${
                        progress.status === "submitted"
                          ? "bg-sky-100 text-sky-800"
                          : " bg-green-100 text-green-800"
                      }`}>
                      {progress.status}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {formatCreatedAt(progress?.updated_at)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    {progress.grade ? progress.grade : "-"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap flex gap-1.5 justify-center self-stretch my-auto text-base text-center whitespace-nowrap text-neutral-500">
                    <img
                      alt=""
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bd402668c3e692b3f2e5f4a860866ac52b0ba8c681ba91f1ed9561c1206877d8?"
                      className="shrink-0 w-5 aspect-square"
                    />
                    <div>{progress.studentComment ? 1 : ""}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link
                      onClick={() => handleSetProgress(progress.id)}
                      to={`/teachersdashboard/assignment/${
                        progress.status === "submitted"
                          ? "review"
                          : "updateReview"
                      }/${progress.id}`}
                      className="self-stretch my-auto text-sky-600">
                      {progress.status === "submitted" ? "Review" : "View"}
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeachersAssignmentList;
