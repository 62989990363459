import React, { useState, useEffect, useMemo } from "react";
import { IoMdCheckmark } from "react-icons/io";
import CardInput from "./CardInput";
import PaypalInput from "./PaypalInput";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PaymentService from "../../services/paymentServices";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../services/url";
import LoadingModal from "../Loader/Loader";
import { SET_PAY_METHOD } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCountry1,
  selectCourse1,
  SET_STRIPE_INTENT_SECRET,
  SET_ORDER_ID,
  SET_TRANSACTIONID,
  SET_PAYMENT_OPTION,
} from "../../redux/auth/authSlice";

const Payment1 = () => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("full");
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [paymentError, setPaymentError] = useState("");
  const [transactionId, setTransactionId] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);

  const [convertedPrices, setConvertedPrices] = useState(null);
  const userCountry = useSelector(selectCountry1);
  const userCourse = useSelector(selectCourse1);
  const userCountryCode = userCountry ? userCountry.split("-")[2] : null;

  const dispatch = useDispatch();

  //fetch course by selected course
  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/${userCourse}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setCourseData(response.data);
        } else {
          throw new Error(
            response.data?.error || "Failed to fetch courses. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching courses";
        throw new Error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);

  const handlePaypalcreateOrder = async (data, actions) => {
    const orderData = {
      selectedCourse: userCourse,
      paymentMode: selectedPaymentOption,
    };

    setIsCreatingOrder(true);
    dispatch(SET_PAYMENT_OPTION(selectedPaymentOption));
    try {
      const response = await axios.post(
        `${BACKEND_URL}/payments/create-paypal-order`,
        orderData,
        {
          withCredentials: true,
        }
      );
      const approvalUrl = response.data.approvalUrl;
      if (approvalUrl) {
        dispatch(SET_ORDER_ID(response.data.id));
        dispatch(SET_TRANSACTIONID(response.data.transactionId));
        window.location.href = approvalUrl;
      }
    } catch (error) {
      // Handle errors here
      console.error("Error creating PayPal order:", error);
      setError("An error occurred while creating the order");
    } finally {
      setIsCreatingOrder(false);
    }
  };

  const getConvertedPrice = (type) => {
    if (!convertedPrices || !courseData) return "Loading prices...";
    switch (type) {
      case "full":
        return convertedPrices
          ? `${convertedPrices.currencySymbol} ${convertedPrices.generalPrice}`
          : courseData.generalPrice;
      case "installment":
        return convertedPrices
          ? `${convertedPrices.currencySymbol} ${convertedPrices.firstInstallment} + ${convertedPrices.secondInstallment}`
          : ` ${courseData.firstInstallment} +${courseData.secondInstallment}`;
      case "fullPayment":
        return convertedPrices
          ? `${convertedPrices.currencySymbol} ${convertedPrices.generalPrice}`
          : `${courseData.firstInstallment}`;
      case "installmentPayment":
        return convertedPrices
          ? `${convertedPrices.currencySymbol} ${convertedPrices.firstInstallment}`
          : `${courseData.firstInstallment}`;
      default:
        return "";
    }
  };

  const navigate = useNavigate();

  // 2. Update useEffect to depend on userCountryCode
  useEffect(() => {
    const fetchConvertedPrices = async () => {
      if (!userCountryCode) {
        setConvertedPrices(null);
        return;
      }

      try {
        const currencyCode =
          {
            userCountryCode,
          }[userCountryCode] || userCountryCode;

        const response = await axios.get(
          `https://v6.exchangerate-api.com/v6/697f19c6e379b904e69d0fc3/latest/GBP`
        );

        const rates = response.data.conversion_rates;

        // Check if exchange rate is available
        if (!rates[currencyCode]) {
          console.error(
            `Exchange rate not found for currency: ${currencyCode}`
          );
          setConvertedPrices({
            generalPrice: courseData.generalPrice,
            firstInstallment: courseData.firstInstallment,
            secondInstallment: courseData.secondInstallment,
          }); // Or set to some default values
          return;
        }

        // Get the currency symbol for the user's country
        const currencySymbol =
          (
            Intl.NumberFormat(undefined, {
              style: "currency",
              currency: currencyCode,
            })
              .formatToParts(0)
              .find((part) => part.type === "currency") || {}
          ).value || "";

        // Convert prices (remove currency symbol and replace ',' with '.')
        const generalPriceNum = parseFloat(
          courseData.generalPrice.replace(/[£,]/g, "")
        );
        const firstInstallmentNum = parseFloat(
          courseData.firstInstallment.replace(/[£,]/g, "")
        );
        const secondInstallmentNum = parseFloat(
          courseData.secondInstallment.replace(/[£,]/g, "")
        );

        setConvertedPrices({
          generalPrice: isNaN(generalPriceNum)
            ? "N/A"
            : (generalPriceNum * rates[currencyCode]).toLocaleString(
                undefined,
                { maximumFractionDigits: 2 }
              ),
          firstInstallment: isNaN(firstInstallmentNum)
            ? "N/A"
            : (firstInstallmentNum * rates[currencyCode]).toLocaleString(
                undefined,
                { maximumFractionDigits: 2 }
              ),
          secondInstallment: isNaN(secondInstallmentNum)
            ? "N/A"
            : (secondInstallmentNum * rates[currencyCode]).toLocaleString(
                undefined,
                { maximumFractionDigits: 2 }
              ),
          currencySymbol: currencySymbol,
        });
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchConvertedPrices();
  }, [userCountryCode, courseData]);

  const initiateStripePayment = async () => {
    const paymentPayload = {
      selectedCourse: userCourse,
      paymentMode: selectedPaymentOption,
      selectedCurrencyCode: userCountryCode,
    };

    setIsCreatingOrder(true);
    try {
      const response = await PaymentService.createStripePaymentIntent(
        paymentPayload
      );
      if (response.status >= 200 && response.status <= 300) {
        dispatch(SET_STRIPE_INTENT_SECRET(response.data.clientSecret));
        dispatch(SET_TRANSACTIONID(response.data.transactionId));
        dispatch(SET_STRIPE_INTENT_SECRET(response.data.clientSecret));
        dispatch(SET_PAYMENT_OPTION(selectedPaymentOption));

        navigate(
          `/stripe-checkout?clientSecret=${response.data.clientSecret}&transactionId=${response.data.transactionId}` // Redirect to a Stripe component
        );
      } else {
        toast.error("Failed to initiate payment");
        navigate("/register/make-payment");
      }
    } catch (error) {
      setPaymentError(
        error.message || "An error occurred while initiating payment."
      );
    } finally {
      setIsCreatingOrder(false);
    }
  };

  const handlePaymentOptionChange = (event) => {
    setSelectedPaymentOption(event.target.value);
    dispatch(SET_PAYMENT_OPTION(event.target.value));
  };
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
    dispatch(SET_PAY_METHOD(event.target.value));
  };

  return (
    <div className="bg-white py-4 px-4 md:px-0 font-roboto mx-auto">
      {isCreatingOrder && <LoadingModal open={isCreatingOrder} />}
      <div className="flex gap-2 max-md:flex-col max-md:gap-0">
        <div className="hidden md:flex flex-col w-[25%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-8 pt-3 pb-20 w-full text-4xl font-semibold text-center text-white bg-sky-700 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <img
              alt=""
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="max-w-full self-start aspect-[1.79] w-[100px]"
            />
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f3b904cb929535952bc83df3ef1639d68f9698df3685464647dc5b04716d2acc?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="self-end mt-14 max-w-full aspect-square w-[450px] max-md:mt-10 max-md:mr-1.5"
            />
            <p className="self-center font-roboto px-2 text-center text-white text-base mt-7 w-[390px]">
              Your journey to success starts here!
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between ml-5 w-[60%] max-md:ml-0 max-md:w-full">
          {/* one time payment and intallment section */}
          <div className="md:hidden">
            <img
              alt=""
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4f7fd1ce03229b90766b13107442acfca52acc5e7c88ea5ea5830a0fbc6569e?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="max-w-full aspect-[1.79] w-[100px]"
            />
          </div>
          <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
            <p className="text-3xl font-medium text-teal-950 max-md:max-w-full">
              Payment options
            </p>
            <p className="mt-3.5 text-xl text-teal-950 max-md:max-w-full">
              Choose your preferred payment plan
            </p>
            <div className="mt-7 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                {/* One time payment section */}
                <div className="flex flex-col text-center w-full md:w-6/12">
                  {/* full Payment Option */}
                  <div
                    className={`flex flex-col grow px-6 pt-4 pb-11 mx-auto w-full bg-white rounded-md border border-solid ${
                      selectedPaymentOption === "installment"
                        ? "border-sky-600"
                        : "border-zinc-400"
                    } shadow-xl text-zinc-800 max-md:pl-5 max-md:mt-4 transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer`}
                    onClick={() => setSelectedPaymentOption("full")}>
                    <div className="flex flex-col items-center">
                      <input
                        type="checkbox"
                        id="oneTimePayment"
                        name="paymentOption"
                        value="full"
                        checked={selectedPaymentOption === "full"}
                        onChange={handlePaymentOptionChange}
                        className="shrink-0 self-end border h-[26px] w-[54px]"
                      />{" "}
                      <div className="mt-4 text-xl font-semibold text-sky-700">
                        Full payment
                      </div>
                      <div className="mt-3 text-base font-medium text-sky-600">
                        {getConvertedPrice("full")}
                      </div>
                      <div className="shrink-0 mt-5 max-w-full h-px border border-solid bg-zinc-400 border-zinc-400 w-[301px] max-md:mr-1.5" />
                      <p className="mt-5 text-lg leading-6 max-md:mr-2">
                        Save 8.3% when you make the full payment at once
                      </p>
                      <ul className="list-disc list-inside mt-6">
                        {" "}
                        <li className="flex gap-2.5 text-base max-md:mr-2">
                          <IoMdCheckmark className="shrink-0 self-start text-sky-600 w-6 aspect-square" />
                          Immediate access to the course
                        </li>
                        <li className="flex gap-2.5 mt-4 text-base max-md:mr-2">
                          <IoMdCheckmark className="shrink-0 self-start text-sky-600 w-6 aspect-square" />
                          Unrestricted access to all course materials.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Installment payment section */}
                <div className="flex mt-6 md:mt-0 flex-col w-full md:w-6/12">
                  {/* Installment Payment Option */}
                  <div
                    className={`flex flex-col grow px-6 pt-4 pb-11 mx-auto w-full bg-white rounded-md border border-solid ${
                      selectedPaymentOption === "installment"
                        ? "border-sky-600"
                        : "border-zinc-400"
                    } shadow-sm text-zinc-800 max-md:pl-5 max-md:mt-4 transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer`}
                    onClick={() => setSelectedPaymentOption("installment")}>
                    <input
                      type="checkbox"
                      id="installment"
                      name="paymentOption"
                      value="installment"
                      checked={selectedPaymentOption === "installment"}
                      onChange={handlePaymentOptionChange}
                      className="shrink-0 self-end border  outline-none border-sky-600 border-solid h-[26px] w-[54px]"
                    />{" "}
                    {/* Placeholder for a check icon */}
                    <div className="self-center mt-4 text-xl font-medium">
                      Installment payment
                    </div>
                    <div className="self-center mt-3 text-base font-medium text-sky-600">
                      {getConvertedPrice("installment")}
                    </div>
                    <div className="shrink-0 mt-5 max-w-full h-px border border-solid bg-zinc-400 border-zinc-400 w-[301px] max-md:mr-1.5" />
                    <p className="mt-5 text-base leading-6 max-md:mr-2">
                      {convertedPrices
                        ? ` Spread your payment into two installments. Pay ${convertedPrices.firstInstallment} now
                      and ${convertedPrices.secondInstallment} within one month`
                        : ` Spread your payment into two installments. Pay ${courseData.firstInstallment} now
                      and ${courseData.secondInstallment} within one month`}
                    </p>
                    {/* Installment Benefits (List) */}
                    <ul className="list-disc list-inside mt-6">
                      <li className="flex gap-2.5 text-base max-md:mr-2">
                        <IoMdCheckmark className="shrink-0 self-start text-sky-600 w-6 aspect-square" />
                        Temporary access to the course until payment is
                        completed
                      </li>
                      <li className="flex gap-2.5 mt-4 text-base max-md:mr-2">
                        <IoMdCheckmark className="shrink-0 self-start text-sky-600 w-6 aspect-square" />
                        Temporary access to course materials until payment is
                        completed
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-12 text-2xl font-medium text-teal-950 max-md:mt-10 max-md:max-w-full">
              Payment method
            </p>
            <div className="flex gap-5 justify-between mt-6 font-medium whitespace-nowrap text-zinc-800 max-md:flex-wrap max-md:max-w-full">
              <p className="my-auto text-xl">Price</p>
              <p className="text-2xl">
                {selectedPaymentOption === "full"
                  ? getConvertedPrice("fullPayment")
                  : getConvertedPrice("installmentPayment")}
              </p>
            </div>
            <div className="shrink-0 mt-3.5 h-px bg-gray-200 border border-gray-200 border-solid max-md:max-w-full" />
            <div className="flex gap-5 justify-between pr-9 mt-5 max-w-full w-[395px] max-md:pr-5">
              <div className="flex gap-5 my-auto rounded-lg">
                <input
                  type="radio"
                  id="paypal"
                  name="paymemtMethod"
                  value="paypal"
                  checked={paymentMethod === "paypal"}
                  onChange={handlePaymentMethodChange}
                  className="shrink-0 my-auto w-5 h-5  border-sky-600 border-solid rounded-full border stroke-[1px]"
                />
                <img
                  alt=""
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b0ea96bd4b31596d0bca805715f54d4e96bb948d9ba3d9695a5320b52907eb4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 max-w-full aspect-[4.17] w-[100px]"
                />
              </div>
              <div className="flex gap-5 rounded-lg">
                <div className="flex flex-col justify-center my-auto">
                  <div className="flex flex-col justify-center p-1 rounded-full border border-sky-600 border-solid">
                    <input
                      type="radio"
                      id="card"
                      name="paymemtMethod"
                      value="card"
                      checked={paymentMethod === "card"}
                      onChange={handlePaymentMethodChange}
                      className="shrink-0 w-3 h-3 border-none rounded-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center font-medium tracking-normal text-white">
                  <div className="flex flex-col w-full bg-sky-600 rounded-sm">
                    <div className="text-xs font-semibold">John Doe</div>
                    <p className="mt-2.5 text-xs text-center">
                      **** **** **** 1234
                    </p>
                    <div className="flex gap-5 justify-between mt-2 text-xs text-right whitespace-nowrap">
                      <p className="justify-self-end">02/24</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {paymentMethod === "card" ? (
              <CardInput onPayment={initiateStripePayment} />
            ) : (
              <PaypalInput onPayment={handlePaypalcreateOrder} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment1;
