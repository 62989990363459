import React, { useState, useEffect } from "react";
import ProgressCircle from "./ProgressCircle";
import CustomModal from "../../StudentsDashboard/Modal";
import AddCourse from "../courses//AddCourse";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import CourseProgressCircle from "./ProgressCircle";
import SetCohort from "./SetCohort";

const CourseSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const navigateAddCourse = () => {
    navigate("/admindashboard/courses/add_course");
  };

  const courseScores = [
    { name: "Business analysis", score: "4/12" },
    { name: "Data analysis", score: "6/12" }, // Example with half-filled border
  ];

  const handleEditCourse = (courseName) => {
    navigate(`/admindashboard/courses/course-form/${courseName}`); // Replace with your actual route
  };

  //fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/fetch-courses`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setCourses(response.data);
        } else {
          console.error(
            response.data?.error || "Failed to fetch courses. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching courses";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);

  return (
    <div className="flex flex-col font-roboto p-4 py-8 bg-white rounded-lg shadow-lg max-md:px-5">
      {/* Heading and Add Button */}
      <div className="flex gap-5 pb-4 justify-between w-full max-md:flex-wrap max-md:max-w-full">
        <p className="text-xl font-medium text-teal-950">Courses</p>
        <div className="flex gap-1.5 my-auto text-base text-sky-600">
          <img
            onClick={navigateAddCourse}
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e91fc10f4ec26b9de1bef674f44c233b002d6c64d8cd43a66b0e6c8c08f5ba15?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 my-auto w-4 aspect-square"
          />
          <p className="text-sky-600">Add new course</p>
        </div>
        <div className="flex gap-1.5 my-auto text-base text-sky-600">
          <img
            onClick={() => setIsModalOpen(true)}
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e91fc10f4ec26b9de1bef674f44c233b002d6c64d8cd43a66b0e6c8c08f5ba15?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 my-auto w-4 aspect-square"
          />
          <p className="text-sky-600">Set new cohort</p>
        </div>
      </div>

      {/* Courses Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-5">
        {courses.length > 0 &&
          courses?.map((course, index) => (
            <div
              key={index}
              className="bg-white rounded-lg border border-solid border-zinc-400 p-5 py-8 md:p-2.5 flex flex-col md:flex-row items-center">
              {/* Course Information */}
              <div className="flex flex-col w-full md:w-9/12">
                <h4 className="text-lg text-zinc-800">{course?.courseName}</h4>
                <div className="mt-5 flex items-center text-sm text-sky-600">
                  <p
                    onClick={() => handleEditCourse(course?.courseName)}
                    className="hover:underline border-none text-sky-600 pointer-cursor">
                    Edit course
                  </p>
                  <div className="hidden md:block ml-auto">
                    {/* <ProgressCircle value={course.score} /> */}
                  </div>
                </div>
              </div>

              {/* Image and Progress Circle */}
              <div className="flex flex-col md:flex-row items-center w-full md:w-3/12 mt-4 md:mt-0">
                <CourseProgressCircle
                  courseName={course?.courseName}
                  courseDuration={course.duration}
                  startDate={course?.startDate}
                />
              </div>
            </div>
          ))}
      </div>
      <CustomModal isOpen={isModalOpen}>
        <SetCohort closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default CourseSection;
