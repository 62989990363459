import React, { useState, useEffect } from "react";
import CountdownItem from "./CountdownItems";
import Counter from "./Counter";
import { BACKEND_URL } from "../../services/url";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SET_PAYMENT_DETAILS } from "../../redux/auth/authSlice";

const CountdownTimer = () => {
  const timerData = [
    { value: 3, label: "Weeks" },
    { value: 2, label: "Days" },
    { value: 11, label: "Hours" },
  ];

  const dispatch = useDispatch();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // fetch course data
  useEffect(() => {
    const fetchPaymentDetails = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/payments/payment-complete`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setPaymentDetails(response.data);
          dispatch(SET_PAYMENT_DETAILS(response.data));
        } else {
          console.error(
            response.data?.error ||
              "Failed to payment details. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching payment details";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPaymentDetails();
  }, []);

  return (
    <>
      {paymentDetails && !paymentDetails.paymentComplete && (
        <div className="flex md:mr-40 gap-2.5 flex-row justify-center">
          <p className="flex flex-row m-auto text-zinc-600">
            second payment due on:
          </p>
          <section className="flex gap-2.5 justify-between p-2.5 text-red-600 whitespace-nowrap bg-white border border-solid border-zinc-400">
            {/* {timerData.map((item, index) => (
            <CountdownItem key={index} value={item.value} label={item.label} />
          ))} */}
            <Counter targetDate={paymentDetails?.startDate} />
          </section>
        </div>
      )}
    </>
  );
};

export default CountdownTimer;
