import React from "react";

const UserDetails = ({ user, course, paymentDetails, handlePayment }) => {
  return (
    <div className="mt-14 font-roboto max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[58%] max-md:ml-0 max-md:w-full">
          {/* Personal Details Table */}
          <table className="min-w-full h-auto divide-y divide-gray-200 bg-white rounded-lg shadow-lg max-md:px-5 max-md:mt-10">
            <tbody>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">Name:</th>
                <td className="text-zinc-800 py-3 px-6">
                  {user.firstName} {user.lastName}
                </td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">Email:</th>
                <td className="text-zinc-800 py-3 px-6">{user.email}</td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">
                  Phone Number:
                </th>
                <td className="text-zinc-800 py-3 px-6">{user.phoneNumber}</td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">Course:</th>
                <td className="text-zinc-800 py-3 px-6">{course}</td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 py-3 px-6">
                  Tutor ID:
                </th>
                <td className="text-zinc-800 py-3 px-6">{`0123${user.studentId}`}</td>{" "}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;