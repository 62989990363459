import React from "react";

const ContactInfo = () => {
  const contactDetails = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/997a3b5316a29ac55a164175d3448c07f4f94c77247423f03cc55bab4e5392f8?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
      title: "Mobile number",
      value: "+447778019210",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/95bfd027b95c9e22c248d7e2511a1134ecc52b177835da3e1634cd5b5b900f6a?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
      title: "Email address",
      value: "admin@smartapproaches.org",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/20197d90fe7ceb0ab1175b8b40ef45dae026b48fb7ab729bc69990e557be52c5?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
      title: "Address",
      value: "Sunderland",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0d3437b8c439995329ddae97c1816e1032646eb46afae3ca2f91774f726b0f8b?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
      title: "Opening days",
      value: "Monday - Friday: 9:00am to 5:00pm",
    },
  ];

  return (
    <div className="flex flex-col px-5 py-6 w-full bg-sky-100 rounded-md border border-solid border-teal-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <h2 className="text-2xl font-medium text-teal-950 max-md:max-w-full">
        Contact Info
      </h2>
      <div className="shrink-0 mt-3 w-11 bg-sky-600 h-[3px]" />
      {contactDetails.map((detail, index) => (
        <React.Fragment key={index}>
          <div className="flex gap-5 justify-between self-start mt-8">
            <img
              loading="lazy"
              src={detail.icon}
              alt=""
              className="shrink-0 my-auto aspect-square w-[30px]"
            />
            <div className="flex flex-col">
              <div className="text-base text-neutral-500">{detail.title}</div>
              <div className="mt-2 text-lg text-zinc-800">{detail.value}</div>
            </div>
          </div>
          {index < contactDetails.length - 1 && (
            <div className="shrink-0 mt-7 h-px border border-solid border-zinc-400 max-md:max-w-full" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ContactInfo;
