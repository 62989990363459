import React from "react";
import { useNavigate } from "react-router-dom";

const WebsiteInfo = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col px-5 pt-8 pb-8 mt-10 w-full bg-white rounded-lg shadow-lg">
      <h2 className="self-center text-xl font-medium text-teal-950">
        Website Information
      </h2>
      <div className="flex gap-5 justify-between mt-8 w-full whitespace-nowrap">
        <div className="flex gap-2.5 my-auto text-base text-zinc-800">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8496e9d659bc16c4e08c47366e24557bed9b702b2a1a66fc94bb4c373940ca68?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 self-start w-6 aspect-square"
          />
          <p>FAQ</p>
        </div>
        <button
          onClick={() => navigate("/admindashboard/updates/FAQS")}
          className="justify-center px-5 py-2.5 text-sm text-sky-600 bg-white rounded border border-sky-600 border-solid">
          Update
        </button>
      </div>
      <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
      <div className="flex gap-5 justify-between mt-5 w-full whitespace-nowrap">
        <div className="flex gap-2.5 my-auto text-base text-zinc-800">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c3ffc877f7fa6b22f25925c2516bd6fc631ba99957b4ee4c53c0b3e23daf2f2?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 self-start w-6 aspect-square"
          />
          <p>Testimonials</p>
        </div>
        <button
          onClick={() => navigate("/admindashboard/updates/Testimonials")}
          className="justify-center px-5 py-2.5 text-sm text-sky-600 bg-white rounded border border-sky-600 border-solid">
          Update
        </button>
      </div>
      <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
      <div className="flex gap-5 justify-between mt-5 w-full">
        <div className="flex gap-2.5 text-base my-auto text-zinc-800">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/96755b3bcf85197157a1166a826faa69bcf83befb9857febdd78ab2b1d02f295?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 self-start w-6 aspect-square"
          />
          <p>About Us</p>
        </div>
        <button
          onClick={() => navigate("/admindashboard/updates/About us")}
          className="justify-center px-5 py-2.5 text-sm text-sky-600 whitespace-nowrap bg-white rounded border border-sky-600 border-solid">
          Update
        </button>
      </div>
    </div>
  );
};

export default WebsiteInfo;
