import React from "react";
import ServiceCard from "./ServiceCard";
import { useNavigate } from "react-router-dom";

const services = [
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/fbddd185250e336225fa8df383fd04cd0c17538d76ebdc494d8ed67717d9d88a?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
    title: "Training and Mentorship",
    description:
      "Equipping individuals with outstanding soft skills and technical skills relevant to today's tech landscape.",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/ab6654e1dddba5a354d93d0085d86272b59d3714137e7db5b618f1ecc229eb74?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
    title: "Software Development",
    description:
      "Building innovative and efficient tech products from conceptualization to execution that solve real-world problems.",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2c276a48485fd5bd897a9b9e92eb727fc41da9e9067de09451055149fe4d375e?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
    title: "IT Consultancy",
    description:
      "Providing expert advice and solutions to help businesses navigate the complex world of technology.",
  },
];

const ServicesSection = () => {
  const navigate = useNavigate();
  return (
      <section className="mt-20 w-full max-w-[1201px] mx-auto max-md:max-w-full">
      {/* Main heading */}
      <h2 className="text-4xl flex flex-row justify-center font-semibold text-teal-950">
        What We Do
      </h2>
      {/* Description with responsive max-width */}
      <p className="mt-9 flex flex-row mx-auto text-lg leading-7 text-center text-zinc-800 max-w-xl md:max-w-2xl"> 
        Our services are designed to meet the diverse needs of our clients,
        ensuring that we deliver high-quality solutions.
      </p>

      {/* Service Cards Container */}
      <div className="px-5 mt-9 w-full max-w-[1201px] max-md:max-w-full">
        {/* Use a grid for responsive layout, stack on smaller screens */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5"> 
          {services.map((service, index) => (
            <div key={index} className="w-full">
              <ServiceCard {...service} />
            </div>
          ))}
        </div>
      </div>

      {/* Contact Us Button */}
      <button
        onClick={() => navigate("/contact")}
        className="px-8 py-2.5 flex mx-auto mt-11 hover:bg-sky-800 text-lg font-semibold text-white bg-sky-600 rounded-md max-md:px-5 max-md:mt-10"
      >
        Contact us
      </button>
    </section>
  );
};

export default ServicesSection;
