import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import { BACKEND_URL } from "../../../services/url";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess";
import CustomModal from "../../StudentsDashboard/Modal";
import { useNavigate } from "react-router-dom";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";

const AddVideo = ({ closeModal }) => {
  useRedirectLoggedOutAdmin("/login");
  const [selectedFile, setSelectedFile] = useState(null);
  const [videoData, setVideoData] = useState({
    courseName: "",
    videoTitle: "",
    video: null,
  });
  const navigate = useNavigate()
  const [videoUrl, setVideoUrl] = useState("");
  const [videos, setVideos] = useState([]); // To store fetched videos
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const handleSucessClick = () => {
    window.location.reload();
    closeModal();
  };
  const [courseOptions, setCourseOptions] = useState([]);

  //const courseOptions = ["Business analysis", "Data analysis"];
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVideoData({
      ...videoData,
      [name]: value,
    });
  };

  //fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/fetch-courses`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setCourseOptions(response.data);
        } else {
          toast.error(
            response.data?.error || "Failed to fetch videos. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching videos";
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("courseName", videoData.courseName);
    formData.append("videoTitle", videoData.videoTitle);
    formData.append("video", selectedFile); // Adds video file to formData

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BACKEND_URL}/videos/add-video`, // api endpoints
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (response.status === 201) {
        setSuccessText(response.data.message); // Update video URL to display it
        setOpenSuccess(true);

      } else {
        toast.error("Failed to upload video. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("An error occurred while uploading the video.");
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <div className="flex flex-col pt-5 pr-5 pb-16 pl-10 text-lg bg-white rounded-lg max-w-[730px] text-teal-950 max-md:pl-5">
      {/* Heading and Close Button */}
      <div className="flex justify-between items-center mb-5 md:mb-8">
        <h2 className="text-xl font-medium text-teal-950">Add Video</h2>

        <img
          onClick={closeModal}
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 w-6 aspect-square"
        />
      </div>

      {/* Video Form */}
      <form>
        {/* Course Selection */}
        <div className="flex flex-col">
          <label htmlFor="courseSelect" className="mt-8 max-md:max-w-full">
            Course
          </label>
          <select
            id="courseSelect"
            name="courseName"
            value={videoData.courseName}
            onChange={handleInputChange}
            className="mt-2.5 appearance-none px-5 py-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7'/%3E%3C/svg%3E")`,
              backgroundSize: "20px",
              backgroundPosition: "right 10px center",
              backgroundRepeat: "no-repeat",
              paddingRight: "30px",
            }}>
            <option value="">Select course</option>
            {courseOptions.map((option, index) => (
              <option key={index} value={option.courseName}>
                {option.courseName}
              </option>
            ))}
          </select>
        </div>

        {/* Video Title Input */}
        <div className="flex flex-col mt-8">
          <label htmlFor="videoTitle" className="max-md:max-w-full">
            Video Title
          </label>
          <input
            type="text"
            id="videoTitle"
            name="videoTitle"
            value={videoData.videoTitle}
            onChange={handleInputChange}
            placeholder="Give the video a title"
            className="mt-2.5 px-5 py-4 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
          />
        </div>

        {/* Video File Upload */}
        <div className="flex flex-col mt-8">
          <label htmlFor="videoFile" className="max-md:max-w-full">
            Video File
          </label>
          <input
            type="file"
            id="videoFile"
            onChange={handleFileChange}
            accept="video/*" // Only allow video files
            className="mt-2.5 file:mr-5 file:px-5 file:py-2.5 file:rounded-md file:border-0 file:text-sm file:font-medium file:text-sky-600 file:bg-sky-50 hover:file:cursor-pointer hover:file:bg-sky-100"
          />
        </div>

        {/* Add Video Button */}
        <button
          onClick={handleUpload}
          type="submit"
          className="mt-10 w-full px-5 py-3 font-medium text-white bg-sky-600 rounded-md max-md:mt-10">
          {isLoading ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20" // Adjust the height
                width="20" // Adjust the width
                color="#fff" // Choose your desired color
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "Add video"
          )}
        </button>
      </form>
      {/* <p>{uploadProgress}</p> */}
      <CustomModal isOpen={openSuccess}>
        <SubmitSuccess
          closeModal={() => setOpenSuccess(false)}
          text={successText}
          buttonText="Go Back"
          handleButtonClick={() => navigate("/admindashboard/learn")}
        />
      </CustomModal>
    </div>
  );
};

export default AddVideo;
