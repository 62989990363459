import React, { useState } from "react";
import { Link, useNavigate, useHistory } from "react-router-dom";
import { toast } from "react-toastify"
import { SET_CREATE_PROJECT } from "../../../redux/project/projectSlice";
import { useDispatch } from "react-redux";
const initialData = {
  title: "",
  instructions: "",
  overallGrade: "",
  link: "",
  startDate: "",
  dueDate: "",
};

const CreateProject = ({ closeModal }) => {
  const [formData, setFormData] = useState(initialData);
  const { title, instructions, overallGrade, link, startDate, dueDate } =
    formData;
  const [selectedFile, setSelectedFile] = useState(null);
 const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePreview = (e) => {
    e.preventDefault()

     const parsedStartDate = new Date(startDate);
    const parsedDueDate = new Date(dueDate);

    const today = new Date();
    if (!title || !instructions || !startDate || !dueDate || !overallGrade) {
      return toast.error("All fields are required.");
    }
    if (isNaN(parsedStartDate) || isNaN(parsedDueDate)) {
      return toast.error("Invalid date format. Please use YYYY-MM-DD.");
    }

    // if (parsedStartDate < today) {
    //   return toast.error("Start date cannot be in the past.");
    // }

    if (parsedDueDate <= parsedStartDate) {
      return toast.error("Due date must be after the start date.");
    }
const projectData = {
      ...formData,
      attachments: selectedFile,
    };
     dispatch(SET_CREATE_PROJECT(projectData));
    navigate("/teachersdashboard/project/preview", {
      state: { ...formData, attachments: selectedFile },
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <form className="flex flex-col font-roboto px-10 pt-5 pb-12  text-base bg-white rounded-lg max-w-[730px] text-teal-950 max-md:pl-5">
      <div className="flex gap-5 items-start text-xl font-medium max-md:flex-wrap max-md:max-w-full">
        <h3 className="flex-auto mt-5">Create Project</h3>
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?"
          className="shrink-0 w-6 aspect-square"
          onClick={closeModal}
        />
      </div>
      <label className="mt-7 max-md:max-w-full">Project name</label>
      <input
        name="title"
        value={title}
        onChange={handleInputChange}
        className="justify-center items-start px-5 py-2 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
        placeholder="Name the project"
      />
      <label className="mt-5 max-md:max-w-full">Project instructions</label>
      <textarea
        name="instructions"
        value={instructions}
        onChange={handleInputChange}
        className="justify-center items-start px-5 pt-4 pb-7 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
        placeholder="Add instructions for the project"
      />
      <div className="flex gap-5 self-start mt-2.5 text-base text-sky-600">
        <div className="flex gap-2 px-2.5 py-1.5 w-[35%] rounded-md border border-sky-600 border-solid">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd0420fa3b3a3321e24507a7e9f53410c57c9a438fb45c6791452159e53e6994?"
            className="shrink-0 w-4 aspect-square"
          />
          <input
            type="file"
            name="attachment"
            onChange={handleFileChange}
            className="w-[100%] border-none text-sm outline-none bg-white"
          />
        </div>
        <div className="flex gap-2 px-2.5 w-[35%] py-1.5 bg-white rounded-md border border-sky-600 border-solid">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/45e5e20e52b5c12abb49088bd6aab1809f48837c95e896a946a3b413acdf9e60?"
            className="shrink-0 w-5 aspect-square"
          />
          <input
            name="link"
            value={link}
            onChange={handleInputChange}
            type="link"
            className="w-full h-full border-none outline-none"
          />
        </div>
      </div>
      <label className="mt-5 max-md:max-w-full">Overall overallGrade</label>
      <input
        name="overallGrade"
        value={overallGrade}
        onChange={handleInputChange}
        className="justify-center items-start px-5 py-2 mt-2.5 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 max-md:max-w-full"
        placeholder="Set the overall score"
      />
      <label className="mt-8 max-md:max-w-full">Project duration</label>
      <div className="flex gap-5 mt-2.5 text-base whitespace-nowrap text-neutral-500 max-md:flex-wrap">
        <div className="flex gap-2.5 justify-between px-5 py-2 rounded-md border border-solid border-zinc-400">
          <label>From</label>
          <input
            name="startDate"
            value={startDate}
            onChange={handleInputChange}
            type="date"
            className="w-full h-full outline-none border-none"
          />
        </div>
        <div className="flex gap-2.5 justify-between px-5 py-2 rounded-md border border-solid border-zinc-400">
          <label>To</label>
          <input
            name="dueDate"
            value={dueDate}
            onChange={handleInputChange}
            type="date"
            className="w-full h-full outline-none border-none"
          />
        </div>
      </div>
      <button
        onClick={handlePreview}
        className="justify-center hover:bg-sky-800 items-center px-5 py-2 mt-12 font-medium text-white bg-sky-600 rounded-md max-md:mt-10 max-md:max-w-full">
        Preview project
      </button>
    </form>
  );
};

export default CreateProject;
