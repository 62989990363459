import React, { useState } from "react";
import { selectProjectProgress, selectProject } from "../../../redux/project/projectSlice";
import { useSelector } from "react-redux";
import { formatCreatedAt, formatDate } from "../../../utils/convertDate";
import Thumbnail from "../../Thumbnails";
import { convertToPngUrl } from "../../../utils/pdfToPng";

const SubmissionDetails = () => {
  const [grade, setGrade] = useState(80);
  const [showReview, setShowReview] = useState(false)
  const reviewedDetails = useSelector(selectProjectProgress)
  const reviewAttachment = reviewedDetails?.attachments && JSON.parse(reviewedDetails.attachments)
  const project = useSelector(selectProject)
  const projectAttachments = project?.attachments && JSON.parse(project.attachments)
  const link = reviewedDetails?.links
   const absoluteUrl = link.startsWith("https://")
     ? link
     : `https://${link}`;

  return (
    <div className="flex flex-col font-roboto px-12 pt-14 pb-8 bg-neutral-50 max-md:px-5">
      <h3 className="text-xl font-medium text-teal-950 max-md:max-w-full">
        {` ${reviewedDetails.studentName} Submission`}
      </h3>
      <div className="mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-start px-10 pt-10 pb-20 w-full bg-white border border-solid border-zinc-400 text-zinc-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
              <p className="text-lg">Document</p>
              <div className="flex gap-2.5 mt-2.5">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                    <div className="flex flex-col px-1.5 py-2 text-xs bg-white rounded-md">
                      {reviewAttachment && (
                        <Thumbnail fileUrl={reviewAttachment?.filePath} />
                      )}
                    </div>
                    <p className="self-center mt-1.5 text-xs">
                      <a href={reviewAttachment?.filePath} target="_blank" rel="noreferrer">
                        {reviewAttachment && reviewAttachment?.fileName}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="hidden lg:flex flex-col justify-center text-xs whitespace-nowrap">
                  <div className="flex flex-col px-2 w-[80px] py-2 rounded-md bg-slate-50">
                    <img
                      alt=""
                      loading="lazy"
                      srcSet={convertToPngUrl(reviewAttachment?.filePath)}
                      className="self-center aspect-[1.23] w-[90px]"
                    />
                    <div className="items-center">
                      <p className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {reviewAttachment?.fileName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="self-stretch mt-10 text-base text-neutral-500 max-md:max-w-full">
                {reviewedDetails.notes}
              </p>
              <div className="mt-10 text-base text-neutral-500">Link</div>
              <a
                href={absoluteUrl}
                className="mt-2.5 text-lg text-sky-600">
                {reviewedDetails.links
                  ? JSON.parse(reviewedDetails.links)
                  : null}
              </a>
            </div>
          </div>
          <div
            className={`flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full ${
              showReview ? "h-auto" : "h-[30px]"
            }`}>
            <div className="flex flex-col grow px-8 pt-8 pb-16 mx-auto w-full bg-white border border-solid border-zinc-400 max-md:px-5 max-md:mt-10">
              <p className="self-center text-xl font-medium text-teal-950">
                Review
              </p>
              <div className="flex gap-5 justify-between px-5 py-2 mt-8 text-xs bg-white rounded-lg border border-solid border-zinc-400 text-teal-950">
                <p className="my-auto">{reviewedDetails.studentName}</p>
                {showReview ? (
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e52e7a0a4bf3cfb5b511d453a0cc8de51182b6f562067ce2473061b779b62e4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                    className="shrink-0 w-7 aspect-square"
                    onClick={() => setShowReview(!showReview)}
                  />
                ) : (
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/41f5e4b240ec9c1c66c390274ecfcb8d0fa7b45d1b18044b232dda2fa9bacd56?"
                    className="shrink-0 w-7 aspect-square"
                    onClick={() => setShowReview(!showReview)}
                  />
                )}
              </div>
              <p className="mt-8 text-base font-medium text-zinc-800">Status</p>
              <div className="flex gap-2 mt-2.5">
                <p className="justify-center px-3 items-center py-2 text-sm text-green-800 whitespace-nowrap bg-green-100 rounded-md">
                  {reviewedDetails.status}
                </p>
                <p className="my-auto text-sm text-zinc-800">
                  {`on ${formatCreatedAt(reviewedDetails.updated_at)}`}
                </p>
              </div>
              <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
              {showReview && (
                <>
                  <p className="mt-5 text-base font-medium text-zinc-800">
                    Grade
                  </p>
                  <div className="flex gap-1.5 mt-2 text-lg whitespace-nowrap">
                    <input
                      className="justify-center px-14 py-2 w-full bg-white border border-solid border-zinc-400 text-neutral-500 max-md:px-5"
                      value={reviewedDetails.grade || grade}
                      onChange={(e) => setGrade(e.target.value)}
                    />
                    <div className="my-auto text-zinc-800">{`/${project.overallGrade}`}</div>
                  </div>
                  <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
                  <p className="mt-5 text-base text-neutral-500">Comment</p>
                  <div className="mt-5 text-base text-neutral-500">
                    <span className="text-teal-950">Teacher</span>
                    <br />
                    <span className="text-sm text-neutral-500">
                      {formatCreatedAt(reviewedDetails.updated_at)}
                    </span>
                  </div>
                  <p className="mt-2.5 text-base text-zinc-800">
                    {reviewedDetails.comment}
                  </p>
                  <div className="mt-5 text-base text-neutral-500">
                    <span className="text-red-700">Student</span>
                    <br />
                    <span className="text-sm text-neutral-500">
                      {formatCreatedAt(reviewedDetails.updated_at)}
                    </span>
                  </div>
                  <p className="mt-2.5 text-base text-zinc-800">
                    {reviewedDetails?.studentComment}
                  </p>
                  <textarea
                    className="justify-center items-start px-5 py-2 mt-6 text-sm rounded-md border border-solid border-zinc-400 text-neutral-500"
                    placeholder="Type your comment..."
                  />
                  <button className="justify-center text-center hover:bg-sky-800 items-center px-5 py-2 mt-10 text-lg font-medium text-white whitespace-nowrap bg-sky-600 rounded-md max-md:mt-10">
                    Update
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionDetails;
