import React, { useState, useEffect } from "react";
import PaymentService from "../../services/paymentServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectOrderId,
  selectTransactionId,
  selectPayMethod,
  CLEAR_PAYMENT_DATA,
} from "../../redux/auth/authSlice";
import PaymentFailed from "./PaymentFailed";

const SecondPaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [paymentStatus, setPaymentStatus] = useState("COMPLETED"); // Initial status
  const orderId = useSelector(selectOrderId);
  const transactionId = useSelector(selectTransactionId);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const payMethod = useSelector(selectPayMethod);

  useEffect(() => {
    const capturePaypalOrder = async () => {
      setRegistrationComplete(false);
      try {
        const response = await PaymentService.capturePayPalOrder(
          orderId,
          transactionId
        );
        if (response.status >= 200 && response.status < 300) {
          setPaymentStatus(response.data.paymentStatus);
          setRegistrationComplete(true);
        }
      } catch (error) {
        console.error("Error fetching payment status:", error);
      } finally {
        setRegistrationComplete(false);
      }
    };
    if (transactionId && orderId && payMethod === "paypal") {
      capturePaypalOrder();
    }
  }, [transactionId, orderId, payMethod]);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await PaymentService.getStripePaymentIntentStatus(
          transactionId
        );
        if (response.status >= 200 && response.status < 300) {
          setPaymentStatus(response.data.status);
        }
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };
    if (transactionId && payMethod === "card") {
      fetchPaymentStatus();
    }
  }, [transactionId, payMethod]);

  if (
    paymentStatus !== null &&
    paymentStatus !== "COMPLETED" &&
    paymentStatus !== "succeeded"
  ) {
    return <PaymentFailed />;
  }
  return (
    <div className="container mx-auto bg-gray-200 min-h-screen w-full flex flex-col items-center justify-center">
      {paymentStatus === "COMPLETED" || "succeeded" ? (
        <>
          <div className="success-message text-center">
            <h1 className="text-3xl text-sky-600 font-bold mb-4">
              Second Payment Successful!
            </h1>
            <p className="text-gray-700">
              Thank you for completing your second payment. Your enrollment is
              now fully confirmed.
            </p>
          </div>

          <div className="next-steps mt-6 text-center">
            <h2 className="text-2xl text-sky-600 font-semibold mb-2">
              What's Next?
            </h2>
            <ul className="list-disc list-inside text-gray-700">
              <li>You can now access the full course dashboard.</li>
              <li>
                If you have any questions, please contact our support team.
              </li>
            </ul>
          </div>

          <button
            onClick={() => navigate("/studentDashboard")}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8">
            Go to Dashboard
          </button>
        </>
      ) : null}
    </div>
  );
};

export default SecondPaymentSuccess;
