import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import AddVideo from "./Addvideo";
import { useParams } from "react-router-dom";
import { BACKEND_URL } from "../../../services/url";
import axios from "axios";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";

const VideoList = () => {
  useRedirectLoggedOutAdmin("/login")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { coursename } = useParams(); // gets course name from location parameter
  // const [videoList, setVideoList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

    const videoList = [
      {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/afffe67c5b741f695a9bd9f99c9791c4329321541a2acd3a95f6305f8eee0680?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4",
        alt: "Business Case",
        videoTitle: "Business Case",
      },
      {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/fab3ab7036142ad278bbf8545e07b3aa85ba6cbe8cd2521d842ed74962b13df5?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4",
        alt: "Wireframe",
        VideoTitle: "Wireframe",
      },
      {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/cbca74795ab806ce1fbf18e8e5d5bd1d02313a6f18e80780231610bc303200da?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4",
        alt: "Requirement List",
        videoTitle: "Requirement List",
      },
    ];
  // //fetch videos
  // useEffect(() => {
  //   const fetchVideosByCourseName = async () => {
  //     setIsLoading(true); // Set loading state to true
  //     try {
  //       const response = await axios.get(
  //         `${BACKEND_URL}/videos/${coursename}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           withCredentials: true,
  //         }
  //       );
  //       if (response.status >= 200 && response.status < 300) {
  //         setVideoList(response.data);
  //       } else {
  //         toast.error(
  //           response.data?.error || "Failed to fetch videos. Please try again."
  //         );
  //       }
  //     } catch (error) {
  //       // Handle errors
  //       const errorMessage =
  //         error.response?.data?.error ||
  //         "An error occurred while fetching videos";
  //       toast.error(errorMessage);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchVideosByCourseName();
  // }, []);

  return (
    <div className="flex flex-col px-4 py-8 bg-neutral-50 md:px-10 min-h-[400px]">
      {/* Main Content Container */}
      <div className="max-w-screen-xl">
        {/* Heading and Add Button */}
        <div className="flex flex-col w-full mt-4 bg-white px-2 py-2 rounded-md md:flex-row justify-between items-center mb-4">
          <h2 className="text-lg md:text-xl font-medium text-teal-950">
            {coursename}
            {/* Hardcoded for now, to be replace with dynamic logic */}
          </h2>
          <button
            onClick={() => setIsModalOpen(true)}
            className="px-4 py-2 text-base text-white bg-sky-600 rounded-md mt-2 md:mt-0">
            Add video
          </button>
        </div>

        {/* Conditional Rendering based on the state of the fetch process */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {isLoading ? (
            <Box sx={{ display: "flex w-full", justifyContent: "center" }}>
              <CircularProgress /> {/* Use Material UI's CircularProgress */}
            </Box>
          ) : videoList.length > 0 ? (
            videoList.map((video) => (
              <div
                key={video.id}
                className="flex flex-col grow px-5 py-6 w-full bg-white rounded-md border border-solid border-zinc-400 max-md:mt-10">
                <div className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-16 aspect-[1.71] max-md:px-5">
                  <video className="object-cover absolute inset-0 size-full">
                    <source
                      alt={video.videoTitle}
                      loading="lazy"
                      type="video/mp4"
                      src={video.src}
                    />
                  </video>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${JSON.parse(video.videoUrl).filePath}`}>
                      <img
                        alt="Play"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e902613535987881575f59d7481145b10ea58cbef0188cdcb9ee47e146bc2ebb?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                        className="w-8 aspect-square"
                      />
                    </a>
                  </div>
                </div>
                <div className="self-center mt-5 text-lg font-semibold text-zinc-800">
                  {video.videoTitle}
                </div>
              </div>
            ))
          ) : (
            <p>No videos found</p>
          )}
        </div>
      </div>

      <CustomModal isOpen={isModalOpen}>
        <AddVideo closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default VideoList;
