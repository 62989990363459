import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import AddTestimonials from "./AddTestimonials";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Menu, MenuItem } from "@mui/material";
import DeleTestimonials from "./DeleteTestimonials";
import { BACKEND_URL } from "../../../services/url";
import { toast } from "react-toastify";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { formatCreatedAt } from "../../../utils/convertDate";
import useRedirectLoggedOutAdmin from "../../../customHook/useRedirectLoggedOutAdmin";


const Testimonials = () => {
  useRedirectLoggedOutAdmin("/login")
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTestimonialId, setSelectedTestimonialId] = useState(null);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [testimonialData, setTestimonialData] = useState([]);

  // fetch Testimonials
  useEffect(() => {
    const fetchTestimonials = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/testimonials`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        // Handle both success and potential error statuses
        if (response.status >= 200 && response.status < 300) {
          setTestimonialData(response.data);
        } else {
          toast.error(
            response.data?.error ||
              "Failed to fetch testimonials. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching testimonials";
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTestimonials();
  }, []);

  const [testimonials, setTestimonials] = useState([
    {
      id: 1,
      name: "John Doe",
      title: "Student",
      dateUploaded: "23 Jan 2024",
      testimonial:
        "Rorem ipsum dolor sit amet, consectetu adipiscing elit. Etiam eu turpismolestie, dictum est a, mattis tellus. Sed dignissi, metus nec fringilla accumsan, risus sem sollicitudin lacus.",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/fda2e44e88f540575ff4e473051f844dbc894b9fda9abeef025a2e2bdc410246?apiKey=c4f2754934754c86a2ca35b496fd31f4&",
    },
    {
      id: 2,
      name: "Mary Jane",
      title: "Student",
      dateUploaded: "28 Jan 2024",
      testimonial:
        "Rorem ipsum dolor sit amet, consectetu adipiscing elit. Etiam eu turpismolestie, dictum est a, mattis tellus. Sed dignissi, metus nec fringilla accumsan, risus sem sollicitudin lacus.",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/fda2e44e88f540575ff4e473051f844dbc894b9fda9abeef025a2e2bdc410246?apiKey=c4f2754934754c86a2ca35b496fd31f4&",
    },
    {
      id: 3,
      name: "Timi Ojo",
      title: "Student",
      dateUploaded: "08 Feb 2024",
      testimonial:
        "Rorem ipsum dolor sit amet, consectetu adipiscing elit. Etiam eu turpismolestie, dictum est a, mattis tellus. Sed dignissi, metus nec fringilla accumsan, risus sem sollicitudin lacus.",
      imageUrl:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/fda2e44e88f540575ff4e473051f844dbc894b9fda9abeef025a2e2bdc410246?apiKey=c4f2754934754c86a2ca35b496fd31f4&",
    },
  ]);

  const handleOpenMenu = (event, testimonial) => {
    setAnchorEl(event.currentTarget);
    setSelectedTestimonial(testimonial); // Set the selected testimonial
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedTestimonialId(null);
  };

  const handleOpenDeleteTestimonial = () => {
    setOpenDelete(true);
    handleCloseMenu();
  };

  const handleDeleteTestimonial = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${BACKEND_URL}/testimonials/delete/${selectedTestimonial.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        // Successful updating
        toast.success(
          response.data.message || "Testimonial deleted successfully!"
        );
        window.location.reload();
      } else {
        toast.error("Failed to delete testimonial.");
      }
    } catch (error) {
      // Handle errors
      const errorMessage =
        error.response?.data?.error ||
        "An error occurred while deleting the testimonial";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      handleCloseMenu();
    }
  };

  return (
    <div className="flex flex-col px-4 w-full py-8 bg-neutral-50 md:px-4  min-h-[400px]">
      <div className="max-w-screen-xl mx-auto bg-white rounded-lg shadow-lg p-5 md:p-12">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 md:mb-8">
          <h2 className="text-2xl font-medium text-teal-950">Testimonials</h2>
          <button
            onClick={() => setOpenAdd(true)}
            className="px-4 py-2 text-base border   hover:bg-neutral-200 border-sky-600 bg-transparent text-sky-600 rounded-md mt-2 md:mt-0">
            Add new testimonial
          </button>
        </div>

        {/* Testimonials Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-neutral-200 text-teal-950 text-left">
                {/* Table Headers */}
                <th className="px-12 py-5">Image</th>
                <th className="px-12 py-5">Name</th>
                <th className="px-4 py-5">Title</th>
                <th className="px-4 py-5">Date Uploaded</th>
                <th className="px-4 py-5 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-zinc-800">
              {testimonialData.map((testimonial) => (
                <tr key={testimonial.id} className="border-b border-zinc-400">
                  <td className="px-12 py-2.5">
                    <img
                      srcSet={`${JSON.parse(testimonial.image_url).filePath}`}
                      alt={`Testimonial by ${testimonial.name}`}
                      className="shrink-0 self-stretch w-10 aspect-square rounded-full"
                    />
                  </td>
                  <td className="px-12 py-2.5 self-stretch my-auto">
                    {testimonial.name}
                  </td>
                  <td className="px-4 py-2.5 self-stretch my-auto">
                    {testimonial.title}
                  </td>
                  <td className="px-4 py-2.5 self-stretch my-auto">
                    {formatCreatedAt(testimonial.created_at)}
                  </td>
                  <td className="px-4 py-2.5 text-center">
                    {/* Replace with your action icon or button */}
                    <BsThreeDotsVertical
                      onClick={(e) => handleOpenMenu(e, testimonial)}
                      className="cursor-pointer"
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}>
                      <MenuItem onClick={handleOpenDeleteTestimonial}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <CustomModal isOpen={openAdd}>
        <AddTestimonials closeModal={() => setOpenAdd(false)} />
      </CustomModal>

      <CustomModal isOpen={openDelete}>
        <DeleTestimonials
          testimonial={selectedTestimonial}
          onDelete={handleDeleteTestimonial}
          closeModal={() => setOpenDelete(false)}
        />
      </CustomModal>
    </div>
  );
};

export default Testimonials;
