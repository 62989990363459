import React, { useState } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import ScheduleClass from "./ScheduleClass";
import ClasstDetails from "./ClassDetails";
import { selectClasses } from "../../../redux/class/classSlice";
import { useSelector } from "react-redux";
import { formatDate, formatTimeTo12Hour } from "../../../utils/convertDate";
import useRedirectLoggedOutTutor from "../../../customHook/useRedirectLoggedOutTutor";
import { Link } from "react-router-dom";

const TeachersClasses = () => {
  useRedirectLoggedOutTutor("/login")
  const cardData = useSelector(selectClasses);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("All"); // Initialize with 'All'
  const [showClassDetails, setShowClassDetails] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null); //  state  to track selected card

  const handleCardClick = (card) => {
    // Add card parameter

    setSelectedCard(card); // Set the selected card
    setShowClassDetails(true);
  };
  const closeCard = () => {
    setShowClassDetails(false);
  };

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    setShowClassDetails(false);
    // logic to filter cards based on buttonType (All, Upcoming, Pending, Done, Cancelled)
  };

  // Filter cards based on activeButton
  const filteredCards =
    cardData.length > 0 &&
    cardData.filter((card) => {
      if (activeButton === "All") return true;
      return card.status === activeButton;
    });

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-full justify-center p-5 bg-neutral-50 max-md:px-5">
        <div className="flex gap-5 justify-between px-5 py-4 w-full bg-white rounded-lg shadow-lg max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="my-auto text-xl font-medium text-teal-950">
            Classes
          </div>
          <div className="flex flex-row gap-1.5 md:gap-2.5 text-sm">
            <Link
              to="/teachersdashboard/course_materials"
              className=" justify-center px-2 md:px-5 py-1 md:py-2.5 text-sky-600 rounded-md border hover:bg-gray-100 border-sky-600 border-solid">
              Course materials
            </Link>
            <button
              onClick={() => setIsModalOpen(true)}
              className=" justify-center px-4 py-2.5 text-white bg-sky-600 hover:bg-sky-800 rounded-md">
              Schedule new class
            </button>
          </div>
        </div>
        <div className="flex flex-col py-2 md:py-7 mt-8 bg-white rounded-lg shadow-lg max-md:max-w-full">
          <div className="flex flex-row justify-center items-center px-4 md:px-16 py-2.5 text-sm bg-white shadow-lg text-zinc-800 max-md:px-5 max-md:max-w-full">
            <div className="flex flex-row gap-0 md:gap-5 md:justify-between w-full max-w-[859px] max-md:flex-wrap max-md:max-w-full">
              <button
                className={`border-none justify-center px-1 md:px-5 py-1 md:py-2.5 font-semibold text-whit rounded-md
              ${activeButton === "All" ? "bg-[#127ABB] text-white" : ""}`}
                onClick={() => handleButtonClick("All")}>
                All
              </button>
              <button
                className={`border-none justify-center px-1 md:px-5 py-1 md:py-2.5 font-semibold text-whit rounded-md
              ${activeButton === "Upcoming" ? "bg-[#127ABB] text-white" : ""}`}
                onClick={() => handleButtonClick("Upcoming")}>
                Upcoming
              </button>
              <button
                className={`border-none justify-center px-1 md:px-5 py-1 md:py-2.5 font-semibold text-whit rounded-md
              ${activeButton === "Pending" ? "bg-[#127ABB] text-white" : ""}`}
                onClick={() => handleButtonClick("Pending")}>
                Pending
              </button>
              <button
                className={`border-none justify-center px-1 py-1 md:px-5 md:py-2.5 font-semibold text-whit rounded-md
              ${activeButton === "Done" ? "bg-[#127ABB] text-white" : ""}`}
                onClick={() => handleButtonClick("Done")}>
                Done
              </button>
              <button
                className={`border-none justify-center px-1 md:px-5 py-1 md:py-2.5 font-semibold text-whit rounded-md
              ${activeButton === "Cancelled" ? "bg-[#127ABB] text-white" : ""}`}
                onClick={() => handleButtonClick("Cancelled")}>
                Cancelled
              </button>
            </div>
          </div>
          <div className="flex flex-col px-5 mt-8 max-md:px-5 max-md:max-w-full">
            <div className="w-full">
              <div className="flex gap-5 flex-wrap max-md:flex-col max-md:gap-0">
                {filteredCards && filteredCards.map((card) => (
                  <div
                    key={card.id}
                    onClick={() => handleCardClick(card)}
                    className={`flex flex-col w-[30%] max-md:ml-0 max-md:w-full`}>
                    <div
                      className={`flex flex-col grow py-5 w-full text-base rounded-lg border border-solid border-zinc-400 max-md:mt-5 ${
                        selectedCard?.id === card?.id
                          ? "bg-sky-100"
                          : "bg-white "
                      }`}>
                      <div className="flex flex-col px-2 text-neutral-500">
                        <p className="font-medium text-zinc-800">
                          {card.class_title}
                        </p>
                        <div className="flex gap-2.5 mt-5">
                          <img
                            alt=""
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/094711366f02931aeeee7ecb7618540386e5be503000213534e9cbcd5279b821?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                            className="shrink-0 w-5 aspect-square"
                          />
                          <p>{formatDate(card.class_date)}</p>
                        </div>
                        <div className="flex gap-2.5 mt-2.5">
                          <img
                            alt=""
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e2ed0897e6d50edc0f3023a87a361f1a4cf350bcc85efcbefdcc392aa20ce979?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                            className="shrink-0 w-5 aspect-square"
                          />
                          <p>{formatTimeTo12Hour(card.start_time)}</p>
                        </div>
                      </div>
                      <button
                        className={`justify-center text-center items-center px-5 py-2 mt-6 whitespace-nowra ${
                          card.status === "Upcoming" ||
                          card.status === "Pending"
                            ? "bg-sky-100 text-sky-800"
                            : card.status === "Done"
                            ? "bg-green-200 text-green-800"
                            : card.status === "Cancelled"
                            ? "bg-red-200 text-red-800"
                            : ""
                        }`}>
                        {card.status}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <CustomModal isOpen={isModalOpen}>
          <ScheduleClass closeModal={() => setIsModalOpen(false)} />
        </CustomModal>
      </div>
      {showClassDetails && selectedCard && (
        <ClasstDetails classData={selectedCard} closeCard={closeCard} />
      )}
    </div>
  );
};

export default TeachersClasses;
