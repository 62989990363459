import React from "react";
import FeatureCard from "./FeatureCard";

const features = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cedc550315f1558671352bf2836958fd1d9a146d1bbc7439555b01861fa2df73?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
    title: "Practical Training",
    description:
      "Gain practical insights and hands-on experience to apply your knowledge",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0c2dce6e228ec4bbf9f843c2b8d6f91ecec174049ce07aa13376632d1e389e6e?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
    title: "Expert Instruction",
    description:
      "The training team consists of the best hands with ample experience to guide you",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/23fe398ea3b82450ed768ffc88c5dca6ecf434dfeb3921fef7814ff5342939ed?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
    title: "Lifetime Access",
    description:
      "Enjoy continued access to all class materials and coaching during and after the training",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a615ba12752f03db3f86929ffc348070bda8bef6f1c984b2735033f628e2997c?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4",
    title: "Remote Learning",
    description:
      "Become a tech expert from the comfort of your home conveniently",
  },
];

const FeaturesSection = () => {
  return (
    <section className="flex justify-center items-center self-stretch px-16 py-10 mt-20 w-full bg-sky-100 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col w-full max-w-[1108px] max-md:max-w-full">
        <h2 className="self-center text-4xl font-semibold text-teal-950">
          Why Choose Us
        </h2>
        <div className="mt-11 max-md:mt-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
            {features.map((feature, index) => (
              <div key={index} className="w-full">
                {" "}
                <FeatureCard {...feature} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
