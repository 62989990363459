import React from "react";

const ServiceCard = ({ image, title, description }) => {
  return (
    <div className="flex flex-col grow items-center px-4 py-4 w-full bg-white rounded-md border border-solid border-zinc-400 max-md:mt-5">
      {/* Image with Responsive Aspect Ratio */}
      <div className="w-full max-w-xs md:max-w-sm lg:max-w-md"> {/* Limit image width on larger screens */}
        <img
          loading="lazy"
          src={image}
          alt={title}
          className="w-full rounded-none aspect-[1.56] object-cover" // Maintain aspect ratio and cover container
        />
      </div>

      <h3 className="mt-5 text-xl font-medium text-teal-950 text-center">
        {title}
      </h3>
      <p className="items-center mt-4 text-base leading-6 text-zinc-800 text-center max-w-xs md:max-w-sm lg:max-w-md">
        {/* Limit text width on larger screens */}
        {description}
      </p>
    </div>
  );
};

export default ServiceCard;
