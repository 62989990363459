import React from "react";
import {FloatingWhatsApp} from "react-floating-whatsapp";

const  WhatsappPlugin = () => {
  const whatsappChatLink =
    "https://api.whatsapp.com/send/?phone=447778019210&text&type=phone_number&app_absent=0"; // Replace with your actual link

  const handleClick = () => {
    window.open(whatsappChatLink, "_blank"); 
  };

  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="447778019210 "
        accountName="Smart Approaches"
        allowEsc
        allowClickAway
        notification
        notificationDelay={60000}
        chatMessage="Hello! How can we help you today?"
        placeholder="Type your message here"
        className="fixed bottom-8 right-8 z-50"
        onClick={handleClick} // Call handleClick on click
      />
    </div>
  );
}


export default WhatsappPlugin