import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { selectCourse } from "../../redux/auth/authSlice";
import { BACKEND_URL } from "../../services/url";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { formatCreatedAt } from "../../utils/convertDate";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { Menu, MenuItem, Button } from "@mui/material";
import MaterialTable from "@material-table/core";

const CourseMaterials = () => {
  const courseName = useSelector(selectCourse);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDocumentId, setselectedDocumentId] = useState(null);

  const handleOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setselectedDocumentId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setselectedDocumentId(null);
  };
  const handleViewDocument = async (documentId) => {
    const document = courseMaterialsData.find((doc) => doc.id === documentId);
    if (document) {
      const filePath = document.filePath;
      window.open(filePath, "_blank");
    }
    handleCloseMenu();
  };

  const handleDownloadDocument = async (documentId) => {
    const document = courseMaterialsData.find((doc) => doc.id === documentId);
    if (document) {
      const filePath = document.filePath;
      const fileName = document.fileName || "default.pdf"; // Set a default filename if missing

      try {
        const response = await axios.get(filePath, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
        });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        // Handle potential missing headers
        const contentDisposition = response.headers["content-disposition"];
        let downloadFileName = fileName;
        if (contentDisposition) {
          const existingFilename = contentDisposition
            .split("filename=")[1]
            ?.trim();
          if (existingFilename) {
            downloadFileName = existingFilename;
          }
        }

        link.href = url;
        link.download = downloadFileName;
        link.click();

        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading document:", error);
      }
    }
  };

  // State to store fetched course documents
  const [courseMaterialsData, setCourseMaterialsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [courseDocuments, setCourseDocuments] = useState([]);
  const [dateUploaded, setDateUploaded] = useState(null);

  useEffect(() => {
    // Fetch course documents based on courseName
    const fetchCourseDocuments = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/${courseName}/documents`,
          {
            withCredentials: true,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          setCourseDocuments(response.data.courseDocuments);
          setDateUploaded(response.data.dateUploaded);

          const dataWithIds =
            response.data.courseDocuments && // Conditional Check
            response.data.courseDocuments.map((item) => ({
              ...item,
              dateUploaded: response.data?.dateUploaded,
              id: item.id || uuidv4(),
            }));
          setCourseMaterialsData(dataWithIds);
        } else {
          throw new Error(
            response.data?.error || "Failed to fetch course documents."
          );
        }
      } catch (error) {
        setError(
          error.response?.data?.error ||
            "An error occurred while fetching course documents"
        );
        console.error("Error fetching course documents:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (courseName) {
      fetchCourseDocuments(); // Fetch documents when courseName is available
    }
  }, [courseName]);
  const columns = [
    {
      title: "File Name",
      field: "fileName",
      render: (rowData) => {
        const fileName = rowData.fileName;
        const maxLength = 60;

        if (fileName.length > maxLength) {
          const truncatedFileName = fileName.substring(0, maxLength) + "...";
          return (
            <div className="flex gap-1" title={fileName}>
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e21485f22d5121af7d4802bfeee9f325113aa36def7a726e80311fbf1f2f9eed?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 w-6 aspect-square"
              />
              <p>{truncatedFileName}</p>
            </div>
          );
        } else {
          return (
            <div className="flex gap-1">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e21485f22d5121af7d4802bfeee9f325113aa36def7a726e80311fbf1f2f9eed?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 w-6 aspect-square"
              />
              <p>{fileName}</p>
            </div>
          );
        }
      },
      width: 200,
    },
    {
      title: "Date Upload",
      field: "dateUploaded",
      render: (rowData) => (
        <div className="flex  my-auto">
          {formatCreatedAt(rowData.dateUploaded)}
        </div>
      ),
      //hidden: true,
      width: 118,
    },
    {
      title: "File Size",
      field: "fileSize",
      render: (rowData) => (
        <div className="flex my-auto">{rowData.fileSize}</div>
      ),
      //hidden: true,
      width: 90,
    },
    {
      title: "Action",
      field: "action",
      render: (rowData) => (
        <div className="flex text-neutral-500 ">
          <Button>
            <HiOutlineDotsHorizontal
              size={24}
              onClick={(e) => {
                handleOpenMenu(e, rowData.id);
              }}
              className="cursor-pointer"
            />

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              <MenuItem onClick={() => handleViewDocument(selectedDocumentId)}>
                View
              </MenuItem>
              {/* <MenuItem
                onClick={() => handleDownloadDocument(selectedDocumentId)}>
                Download
              </MenuItem> */}
            </Menu>
          </Button>
        </div>
      ),
      width: 100,
    },
  ];

  const isMobile = useMediaQuery("(max-width:640px)"); // Adjust the breakpoint as needed
  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    const newColumns = columns.map((col) => ({
      ...col,
      hide: isMobile && !["File Name", "Action"].includes(col.field), // Show only Student and Action on mobile
    }));
    setVisibleColumns(newColumns);
  }, [isMobile]);

  return (
    <>
      <div className="flex gap-5 justify-between px-2 py-4 text-xl font-medium bg-white rounded-lg shadow-lg text-teal-950 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="my-auto">Course Materials</div>
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6da1b3c3183204f050c3129999ff2e5a2767e162fcafa04c988794d50de59c27?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 w-7 aspect-square"
        />
      </div>
      <div className="mt-12 font-roboto max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 w-full max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
            <div className="flex flex-col w-full grow rounded-lg shadow-lg max-md:mt-8 max-md:max-w-full">
              <div className="flex gap-5 w-full font-roboto justify-between px-8 py-2.5 font-medium rounded-lg border border-solid bg-zinc-100 border-zinc-400 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                <p className="text-lg px-2 font-roboto text-teal-950">
                  Course Materials
                </p>
                {/* <p className="text-base  w-[100px] text-sky-600 font-roboto">
                  View all
                </p> */}
              </div>

              <MaterialTable
                columns={columns}
                data={courseMaterialsData}
                options={{
                  headerStyle: {
                    backgroundColor: "#f0f0f5",
                    color: "#333",
                  },
                  rowStyle: {
                    backgroundColor: "#fff",
                  },
                  showTitle: false, // Disable table title
                  search: false, // Disable search
                  toolbar: false,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseMaterials;
