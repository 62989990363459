import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { IoMdNotificationsOutline } from "react-icons/io";

const NotificationBell = () => {
  const [notifications, setNotifications] = useState([]);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  useEffect(() => {
    const ws = new WebSocket("ws://localhost:7001");

    ws.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      if (notification.type === "newClass") {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          notification.data,
        ]);
        toast(notification.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

    ws.onerror = (error) => {};

    ws.onclose = () => {};
  }, []);

  const handleNotificationClick = (notification) => {
    // Handle notification click event
  };

  const handleBellClick = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  return (
    <div>
      <IoMdNotificationsOutline
        size={24}
        className="shrink-0 my-auto aspect-square w-[25px] text-sky-600"
        onClick={handleBellClick}
      />
      {isNotificationsOpen && (
        <div>
          {notifications.map((notification, index) => (
            <div
              key={index}
              onClick={() => handleNotificationClick(notification)}>
              <p>{notification.classTitle}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationBell;
