import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../../services/url.js";
import { Circles } from "react-loader-spinner";
import { validateEmail } from "../../../services/authServices.js";
import CustomModal from "../../StudentsDashboard/Modal.js";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess.js";
import { useNavigate } from "react-router-dom";
const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  country: "US +1",
  phoneNumber: "",
  selectedCourse: "",
  paymentMode: "",
  doe: "",
};

const countryCodes = [
  { name: "Niger", code: "+227", shortForm: "Nig" },
  { name: "Nigeria", code: "+234", shortForm: "NG" },
  { name: "Rwanda", code: "+250", shortForm: "Rwa" },
  { name: "Sao Tome and Principe", code: "+239", shortForm: "STP" },
  { name: "Senegal", code: "+221", shortForm: "Sen" },
  { name: "Seychelles", code: "+248", shortForm: "Sey" },
  { name: "Sierra Leone", code: "+232", shortForm: "SLE" },
  { name: "Somalia", code: "+252", shortForm: "Som" },
  { name: "South Africa", code: "+27", shortForm: "SA" },
  { name: "South Sudan", code: "+211", shortForm: "SSD" },
  { name: "Sudan", code: "+249", shortForm: "SD" },
  { name: "Tanzania", code: "+255", shortForm: "TZ" },
  { name: "Togo", code: "+228", shortForm: "TGO" },
  { name: "Tunisia", code: "+216", shortForm: "TUN" },
  { name: "Uganda", code: "+256", shortForm: "UG" },
  { name: "Zambia", code: "+260", shortForm: "ZM" },
  { name: "Zimbabwe", code: "+263", shortForm: "ZW" },
  { name: "United States", code: "+1", shortForm: "US" },
  { name: "United Kingdom", code: "+44", shortForm: "UK" },
  { name: "Germany", code: "+49", shortForm: "Ger" },
  { name: "France", code: "+33", shortForm: "Fra" },
  { name: "Italy", code: "+39", shortForm: "Ita" },
  { name: "Spain", code: "+34", shortForm: "ESP" },
  { name: "Netherlands", code: "+31", shortForm: "NED" },
  { name: "Belgium", code: "+32", shortForm: "BEL" },
  { name: "Sweden", code: "+46", shortForm: "SWE" },
  { name: "Norway", code: "+47", shortForm: "NOR" },
  { name: "Canada", code: "+1", shortForm: "CAN" },
  { name: "Mexico", code: "+52", shortForm: "MEX" },
  { name: "Brazil", code: "+55", shortForm: "BRA" },
  { name: "Argentina", code: "+54", shortForm: "ARG" },
  { name: "Chile", code: "+56", shortForm: "CHL" },
  { name: "Colombia", code: "+57", shortForm: "COL" },
  { name: "Peru", code: "+51", shortForm: "PER" },
  { name: "Venezuela", code: "+58", shortForm: "VEN" },
  { name: "Ecuador", code: "+593", shortForm: "ECU" },
  { name: "Bolivia", code: "+591", shortForm: "BOL" },
  { name: "Paraguay", code: "+595", shortForm: "PRY" },
  { name: "Uruguay", code: "+598", shortForm: "URY" },
  { name: "Panama", code: "+507", shortForm: "PAN" },
  { name: "Costa Rica", code: "+506", shortForm: "CRI" },
  { name: "Puerto Rico", code: "+1", shortForm: "PRI" },
  { name: "Dominican Republic", code: "+1", shortForm: "DOM" },
  { name: "Jamaica", code: "+1", shortForm: "JAM" },
  { name: "Trinidad and Tobago", code: "+1", shortForm: "TTO" },
  { name: "Barbados", code: "+1", shortForm: "BRB" },
  { name: "Bahamas", code: "+1", shortForm: "BHS" },
  { name: "Belize", code: "+501", shortForm: "BLZ" },
  { name: "Honduras", code: "+504", shortForm: "HND" },
  { name: "El Salvador", code: "+503", shortForm: "SLV" },
  { name: "Guatemala", code: "+502", shortForm: "GTM" },
  { name: "Nicaragua", code: "+505", shortForm: "NIC" },
  { name: "Japan", code: "+81", shortForm: "JPN" },
  { name: "China", code: "+86", shortForm: "CHN" },
  { name: "India", code: "+91", shortForm: "IND" },
  { name: "South Korea", code: "+82", shortForm: "KOR" },
  { name: "Australia", code: "+61", shortForm: "AUS" },
  { name: "New Zealand", code: "+64", shortForm: "NZL" },
  { name: "Indonesia", code: "+62", shortForm: "IDN" },
  { name: "Philippines", code: "+63", shortForm: "PHL" },
  { name: "Thailand", code: "+66", shortForm: "THA" },
  { name: "Singapore", code: "+65", shortForm: "SGP" },
  { name: "Malaysia", code: "+60", shortForm: "MYS" },
  { name: "Vietnam", code: "+84", shortForm: "VNM" },
  { name: "Turkey", code: "+90", shortForm: "TUR" },
  { name: "Saudi Arabia", code: "+966", shortForm: "SAU" },
  { name: "United Arab Emirates", code: "+971", shortForm: "ARE" },
  { name: "Israel", code: "+972", shortForm: "ISR" },
  // ... add more countries
];
const RegisterStudent = ({ closeModal }) => {
  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resError, setResError] = useState("");
  const navigate = useNavigate();
  const {
    firstName,
    lastName,
    email,
    country,
    phoneNumber,
    selectedCourse,
    paymentMode,
    doe,
  } = formData;

  const [paymentModes, setPaymentodes] = useState([
    { id: 1, mode: "Installment" },
    { id: 2, mode: "Full" },
    { id: 3, mode: "Scholarship" },
  ]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateInput(name, value);
  };

  const handleSubmit = () => {
    closeModal();
  };

  //fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/fetch-courses`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setCourseOptions(response.data);
        } else {
          console.error(
            response.data?.error || "Failed to fetch courses. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching courses";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);
  const validateInput = (name, value) => {
    let error = {};
    switch (name) {
      case "firstName":
        if (!/^[a-zA-Z]+$/.test(value)) {
          error = {
            ...error,
            firstName: "First name should only contain letters",
          };
        } else if (value.length < 3) {
          error = {
            ...error,
            firstName: "First name should be at least 3 characters long",
          };
        } else {
          // Remove the error if the input is valid
          error.firstName = "";
        }
        break;
      case "lastName":
        if (!/^[a-zA-Z]+$/.test(value)) {
          error = {
            ...error,
            lastName: "Last name should only contain letters",
          };
        } else if (value.length < 3) {
          error = {
            ...error,
            lastName: "Last name should be at least 3 characters long",
          };
        } else {
          // Remove the error if the input is valid
          error.lastName = "";
        }
        break;
      case "email":
        if (!validateEmail(value)) {
          error = { ...error, email: "Invalid email address" };
        } else {
          // Remove the error if the input is valid
          error.email = "";
        }
        break;
      case "phoneNumber":
        // if (!/^\d{3}-\d{3}-\d{4}$/.test(value)) {
        //   error = {
        //     ...error,
        //     phoneNumber: "Invalid phone number format (XXX-XXX-XXXX)",
        //   };
        // }
        if (value.length < 10) {
          error = {
            ...error,
            phoneNumber: "phoneNumber should be at least 10 characters long",
          };
        } else {
          // Remove the error if the input is valid
          error.phoneNumber = "";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, ...error });
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BACKEND_URL}/admin-register`,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          country: formData.country,
          phoneNumber: formData.phoneNumber,
          selectedCourse: formData.selectedCourse,
          paymentMode: formData.paymentMode,
          doe: formData.doe,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true, // If you're using cookies for authentication
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setIsModalOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setResError(error.response.data.error);
    }
  };

  return (
    <div className="flex flex-col px-10 pt-5  pb-12  text-lg bg-white rounded-lg max-w-[730px] max-md:pl-5">
      <div className="flex gap-5 items-start text-xl font-medium text-teal-950 max-md:flex-wrap max-md:max-w-full">
        <h2 className="flex-auto mt-5">Register new student</h2>
        <button onClick={closeModal}>
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 w-6 aspect-square"
          />
        </button>
      </div>
      <form>
        {/* Student First Name */}
        <div className="flex flex-col mt-4">
          <label
            htmlFor="studentFirstName"
            className="text-teal-950 max-md:max-w-full">
            Student First Name
          </label>
          <input
            type="text"
            id="studentFirstName"
            name="firstName"
            placeholder="First name"
            value={firstName}
            onChange={handleInputChange}
            className="mt-2.5 px-5 py-2 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
          />
          {errors.firstName ? (
            <p className="text-red-500 text-sm">{errors.firstName}</p>
          ) : (
            ""
          )}
        </div>

        {/* Student Last Name */}
        <div className="flex flex-col mt-4">
          <label
            htmlFor="studentLastName"
            className="text-teal-950 max-md:max-w-full">
            Student Last Name
          </label>
          <input
            type="text"
            id="studentLastName"
            name="lastName"
            placeholder="Last name"
            value={lastName}
            onChange={handleInputChange}
            className="mt-2.5 px-5 py-2 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
          />
          {errors.lastName ? (
            <p className="text-red-500 text-sm">{errors.lastName}</p>
          ) : (
            ""
          )}
        </div>

        {/* Email */}
        <div className="flex flex-col mt-4">
          <label htmlFor="email" className="text-teal-950 max-md:max-w-full">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="example@domain.com"
            value={email}
            onChange={handleInputChange}
            className="mt-2.5 px-5 py-2 text-base whitespace-nowrap rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
          />
          {errors.email ? (
            <p className="text-red-500 text-sm">{errors.email}</p>
          ) : (
            ""
          )}
          {resError ? <p className="text-red-500 text-sm">{resError}</p> : ""}
        </div>

        {/* Country and Phone Number */}
        <div className="flex gap-5 px-4 mt-4 max-md:flex-wrap">
          <div className="flex flex-col whitespace-nowrap">
            <label htmlFor="country" className="text-teal-950">
              Country
            </label>
            <select
              id="country"
              name="country"
              value={country}
              onChange={handleInputChange}
              className="mt-2.5 px-1 py-2 text-base rounded-md border border-solid border-zinc-400 text-zinc-800 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full">
              {countryCodes.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name} {country.code}
                </option>
              ))}
            </select>
          </div>
          {/* Phone Number */}
          <div className="flex flex-col w-full text-teal-950 max-md:max-w-full">
            <label htmlFor="phoneNumber" className="max-md:max-w-full">
              Phone number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleInputChange}
              className="mt-2.5 px-5 py-1.5 rounded-md border w-full border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
            />
          </div>
        </div>

        {/* Course Selection */}
        <div className="flex flex-col mt-8">
          <label htmlFor="course" className="text-teal-950 max-md:max-w-full">
            Select Course
          </label>
          <select
            name="selectedCourse"
            id=""
            value={selectedCourse}
            onChange={handleInputChange}
            className="border-2 border-gray-400 rounded-md py-1.5 px-2 placeholder-gray-500 placeholder text-sm">
            <option value="" disabled selected>
              Select an option
            </option>
            {courseOptions.map((option) => (
              <option key={option.id} value={option.courseName}>
                {option.courseName}
              </option>
            ))}
          </select>
        </div>

        {/* Payment Mode Selection */}
        <div className="flex flex-col mt-8">
          <label
            htmlFor="paymentMode"
            className="text-teal-950 max-md:max-w-full">
            Payment mode
          </label>
          <select
            id="paymentMode"
            name="paymentMode"
            value={paymentMode}
            onChange={handleInputChange}
            className="mt-2.5 px-5 py-2 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full">
            {paymentModes.map((mode) => (
              <option key={mode.id} value={mode.mode}>
                {mode.mode}
              </option>
            ))}
          </select>
        </div>

        {/* Date of Enrollment (Use date input) */}
        <div className="flex flex-col mt-8">
          <label htmlFor="doe" className="text-teal-950 max-md:max-w-full">
            Date of enrollment
          </label>
          <input
            type="date"
            id="enrollmentDate"
            name="doe"
            value={doe}
            onChange={handleInputChange}
            className="mt-2.5 px-5 py-2 text-base rounded-md border border-solid border-zinc-400 text-neutral-500 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={handleRegister}
          type="submit"
          className="mt-8 px-5 py-2 w-full font-medium text-white bg-sky-600 rounded-md max-md:mt-10">
          {isLoading ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20"
                width="20"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "submit"
          )}
        </button>
      </form>

      <CustomModal isOpen={isModalOpen}>
        <SubmitSuccess
          closeModal={() => setIsModalOpen(false)}
          text="student registered successfully"
          desc="email and login details sent"
          buttonText="Go Home"
          handleButtonClick={() => window.location.reload()}
        />
      </CustomModal>
    </div>
  );
};

export default RegisterStudent;
