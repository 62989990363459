import React, { useState } from "react";
import { BACKEND_URL } from "../../services/url";
import axios from "axios";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${BACKEND_URL}/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName, email, message }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setIsLoading(false);
        setSuccess(data.message);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        toast.success("Message sent");
      }
    } catch (error) {
      setIsLoading(false);
      setError("Failed to send email");
      toast.error("Failed to sent");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-[55%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow mt-1.5 text-lg text-zinc-800 max-md:mt-10 max-md:max-w-full">
        <label htmlFor="firstName" className="max-md:max-w-full">
          First Name
        </label>
        <input
          id="firstName"
          type="text"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          placeholder="First Name"
          className="px-5 py-4 mt-3 text-base rounded-md border border-solid border-zinc-400 text-zinc-400 max-md:max-w-full"
        />
        <label htmlFor="lastName" className="mt-6 max-md:max-w-full">
          Last Name
        </label>
        <input
          id="lastName"
          type="text"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          placeholder="Last Name"
          className="px-5 py-4 mt-3 text-base rounded-md border border-solid border-zinc-400 text-zinc-400 max-md:max-w-full"
        />
        <label htmlFor="email" className="mt-6 max-md:max-w-full">
          Email
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="example@domain.com"
          className="px-5 py-4 mt-3 text-base whitespace-nowrap rounded-md border border-solid border-zinc-400 text-zinc-400 max-md:max-w-full"
        />
        <label htmlFor="message" className="mt-6 max-md:max-w-full">
          How can we help?
        </label>
        <textarea
          id="message"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          className="shrink-0 mt-3 rounded-md border border-solid border-zinc-400 h-[125px] max-md:max-w-full"
        />
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        {success && <p className="text-green-500 text-sm mt-2">{success}</p>}
        <button
          type="submit"
          className="px-5 py-4 mt-8 hover:bg-sky-800 max-w-full font-medium text-white whitespace-nowrap bg-sky-600 rounded-md w-[200px]">
          {isLoading ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20"
                width="20"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "Send"
          )}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
