import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Specify workerSrc (adjust path if needed)
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function DocumentPreview({ fileUrl }) {
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false); // Mark as loaded
  }

  function onError(error) {
    console.error("Error loading PDF:", error);
    setLoading(false); // Mark as loaded even on error
    // Optionally: display an error message to the user
  }

  return (
    <div>
      {loading && <p>Loading document...</p>} {/* Show loading message */}
      {!loading && numPages === null && <p>Failed to load document.</p>}{" "}
      {/* Error message */}
      {!loading &&
        numPages && ( // Only render pages if loaded and numPages is available
          <Document
            file={fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onError={onError}>
            {Array.from(new Array(numPages), (_, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        )}
    </div>
  );
}

export default DocumentPreview;
