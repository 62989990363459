import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomModal from "../../StudentsDashboard/Modal";
import { BACKEND_URL } from "../../../services/url";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";

const EditQuestion = ({ closeModal, faq, onUpdate }) => {
  const [editedQuestion, setEditedQuestion] = useState(faq.question);
  const [editedAnswer, setEditedAnswer] = useState(faq.answer);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Update state when the faq prop changes (e.g., when a different FAQ is selected for editing)
    setEditedQuestion(faq.question);
    setEditedAnswer(faq.answer);
  }, [faq]);
  
  const handleUpdate = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const updatedFaq = {
        question: editedQuestion,
        answer: editedAnswer,
      };
      const response = await axios.put(
        `${BACKEND_URL}/faqs/update-faq/${faq.id}`,
        updatedFaq,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        // Successful updating
        toast.success(response.data.message || "Faq updated successfully!");
        window.location.reload()
        closeModal(); // Close the modal after successful submission
      } else {
        toast.error("Failed to update faq.");
      }
    } catch (error) {
      // Handle errors
      const errorMessage =
        error.response?.data?.error ||
        "An error occurred while updating the faq";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col pt-10 pr-5 pb-16 pl-10 text-lg bg-white rounded-lg max-w-[730px] text-teal-950 max-md:pl-5">
      {/* Heading and Close Button */}
      <div className="flex justify-between items-center mb-5 md:mb-8">
        <h2 className="text-xl font-medium text-teal-950">Edit Question</h2>
        <img
          onClick={closeModal}
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 w-6 aspect-square"
        />
      </div>

      {/* Question Form */}
      <form>
        {" "}
      
        {/* Question Input */}
        <div className="flex flex-col">
          <label htmlFor="question" className="mt-4 max-md:max-w-full">
            Question
          </label>
          <textarea
            id="question"
            className="mt-2.5 px-5 py-3 text-base rounded-md border border-solid border-zinc-400 text-zinc-800 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
            value={editedQuestion}
            onChange={(e) => setEditedQuestion(e.target.value)} // Update editedQuestion on change
          />
        </div>
        {/* Answer Input */}
        <div className="flex flex-col mt-4">
          <label htmlFor="answer" className="max-md:max-w-full">
            Answer
          </label>
          <textarea
            id="answer"
            className="mt-2.5 px-5 py-5 text-base rounded-md border border-solid border-zinc-400 text-zinc-800 focus:outline-none focus:ring focus:ring-sky-500 max-md:max-w-full"
            value={editedAnswer}
            onChange={(e) => setEditedAnswer(e.target.value)} // Update editedAnswer on change
          />
        </div>
        {/* Update Button */}
        <button
          type="submit"
          onClick={handleUpdate}
          className="mt-10 px-5 w-full py-3 hover:bg-sky-800 font-medium text-white whitespace-nowrap bg-sky-600 rounded-md max-md:mt-10">
          {isLoading ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20"
                width="20"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            " Update Faq"
          )}
        </button>
      </form>
    </div>
  );
};

export default EditQuestion;
