import axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_URL } from "./url";

export const submitAssignment = async (assignmentData) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/assignment/submit`,
      assignmentData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    if (response.statusText === "OK") {
      toast.success("Assignment submitted successfully");
    }
  
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

export const createAssignment = async (assignmentData) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/assignment/create`,
       assignmentData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);

  }
};

export const fetchAssignments = async (userData) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/assignment/`,
      userData,
      { withCredentials: true }
    );
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

export const updateAssignmentStatus = async (assignmentId) => {
  try {
    const response = await axios.put(
      `${BACKEND_URL}/assignment/update-status/${assignmentId}`,
      { withCredentials: true }
    );
    if (response.statusText === "OK") {
      toast.success("assignment successfully updated");
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};


export const fetchSubmittedAssignments = async (assignmentId) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/assignment/submission/${assignmentId}`,
      { withCredentials: true }
    );
    // if (response.statusText === "OK") {
    //   toast.success("assignments fetched successfully");
    // }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};

export const fetchSubmissionCounts = async (assignmentId) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/assignment/count`,
      { withCredentials: true }
    );
    if (response.statusText === "OK") {
      
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};

export const fetchProgress = async (assignmentId) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/assignment/progress/${assignmentId}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.statusText === "OK") {
      
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};


export const postReview = async (submissionId,studentId,reviewedData) => {
  try {
    const response = await axios.put(
      `${BACKEND_URL}/assignment/${submissionId}/students/${studentId}/review/`,reviewedData,
      {
        withCredentials: true,
        
      }
    );
    if (response.statusText === "OK") {
      toast.success("submission reviewed successfully");
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};
