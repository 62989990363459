import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import ScheduleClass from "../Classes/ScheduleClass";
import { useDispatch, useSelector } from "react-redux";
import { selectCourse } from "../../../redux/auth/authSlice";
import { selectClasses } from "../../../redux/class/classSlice";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/convertDate";
import ViewClassLink from "../../StudentsDashboard/Dashboard/ViewClassLink";

const UpcomingClass1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [classes, setClasses] = useState([])
  const [openClassLink, setOpenClassLink] = useState();
  const dispatch = useDispatch();

  const course = useSelector(selectCourse);

  const classes = useSelector(selectClasses);

  const upcomingOrPendingClasses =
    classes?.length > 0 &&
    classes.filter(
      (classItem) =>
        classItem.status === "Upcoming" || classItem.status === "Pending"
    );

  upcomingOrPendingClasses.length > 0 &&
    upcomingOrPendingClasses.sort((a, b) => {
      const dateA = new Date(a.class_date);
      const dateB = new Date(b.class_date);
      return dateA - dateB; // Sort in ascending order of date
    });

  // Get the most recent upcoming/pending class (or null if none)
  const mostRecentClass = upcomingOrPendingClasses[0] || null;

  function formatTimeTo12Hour(timeString) {
    const [hours, minutes] = timeString?.split(":");
    let period = "AM";

    let hoursNum = parseInt(hours, 10);

    // Automatically determine AM/PM
    if (hoursNum >= 12) {
      period = "PM";
      if (hoursNum > 12) {
        hoursNum -= 12; // Adjust for 12-hour format
      }
    }

    return `${hoursNum}:${minutes} ${period}`;
  }

  // useEffect(() => {
  //   const fetchClasses = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get(`${BACKEND_URL}/class/${course}`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       });

  //       // Handle both success and potential error statuses
  //       if (response.status >= 200 && response.status < 300) {
  //         //setClasses(response.data);
  //         dispatch(SET_CLASSES(response.data));
  //       } else {
  //         console.error.error(
  //           response.data?.error ||
  //             "Failed to fetch assignment. Please try again."
  //         );
  //       }
  //     } catch (error) {
  //       // Handle errors
  //       const errorMessage =
  //         error.response?.data?.error ||
  //         "An error occurred while fetching assignment";
  //       console.error(errorMessage);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchClasses();
  // }, []);

  return (
    <div className="flex flex-col ml-2 w-[28%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-6 pt-5 pb-8 mx-auto w-full font-medium bg-white shadow-lg max-md:pl-5 max-md:mt-5">
        <div className="flex gap-5 text-xl text-teal-950">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0469dda73424fe9dbbe1ace71cd40b2429a8272a6742faa614f30a615c8fb67?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 aspect-square w-[25px]"
          />

          <p className="w-full text-sm ">Upcoming Class</p>
        </div>
        <div className="shrink-0 mt-3.5 h-px bg-gray-200 border border-gray-200 border-solid" />
        {mostRecentClass === null ? (
          <>
            <p className="mt-12 text-base leading-7 text-neutral-500 max-md:mt-10">
              No Upcoming Class
              <br />
            </p>
            <p
              onClick={() => setIsModalOpen(true)}
              className="mt-8 cursor-pointer text-base text-sky-600 max-md:mr-0.5">
              Schedule class
            </p>
          </>
        ) : (
          <>
            <div className="flex gap-2 mt-5">
              <p className=" font-roboto text-sm text-neutral-500">Date:</p>
              <p className="font-medium font-roboto text-sm text-zinc-800">
                {formatDate(mostRecentClass?.class_date)}
              </p>
            </div>
            <div className="flex gap-2 mt-5">
              <p className="text-sm font-roboto text-neutral-500">Time:</p>
              <p className="font-roboto text-[13px] text-zinc-800">
                {`${formatTimeTo12Hour(mostRecentClass?.start_time)}(UK TIME)`}
              </p>
            </div>
            <div className="flex gap-1.5 mt-8 text-base font-roboto text-sky-600">
              <Link onClick={() => setOpenClassLink(true)}>
                View class details
              </Link>
            </div>
          </>
        )}
      </div>
      <CustomModal isOpen={isModalOpen}>
        <ScheduleClass closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
      <CustomModal isOpen={openClassLink}>
        <ViewClassLink
          closeModal={() => setOpenClassLink(false)}
          classData={mostRecentClass}
        />
      </CustomModal>
    </div>
  );
};

export default UpcomingClass1;
