import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  classes: [], // Array to store all classes
  selectedClass: null, // Initially, no class is selected
};

const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    SET_CLASSES(state, action) {
      state.classes = action.payload; 
    },
    SET_SELECTED_CLASS(state, action) {
      const classId = action.payload; 
      const selectedClass = state.classes.find((c) => c.id === classId);
      state.selectedClass = selectedClass || null; 
    },
  },
});

export const { SET_CLASSES, SET_SELECTED_CLASS } = classSlice.actions;

// Selectors
export const selectClasses = (state) => state.class.classes;
export const selectSelectedClass = (state) => state.class.selectedClass;

export default classSlice.reducer;
